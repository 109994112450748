import { Progress } from "antd";

const ProgressBar = ({
  title,
  value,
  color,
  width,
  fullWidth,
}: {
  title: string;
  value?: number;
  color: string;
  width: string | number;
  fullWidth: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: fullWidth ? "100%" : "",
      }}
    >
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "99999px",
          backgroundColor: color,
        }}
      ></div>
      <div style={{ width: width }}>
        <span>{title}</span>
        <Progress status="normal" percent={value} trailColor="#EAECF0" strokeColor={color} />
      </div>
    </div>
  );
};
export { ProgressBar as default, ProgressBar };
