import { theme } from "src/theme/theme";

const GoalAmountIcon = ({ width = "132", height = "137" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 132 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.59826 26.5989C7.11249 26.106 7.94104 24.4768 7.44888 22.9601C6.95671 21.4434 5.33021 20.6134 3.81598 21.1064C2.30175 21.5994 1.4732 23.2286 1.96536 24.7453C2.45753 26.262 4.08403 27.0919 5.59826 26.5989Z"
        fill="#EAEEF9"
      />
      <g filter="url(#filter0_d_11_15467)">
        <path
          d="M23.5181 9.42735C24.6008 9.42735 25.4785 8.54818 25.4785 7.46367C25.4785 6.37917 24.6008 5.5 23.5181 5.5C22.4353 5.5 21.5576 6.37917 21.5576 7.46367C21.5576 8.54818 22.4353 9.42735 23.5181 9.42735Z"
          fill="url(#paint0_linear_11_15467)"
        />
      </g>
      <path
        d="M123.04 60.2521C124.122 60.2521 125 59.3729 125 58.2884C125 57.2039 124.122 56.3247 123.04 56.3247C121.957 56.3247 121.079 57.2039 121.079 58.2884C121.079 59.3729 121.957 60.2521 123.04 60.2521Z"
        fill="url(#paint1_linear_11_15467)"
      />
      <path
        d="M5.0669 102.759C6.14963 102.759 7.02736 101.88 7.02736 100.796C7.02736 99.7112 6.14963 98.832 5.0669 98.832C3.98417 98.832 3.10645 99.7112 3.10645 100.796C3.10645 101.88 3.98417 102.759 5.0669 102.759Z"
        fill="url(#paint2_linear_11_15467)"
      />
      <path
        d="M120.487 34.3602C122.274 32.7249 122.399 29.9484 120.766 28.1589C119.133 26.3694 116.361 26.2444 114.575 27.8797C112.788 29.5151 112.663 32.2915 114.296 34.0811C115.929 35.8706 118.701 35.9956 120.487 34.3602Z"
        fill="#EAEEF9"
      />
      <path
        d="M120.816 14.684C121.569 13.9954 121.621 12.8264 120.934 12.0729C120.246 11.3194 119.079 11.2668 118.327 11.9554C117.575 12.644 117.522 13.813 118.21 14.5665C118.897 15.32 120.064 15.3726 120.816 14.684Z"
        fill="#EAEEF9"
      />
      <path
        d="M29.631 95.5977L20.29 120.201H28.4778L39.5486 100.103L29.631 95.5977Z"
        fill="#CED7E2"
      />
      <path
        d="M79.3336 95.5977L88.6746 120.201H80.4868L69.416 100.103L79.3336 95.5977Z"
        fill="#CED7E2"
      />
      <path
        d="M58.6908 105.185C83.3388 105.185 103.32 85.1712 103.32 60.4827C103.32 35.7942 83.3388 15.7803 58.6908 15.7803C34.0427 15.7803 14.0615 35.7942 14.0615 60.4827C14.0615 85.1712 34.0427 105.185 58.6908 105.185Z"
        fill="#CED7E2"
      />
      <path
        d="M54.5394 105.185C79.1874 105.185 99.1686 85.1712 99.1686 60.4827C99.1686 35.7942 79.1874 15.7803 54.5394 15.7803C29.8913 15.7803 9.91016 35.7942 9.91016 60.4827C9.91016 85.1712 29.8913 105.185 54.5394 105.185Z"
        fill="#E1E5ED"
      />
      <path
        d="M54.54 94.2119C73.1374 94.2119 88.2137 79.1109 88.2137 60.4829C88.2137 41.8549 73.1374 26.7539 54.54 26.7539C35.9425 26.7539 20.8662 41.8549 20.8662 60.4829C20.8662 79.1109 35.9425 94.2119 54.54 94.2119Z"
        fill="white"
      />
      <path
        d="M54.5402 84.1619C67.5967 84.1619 78.181 73.5602 78.181 60.4823C78.181 47.4044 67.5967 36.8027 54.5402 36.8027C41.4838 36.8027 30.8994 47.4044 30.8994 60.4823C30.8994 73.5602 41.4838 84.1619 54.5402 84.1619Z"
        fill="#E1E5ED"
      />
      <path
        d="M54.5401 73.5365C61.737 73.5365 67.5713 67.6926 67.5713 60.4838C67.5713 53.275 61.737 47.4312 54.5401 47.4312C47.3431 47.4312 41.5088 53.275 41.5088 60.4838C41.5088 67.6926 47.3431 73.5365 54.5401 73.5365Z"
        fill="white"
      />
      <g filter="url(#filter1_d_11_15467)">
        <path
          d="M117.966 92.1323C117.966 93.865 117.735 95.5976 117.389 97.2148C117.159 98.2544 116.813 99.1785 116.467 100.103C114.276 105.878 109.663 110.498 103.897 112.693C103.435 112.809 103.089 113.04 102.628 113.155C100.437 113.848 98.246 114.195 95.8243 114.195C83.6002 114.195 73.6826 104.261 73.6826 92.0168C73.6826 79.7727 83.6002 69.8389 95.8243 69.8389C108.048 69.9544 117.966 79.8883 117.966 92.1323Z"
          fill={theme.colors.primary["500"]}
        />
      </g>
      <path
        d="M117.389 97.7933C117.158 98.7173 116.812 99.6414 116.466 100.565C114.275 106.11 109.893 110.499 104.357 112.694L91.9027 100.219L90.1729 98.4863L102.397 87.5128L106.433 85.2026L117.273 96.0606L117.389 97.7933Z"
        fill={theme.colors.primary["500"]}
      />
      <path
        d="M93.517 100.796C92.8251 100.796 92.1332 100.565 91.6719 99.9874L86.5978 94.9049C85.5599 93.8653 85.5599 92.2482 86.5978 91.2086C87.6356 90.169 89.2501 90.169 90.288 91.2086L93.517 94.4429L102.743 85.2021C103.781 84.1625 105.395 84.1625 106.433 85.2021C107.471 86.2416 107.471 87.8588 106.433 88.8984L95.3621 99.9874C94.9009 100.565 94.2089 100.796 93.517 100.796Z"
        fill="url(#paint5_linear_11_15467)"
      />
      <path
        d="M31.9362 52.6282L54.8851 58.6347C55.923 58.8658 56.4996 60.0209 56.2689 61.0605C56.0383 62.1 54.8851 62.6776 53.8472 62.4466L3.45191 49.2784C2.41402 49.0474 1.83742 47.8923 2.06806 46.8527C2.2987 45.8131 3.45191 45.2356 4.4898 45.4666L21.788 49.9715"
        fill="#1C3754"
      />
      <path
        d="M25.0173 47.1993L24.4407 46.0442C23.7488 44.6581 22.3649 43.503 20.8658 43.1564L5.18211 39.1136C4.0289 38.8826 3.10633 39.9222 3.68293 40.9617L7.25789 48.2389L0.569266 52.7438C-0.353302 53.4369 -0.12266 54.823 1.03055 55.1695L16.7142 59.2124C18.2134 59.6744 19.9432 59.3279 21.2117 58.4038L26.6318 54.7075C27.5544 54.1299 27.785 52.9748 27.3237 52.0507L25.0173 47.1993Z"
        fill="url(#paint6_linear_11_15467)"
      />
      <path
        d="M2.2998 121.125H106.089"
        stroke="#EAEEF9"
        stroke-width="3.36408"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_11_15467"
          x="8.10128"
          y="0.453874"
          width="30.8336"
          height="30.8404"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.41021" />
          <feGaussianBlur stdDeviation="6.72817" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11_15467"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11_15467"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_11_15467"
          x="60.2263"
          y="64.7927"
          width="71.1959"
          height="71.2686"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.41021" />
          <feGaussianBlur stdDeviation="6.72817" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11_15467"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11_15467"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11_15467"
          x1="22.4648"
          y1="5.86275"
          x2="24.6276"
          y2="9.15125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#78B700" />
          <stop offset="1" stop-color="#69A000" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_15467"
          x1="123.04"
          y1="56.3247"
          x2="123.04"
          y2="60.2521"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0208333" stop-color="#FFC46B" />
          <stop offset="1" stop-color="#F79B11" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_15467"
          x1="5.0669"
          y1="98.832"
          x2="5.0669"
          y2="102.759"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0208333" stop-color="#FFC46B" />
          <stop offset="1" stop-color="#F79B11" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_15467"
          x1="83.9282"
          y1="73.9358"
          x2="108.356"
          y2="111.076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#78B700" />
          <stop offset="1" stop-color="#69A000" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11_15467"
          x1="97.9513"
          y1="91.4582"
          x2="107.264"
          y2="100.756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#78B700" />
          <stop offset="1" stop-color="#78B700" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11_15467"
          x1="102.172"
          y1="95.6887"
          x2="93.0115"
          y2="86.5429"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#C2D1FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11_15467"
          x1="13.7772"
          y1="39.0811"
          x2="13.7772"
          y2="59.4161"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0208333" stop-color="#FFC46B" />
          <stop offset="1" stop-color="#F79B11" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoalAmountIcon;
