import { FC, useEffect, useRef } from "react"

interface SymbolOverViewWidgetProps {
    symbol: string
}

export const SymbolOverViewWidget: FC<SymbolOverViewWidgetProps> = ({ symbol }) => {

    const widgetRef = useRef(null);

    useEffect(() => {
        if (widgetRef.current && symbol) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
            script.async = true;
            script.innerHTML = JSON.stringify({
                symbols: [
                    [
                        symbol,
                    ]
                ],
                width: "100%"
            })
            widgetRef.current.appendChild(script);
        }
    }, [symbol])
    return (<div ref={widgetRef} />)
}