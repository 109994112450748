import React from "react";

import { Button, Icon } from "src/components";
import ArrowCircleright from "src/assets/ArrowCircleright.svg";
import dashboardImg from "./assets/dash-ui.png";
import {
  StartUp,
  Container,
  Img,
  ImageContainer,
  Wrapper,
  Title,
  Text,
  Frame,
  ButtonContainer,
} from "./style/modal";

const StartModal = ({ isVisible, onDone }) => {
  return (
    <StartUp width={880} centered visible={isVisible} onOk={onDone}>
      <Container>
        <ImageContainer>
          <Img src={dashboardImg} />
        </ImageContainer>
        <Wrapper>
          <Frame>
            <Title>Welcome to Your Dashboard</Title>
            <Text>
              You will be able to invest and manage your account from here.
            </Text>
          </Frame>
          <Button onClick={onDone} key="done" width="50%">
            <ButtonContainer>
              Get Started
              <Icon size="20px" color="white" src={ArrowCircleright} />
            </ButtonContainer>
          </Button>
        </Wrapper>
      </Container>
    </StartUp>
  );
};

export default StartModal;
