import { nanoid } from "@reduxjs/toolkit";
import { FC } from "react";
import { Flex } from "src/components/Flex";
import { ProgressBar } from "src/pages/SingleSmartfolio/components/Progress";
import { PieColors } from "../PieChart";

interface IBarChart {
  data: { key: string; value: number }[];
}

export const BarChart: FC<IBarChart> = ({ data }) => {
  return (
    <Flex flexDirection="column">
      {data?.map(({ key, value }, index) => {
        return (
          <ProgressBar
            key={nanoid()}
            title={key}
            value={value}
            width={"100%"}
            fullWidth={true}
            color={PieColors[index]}
          />
        );
      })}
    </Flex>
  );
};
