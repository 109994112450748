import styled from "styled-components/macro";
import { typography } from "src/utils/styledComponents/typography";
import { Modal } from "antd";
import { IPAD, LARGE_SCREEN } from "src/utils/constants/media";

export const StartUp = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-footer {
      display: none;
    }
    ${typography.CustomText("1em")}
    .ant-modal-body {
      padding: 0;
      overflow: hidden;
    }
    .ant-modal-header {
      padding: 5px;
      border-radius: 12px;
      border-bottom: 0px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-close {
      display: none;
    }
  }

  @media (max-width: ${LARGE_SCREEN}px) {
    &.ant-modal {
      width: 80% !important;
    }
  }

  @media (max-width: ${IPAD}px) {
    &.ant-modal {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  transform: translateX(10%);
  justify-content: space-around;
  padding: 20px;

  button {
    padding: 12px 0px;
  }
`;

export const ImageContainer = styled.div`
  background-color: #f7f9fb;
  flex: 0.4;
  height: 496px;
  overflow: hidden;
  padding: 5px;
  border-top: 10px solid #f7f9fb;
  border-bottom: 10px solid #f7f9fb;
  border-left: 10px solid #f7f9fb;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  position: relative;
`;

export const Img = styled.img`
  transform: scale(3) rotate(-35deg) translate(21px, 31px);
  filter: blur(0.023em);
`;

export const Title = styled.span`
  ${typography.CustomMeriText("26px")}
  color:#2478F6;
  padding-bottom: 26px;
  font-weight: 600;
`;

export const Text = styled.p``;

export const Frame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.8em;
`;
