import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  margin-top: 2em;
  width: 100%;
  padding: 1em;
  background-color: #F7F9FB;
  border-radius: 5px;
  border: 1px solid #e8f1ff;
  font-weight: 700;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export const Header = styled.div`
  top: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div<{ isVisible: boolean }>`
  padding: 5px;
  background: #e8f1ff;
  transform: rotate(0);
  border-radius: 5px;
  transform: ${({ isVisible }) =>
    isVisible ? 'rotate(270deg)' : 'roteat(0deg)'};
`;

const visibleWrapper = css`
  visibility: visible;
`;

export const Wrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 58px;
  right: 0;
  left: -1px;
  background: #fff;
  width: calc(100% + 2px);
  z-index: 1;
  background-color: #f7f9fb;
  border-radius: 5px;
  border: 1px solid #e8f1ff;
  font-weight: 700;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  visibility: hidden;
  ${({ isVisible }) => isVisible && visibleWrapper};
`;

export const Title = styled.span``;
