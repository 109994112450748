import React from "react";

import percentageIcon from "src/assets/percent.svg";
import dollarIcon from "src/assets/dollar.svg";

import { Button, Image, ImageContainer } from "./styles/switcher";

interface ISwitcher {
  isOn?: boolean;
  handleToggle?: React.MouseEventHandler<HTMLButtonElement>;
}

const Switcher = ({ isOn, handleToggle }: ISwitcher) => {
  return (
    <Button
      type="button"
      role="switch"
      aria-checked={isOn}
      onClick={handleToggle}
    >
      <ImageContainer selected={isOn}>
        <Image alt="first" src={percentageIcon} />
      </ImageContainer>
      <ImageContainer selected={!isOn}>
        <Image alt="second" src={dollarIcon} />
      </ImageContainer>
    </Button>
  );
};

export default Switcher;
