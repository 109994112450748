import {
  SingleSmartfolioDescription,
  SingleSmartfolioTitle,
} from "./PrivateSmartfolio.styled";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { FACTSHEET } from "src/utils/config";
import { useMediaQuery } from "src/components/FinaLayout/hooks/hooks";
import { ButtonTheme } from "src/components/Button/ButtonTheme";
import { Space } from "antd";
import { useGetPrivateSmartFolioDocumentQuery } from "src/store/middleware/api_v2";

const Header = ({
  title,
  description,
  smartfolioMarket,
  id,
}: {
  title: string;
  description: string;
  smartfolioMarket: string;
  id: string;
}) => {
  const history = useHistory();
  const { data: smartfolioVideo, isLoading } =
    useGetPrivateSmartFolioDocumentQuery({
      id,
      fileFormat: "pdf",
      fileType: "termsheet",
    });
  const smallScreen = useMediaQuery("(max-width: 650px)");

  return (
    <header>
      <div
        style={{
          display: "flex",
          flexDirection: smallScreen ? "column" : "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <SingleSmartfolioTitle>
            <LeftOutlined onClick={() => history.push("/smartfolios")} />
            {title}
          </SingleSmartfolioTitle>
        </div>
        <Space style={{ textAlign: "right", marginBottom: 16 }}>
          <ButtonTheme>
            <a
              href={`${FACTSHEET}/api/v2/public/smartfolios/${id}/factsheet?file_format=pdf&file_type=factsheet`}
              target="_blank"
              rel="noreferrer"
            >
              Presentation
            </a>
          </ButtonTheme>
          <ButtonTheme>
            <a
              href={`${FACTSHEET}/api/v2/public/smartfolios/${id}/factsheet?file_format=pdf&file_type=backtest`}
              target="_blank"
              rel="noreferrer"
            >
              BackTests
            </a>
          </ButtonTheme>
          <ButtonTheme>
            <a
              href={`${FACTSHEET}/api/v2/public/smartfolios/${id}/factsheet?file_format=pdf&file_type=termsheet`}
              target="_blank"
              rel="noreferrer"
            >
              Termsheet
            </a>
          </ButtonTheme>
          {smartfolioMarket !== "Structured Notes" && (
            <ButtonTheme>
              <a
                href={`${FACTSHEET}/api/v2/public/smartfolios/${id}/factsheet?file_format=html`}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet
              </a>
            </ButtonTheme>
          )}
          <ButtonTheme
            primary={true}
            onClick={() =>
              history.push(
                // smartfolioMarket === "Private Equity" ||
                //   smartfolioMarket === "Structured Notes"
                //   ? `/invest/private/${id}`
                //   : `/invest/public/${id}`,

                smartfolioMarket === "Private Equity"
                  ? `/invest/private/${id}`
                  : smartfolioMarket === "Structured Notes"
                  ? `/invest/structured/${id}`
                  : `/invest/public/${id}`,
              )
            }
          >
            Invest
          </ButtonTheme>
        </Space>
      </div>
      <SingleSmartfolioDescription>{description}</SingleSmartfolioDescription>
    </header>
  );
};
export { Header as default };
