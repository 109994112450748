import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolveDurationData } from "src/store/Goal-Based/goal";
import moment from "moment";
import { UpdateFieldPayload } from "./goal";

const initialState: SolveDurationData = {
  start_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  contribution_type: 1,
  contribution_amount: "500",
  goal_amount: "10000",
  initial_amount: "1000"
};

const SolveDurationSlice = createSlice({
  name: "SolveInitial",
  initialState,
  reducers: {
    updateSolveDurationField: (state: any, action: PayloadAction<UpdateFieldPayload<SolveDurationData>>) => {
      const { name, value } = action.payload;
      state[name] = value;
    }
  },
});

export const { updateSolveDurationField } = SolveDurationSlice.actions;
export default SolveDurationSlice.reducer;
