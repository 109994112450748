import { createAction } from "@reduxjs/toolkit";

import { ClientRequestInterface } from "src/interfaces/StoreApi";
/*
 ** NOTE this file containes the actions that triggers the middleware
 ** All async / fetch actions
 */

export const apiCallBegan =
  createAction<ClientRequestInterface>("api/callBegan");
export const apiCallSuccess = createAction("api/callSuccess");
export const apiCallFailed = createAction<any>("api/callFailed");
