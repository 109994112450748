import { colors } from "src/theme/colors";

export const customScrollbar = `
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: ${colors.primary[500]} !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: ${colors.primary[500]} !important;
  }
  scrollbar-width: thin;

`;
