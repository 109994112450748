import React from "react";
import { StyledFormSection, StyledPredictionForm } from "../predictions";
import { FrequencyRadioField } from "../components/GoalAmountFormInputs";
import TimeDurationInput from "./Duration";
import AmountPredictionChart from "../components/AmountPredictionChart";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "src/store/rootReducer";
import AmountSlider from "../components/AmountSlider";
import { updateSolveInitialField } from "src/store/Goal-Based/SolveInitialSlice";
import { useGetSolveInitialStatsQuery } from "src/store/Goal-Based/goalBasedApi";
import { GoalStats, SolveInitialData } from "src/store/Goal-Based/goal";

export enum Fields {
  period = "period",
  startDate = "start_date",
  endDate = "end_date",
  contributionType = "contribution_type",
  contributionAmount = "contribution_amount",
  goalAmount = "goal_amount",
}

const SolveInitialForm: React.FC = () => {
  const solveInitialFormValues = useSelector(
    (state: RootStateType) => state.SolveInitialReducer,
  );
  const dispatch = useDispatch();

  const { data: stats, isFetching } = useGetSolveInitialStatsQuery(
    {input: solveInitialFormValues},
  );

  return (
    <StyledPredictionForm>
      <StyledFormSection>
        <AmountSlider
          titles={{
            title: "Contribution Amount",
            subtitle: "If I Would Like To Contribute:",
          }}
          max={10000}
          value={Number(solveInitialFormValues[Fields.contributionAmount])}
          name={Fields.contributionAmount}
          onChange={(number) =>
            dispatch(
              updateSolveInitialField({
                name: Fields.contributionAmount,
                value: number.toString(),
              }),
            )
          }
        />
        <FrequencyRadioField<SolveInitialData>
          updateField={updateSolveInitialField}
          value={solveInitialFormValues[Fields.contributionType]}
        />
      </StyledFormSection>
      <StyledFormSection>
        <AmountSlider
          titles={{
            title: "Final Amount",
            subtitle: "If I Would Like To Get At The End:",
          }}
          name={Fields.goalAmount}
          max={10000000}
          value={Number(solveInitialFormValues[Fields.goalAmount])}
          onChange={(number) =>
            dispatch(
              updateSolveInitialField({
                name: Fields.goalAmount,
                value: number.toString(),
              }),
            )
          }
        />
      </StyledFormSection>
      <StyledFormSection>
        <TimeDurationInput<SolveInitialData>
          updateField={updateSolveInitialField}
          value={solveInitialFormValues[Fields.period]}
          startDate={solveInitialFormValues[Fields.startDate]}
        />
      </StyledFormSection>
      <AmountPredictionChart
        titles={{
          title: "Investment Initial Amount",
          subtitle: "I Should Start With:",
        }}
        data={stats as GoalStats}
        isLoading={isFetching}
      />
    </StyledPredictionForm>
  );
};
export default SolveInitialForm;
