export const getErrorMessage = (error: any) => {
  const possibilities = [
    error?.response?.data?.error?.[0]?.message,
    error?.response?.data?.message?.error?.[0]?.message,
    error?.response?.data?.message?.message,
    error?.response?.data?.message,
    error?.response?.data,
    error,
    error?.message?.error?.[0]?.message,
    error?.message?.message,
    error?.message,
  ];

  for (let errorMessage of possibilities) {
    if (typeof errorMessage === "string") return errorMessage;
  }

  return "Oops, something went wrong.";
};
