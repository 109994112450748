import styled, { css } from "styled-components";
import btnVariables from "./theming";
import buttonVariant from "./buttonVariant";
import buttonSize from "./buttonSize";
import { typography } from "src/utils/styledComponents/typography";
import { darken } from "polished";
import { colors } from "src/theme/colors";
import { theme } from "src/theme/theme";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  font-weight: ${btnVariables.fontWeight};
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  user-select: none;
  width: 100%;
  outline: none;

  span {
    ${typography.CustomText("1rem")}
    letter-spacing: 0.48px;
    line-height: 1;
    @media only screen and (max-width: 767px) {
      ${typography.CustomText("1rem")}
    }
  }

  ${buttonSize(
  btnVariables.paddingBaseVertical,
  btnVariables.paddingBaseHorizontal,
  btnVariables.borderRadius,
)}
  .form-control-icon {
    display: inline-block;
    padding: 0px 0 0 10px;
  }
`;

export const Primary = styled(Button)
  `
  ${buttonVariant("white", colors.primary[500])}
  border: none !important;
  &.disabled,
  &[disabled],
  fieldset[disabled] {
    background-color: ${colors.primary[100]};
    color: ${colors.primary[500]};
    opacity: 0.5;
    &:hover,
    &:focus,
    &.focus {
      background-color: ${colors.primary[100]};
      color: ${colors.primary[500]};
    }
  }
`;

export const Outline = css`
  button {
    width: ${({ width }) => width || "initial"};
    color: ${colors.primary[500]};
    background-color: transparent;
    box-shadow: inset 0 0 0 1px  ${colors.primary[500]};
    border: none;
    &:hover,
    &:active,
    &:disabled,
    &:focus,
    &:active:focus,
    &[disabled],
    fieldset[disabled] {
      background-color: transparent;
      outline: 0;
      color: ${colors.primary[500]};
    }
    &:hover {
      color: ${colors.primary[600]};
      box-shadow: inset 0 0 0 1px  ${colors.primary[600]};
    }

    padding: 19px 0;
  }
`;

export const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: ${theme.colors.primary[500]};
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: ${theme.colors.primary[500]};
    border-color: ${theme.colors.primary[500]};
    background-color: transparent;
    text-shadow: none;
  }
  display: flex;
  justify-content: center;
  width: ${({ width }) => width || "auto"};
  height: 100%;
  margin: ${(props) => props.$margin && props.$margin};
  #save-btn {
    width: initial;
    color: #444655;
    background-color: transparent;
    text-decoration: underline;

    &:hover,
    &:active,
    &:disabled,
    &:focus,
    &:active:focus,
    &[disabled],
    fieldset[disabled] {
      background-color: transparent;
      outline: 0;
    }
    &:hover {
      color: ${colors.primary["500"]};
    }
  }
  #btn-orange {
    width: initial;
    color: #f2a20c;
    background-color: transparent;
    text-decoration: underline;

    &:hover,
    &:active,
    &:disabled,
    &:focus,
    &:active:focus,
    &[disabled],
    fieldset[disabled] {
      background-color: transparent;
      outline: 0;
    }
    span {
      ${typography.CustomText("1.125rem")}
      letter-spacing: 0.48px;
    }
    svg {
      font-size: 1.25rem;
      vertical-align: middle;
    }
    &:hover {
      color: #eab95e;
    }
  }

  #skip-btn {
    width: ${({ width }) => width || "initial"};
    color: ${theme.colors.primary[500]};
    background-color: transparent;
    border: 1px solid ${colors.primary["500"]};
    &:hover,
    &:active,
    &:disabled,
    &:focus,
    &:active:focus,
    &[disabled],
    fieldset[disabled] {
      background-color: transparent;
      outline: 0;
    }
    &:hover {
      color: #fff;
      background-color: ${darken(0.17, colors.primary["500"])};
    }
  }

  ${({ outline }) => outline && Outline}
`;
