import React from "react";

import {
  Container,
  DropDownBtn,
  DropDownContainer,
  DropDownContent,
  Content,
} from "./styled";
import AndroidIcon from "src/assets/AndroidApp.svg";
import AppleIcon from "src/assets/AppleApp.svg";
import PhoneIcon from "src/assets/PhoneApp.svg";

const MobileAction = () => {
  const [display, setDisplay] = React.useState(false);

  return (
    <Container>
      <DropDownBtn onClick={() => setDisplay((curr) => !curr)}>
        <img src={PhoneIcon} alt="phon app link" />
      </DropDownBtn>

      <DropDownContainer data-display={display ? "show" : "nonSow"}>
        <DropDownContent>
          <Content
            target="_blanc"
            href="https://play.google.com/store/apps/details?id=finamaze.com"
          >
            <img src={AndroidIcon} alt="androi app link" />
            Android
          </Content>
          <Content
            target="_blanc"
            href="https://apps.apple.com/fr/app/finamaze/id1587680469?l=en#?platform=iphone"
          >
            <img src={AppleIcon} alt="androi app link" />
            Apple
          </Content>
        </DropDownContent>
      </DropDownContainer>
    </Container>
  );
};

export default MobileAction;
