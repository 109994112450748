import { Col, Row, Steps, message } from "antd";
import { useState } from "react";
import { Flex } from "src/components/Flex";
import styled from "styled-components";
import { SecuritiesTable } from "./components";
import * as Styled from "./SmartfolioBuilder.css";
import { uniqueId } from "lodash";
import { CustomizeSmartfolio } from "./components/CustomizeSmartfolio/CustomizeSmartfolio";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  selectBasket,
  selectDescription,
  selectName,
} from "src/store/Goal-Based/SelfMadeSmartfolioSlice";
import { useBuildSmartfolioMutation } from "src/store/Goal-Based/goalBasedApi";
import { theme } from "src/theme/theme";
import { ButtonTheme } from "src/components/Button/ButtonTheme";

const { Step } = Steps;

const CostumStep = styled(Step)`
  &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${theme.colors.primary[500]};
  }
  &.ant-steps-item-process .ant-steps-item-icon {
    border-color: ${theme.colors.primary[500]};
  }
  &.ant-steps-item-finish .ant-steps-item-icon {
    background-color: white;
    border-color: ${theme.colors.primary[500]};
  }

  &.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.primary[500]};
  }
`
const steps = [
  {
    title: "Add Securities",
    content: SecuritiesTable,
    buttons: [
      {
        title: "Cancel",
      },
      {
        title: "Next",
        primary: true,
      },
    ],
  },
  {
    title: "Customize Smartfolio",
    content: CustomizeSmartfolio,
    buttons: [
      {
        title: "Previous",
      },
      {
        title: "Build",
        primary: true,
      },
    ],
  },
] as const;

type ButtonsTitle = (typeof steps)[number]["buttons"][number]["title"];

const StepsWrapper = styled.div`
  & > div {
    gap: 2rem;
  }
`;

export function SmartfolioBuilder({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [current, setCurrent] = useState(0);
  const basket = useSelector(selectBasket);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const dispatch = useDispatch<any>();
  const [buildSmartfolio, { isLoading }] = useBuildSmartfolioMutation();

  const Content = steps[current].content;

  const handlers: Record<ButtonsTitle, () => void> = {
    Next: () => {
      setCurrent((previous) => previous + 1);
    },
    Cancel: () => {
      setVisible(false);
      if (current === 0) dispatch(resetState());
    },
    Previous: () => {
      setCurrent((previous) => previous - 1);
    },
    Build: async () => {
      try {
        await buildSmartfolio({
          name,
          description,
          basket_items: basket.map(
            ({
              ticker_id,
              weight,
              minimum_bound_weight,
              maximum_bound_weight,
            }) => ({
              ticker_id,
              weight,
              minimum_bound_weight,
              maximum_bound_weight,
            }),
          ),
        }).unwrap();
        message.success("You have successfully created a new smartfolio!");
        dispatch(resetState());
      } catch (error) {
        message.error("Smartfolio creation failed!");
      }
      setCurrent(0);
      setVisible(false);
    },
  };

  return (
    <Styled.Wrapper
      visible={visible}
      onOk={null}
      onCancel={handlers.Cancel}
      footer={null}
      closable={false}
      width={1000}
    >
      <Flex flexDirection="column" alignItems="center" gap="1rem">
        <StepsWrapper>
          <Steps current={current}>
            {steps.map(({ title }) => (
              <CostumStep title={title} key={uniqueId()} />
            ))}
          </Steps>
        </StepsWrapper>
        <Flex width="100%" flexDirection="column" gap="1rem">
          <Content />
          <Row justify="center" gutter={[16, 16]}>
            {steps[current].buttons.map(({ title, primary }) => (
              <Col lg={4}>
                <ButtonTheme
                  block
                  style={{ borderRadius: 8 }}
                  size={"large"}
                  primary={primary}
                  onClick={async () => await handlers[title]()}
                  loading={title === "Build" && isLoading}
                  disabled={
                    (title === "Next" && basket.length < 2) ||
                    (title === "Build" &&
                      (basket.length < 2 ||
                        name.length < 5 ||
                        description.length < 5 ||
                        basket.some((security) => security.ticker_id < 0) ||
                        basket
                          .slice(1)
                          .some((security) => security.weight <= 0)))
                  }
                >
                  {title}
                </ButtonTheme>
              </Col>
            ))}
          </Row>
        </Flex>
      </Flex>
    </Styled.Wrapper>
  );
}
