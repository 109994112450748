import Invest from "src/components/Invest";
import { FC } from "react";

interface InvestModalProps {
    setModalVisible: any;
    isModalVisible: boolean;
    children: any
}

export const InvestModal: FC<InvestModalProps> = ({ setModalVisible, isModalVisible, children }) => {
    return (
        <Invest.Modal
            title={null}
            closable={false}
            handleCancel={() => setModalVisible(false)}
            isModalVisible={isModalVisible}
            handleOk={() => console.log()}
            footer={null}
        >
            {children}
        </Invest.Modal>
    )
}