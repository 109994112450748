import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { includes } from "lodash";

const SmallStat = ({ date, stat }: { date: string; stat: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <span
        style={{
          color: "#475467",
          fontWeight: "700",
          fontSize: "16px",
          whiteSpace: "nowrap",
        }}
      >
        {date}
      </span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: "16px",
          padding: "2px 10px 2px 8px",
          backgroundColor: Number(stat) > 0 ? "#ECFDF3" : "#FEF3F2",
        }}
      >
        {Number(stat) > 0 ? (
          <ArrowUpOutlined
            style={{
              color: "#12B76A",
              fontWeight: "700",
            }}
          />
        ) : (
          <ArrowDownOutlined
            style={{
              color: "#F04438",
              fontWeight: "700",
            }}
          />
        )}
        <span
          style={{
            color: Number(stat) > 0 ? "#027A48" : "#B42318",
            fontWeight: "500",
            whiteSpace: "nowrap",
          }}
        >
          {(Number(stat) * 100).toFixed(2)}
        </span>
      </div>
    </div>
  );
};
export { SmallStat as default };
export const MiniMetric = SmallStat;
