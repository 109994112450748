import {
    createDraftSafeSelector,
    createSlice,
    PayloadAction,
  } from "@reduxjs/toolkit";
  import { RootStateType } from "../rootReducer";
  import { SelfMadeSmartfolio, BasketItem, Security } from "./goal";
  
  const initialState: SelfMadeSmartfolio = {
    name: "",
    description: "",
    basket_items: [
      {
        weight: 1,
        ticker_id: 1,
        symbol: "CASH",
        name: "",
        maximum_bound_weight: 1,
        minimum_bound_weight: 0,
      },
    ],
  };
  
  const EditSmartfolioSlice = createSlice({
    name: "editSmartfolio",
    initialState,
    reducers: {
      resetStateEdit: (state) => initialState,
      setNameEdit: (state, action: PayloadAction<string>) => {
        state.name = action.payload;
      },
      setDescriptionEdit: (state, action: PayloadAction<string>) => {
        state.description = action.payload;
      },
      setBasketEdit: (state, action: PayloadAction<BasketItem[]>) => {
        state.basket_items = state.basket_items
          .slice(0, 1)
          .concat(action.payload);
      const scaledCashWeight = 1000 - action.payload.reduce((acc, curr) => acc + curr.weight * 1000, 0);
        state.basket_items[0].weight = +(scaledCashWeight / 1000).toFixed(2);
      },
      addBasketItemEdit: (state, action: PayloadAction<BasketItem | undefined>) => {
        const newItem = action.payload ?? {
          weight: 0,
          ticker_id: -1,
          maximum_bound_weight: 1,
          minimum_bound_weight: 0,
          symbol: "",
          name: ""
        };
  
        state.basket_items.push(newItem);
      },
      updateBasketItemEdit: (
        state,
        action: PayloadAction<{
          index: number;
          attribute: string;
          value: number | string;
        }>,
      ) => {
        const { index, attribute } = action.payload;
        let newValue = action.payload.value;
        if (attribute === "weight") {
          let scaledCashWeight = state.basket_items[0].weight * 1000;
          scaledCashWeight += state.basket_items[index].weight * 1000 - Number(newValue) * 10;
          state.basket_items[0].weight = scaledCashWeight / 1000;
        }
        if (attribute.includes("weight"))
          newValue = +(newValue as number / 100).toFixed(2);
        state.basket_items[index][attribute] = newValue;
      },
      updateBasketInfoEdit: (
        state,
        action: PayloadAction<{ index: number; security: Security }>,
      ) => {
        const { index, security } = action.payload;
        state.basket_items[index] = {
          ...state.basket_items[index],
          ticker_id: security.key,
          name: security.security,
          symbol: security.symbol,
        };
      },
      removeBasketItemEdit: (state, action: PayloadAction<number>) => {
        const index = action.payload;
        if (index) {
          const scaledCashWeight: number = state.basket_items[0].weight * 1000 + +state.basket_items[index].weight * 1000;
          
          state.basket_items[0].weight = scaledCashWeight / 1000;
          state.basket_items.splice(index, 1);
        }
      },
    },
  });
  
  export const {
    setNameEdit,
    setDescriptionEdit,
    addBasketItemEdit,
    updateBasketItemEdit,
    removeBasketItemEdit,
    updateBasketInfoEdit,
    setBasketEdit,
    resetStateEdit
  } = EditSmartfolioSlice.actions;
  
  export const selectBasketEdit = createDraftSafeSelector(
    (state: RootStateType) => state,
    (state) => state.SelfMadeEditReducer.basket_items,
  );
  
  export const selectNameEdit = createDraftSafeSelector(
    (state: RootStateType) => state,
    (state) => state.SelfMadeEditReducer.name,
  );
  
  export const selectDescriptionEdit = createDraftSafeSelector(
    (state: RootStateType) => state,
    (state) => state.SelfMadeEditReducer.description,
  );
  
  export const selectSmartfolioEdit = createDraftSafeSelector(
    (state: RootStateType) => state,
    (state) => state.SelfMadeEditReducer,
  );
  
  export const selectCashWeightEdit = createDraftSafeSelector(
    (state: RootStateType) => state,
    (state) => state.SelfMadeEditReducer.basket_items[0].weight,
  );
  
  export default EditSmartfolioSlice.reducer;
  