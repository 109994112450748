import React from "react";
import Disclaimer from "src/components/Disclaimer";
import CollapseItem from "src/components/CollapseItem";

import { CryptoItems } from "./list/cryptoList";
import { PublicMarketItems } from "./list/publicMarketList";

export const DisclaimerContainer = ({ disclaimer, clientType }) => {
  const retailsItems = CryptoItems(clientType); 
  const Items = disclaimer === "crypto" ? retailsItems : PublicMarketItems;

  return (
    <Disclaimer>
      <Disclaimer.Inner>
        {Items?.map((item) => (
          <CollapseItem key={item.title} title={item.title}>
            {item.Text}
          </CollapseItem>
        ))}
      </Disclaimer.Inner>
    </Disclaimer>
  );
};
