import { useQuery } from "react-query";
import { userI } from "src/interfaces/Client";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

const useClient = () =>
  useQuery<userI>(
    "User/me",
    async () =>
      (
        await axios.request({
          url: `${baseUrl}/me`,
          method: "get",
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
      ).data,
    {
      refetchInterval: 3600000, // one time per hour
      refetchOnWindowFocus: false,
    },
  );

export default useClient;
