import { Radio } from "antd";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export const RadioGroup = styled(Radio.Group)`
    display: flex;
    flex-wrap: wrap;
    .ant-radio-button-wrapper {
        text-align: center;
        flex: 1 !important;
        white-space: nowrap;
    }
    .ant-radio-button-wrapper:hover {
        color: ${theme.colors.primary[500]}
    }

    &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: ${theme.colors.primary[500]};
        border-color: ${theme.colors.primary[500]}
    }
    
`

export const RadioGroupTif = styled(Radio.Group)`
    // display: flex;
    // flex-wrap: wrap;
    // .ant-radio-button-wrapper {
    //     text-align: center;
    //     flex: 1 !important;
    //     white-space: nowrap;
    // }
`

export const RadioGroupPL = styled(Radio.Group)`
    & .ant-radio-button-wrapper {
        border: none;
        &::before {
            display: none;
          }
        &::after {
          display: none;
        }
    }
    & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none;
    }
`