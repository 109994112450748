import React, { useEffect } from "react";
import { Typography, ModalProps } from "antd";
import { Form, Icon, Button } from "src/components";

import {
  Modal,
  Header,
  DoneContainer,
  DoneFrame,
  DoneText,
  Small,
} from "./styles/successModal";

import ArrowCircleright from "src/assets/ArrowCircleright.svg";
import { theme } from "src/theme/theme";

interface SuccessModalProps extends ModalProps {
  icon?: string;
  text?: React.ReactNode;
  title?: React.ReactNode;
  definition?: React.ReactNode;
  smallText?: React.ReactNode;
  onDone?: () => void;
}

const SuccessModal = ({
  visible,
  onOk,
  onCancel,
  icon,
  title,
  text,
  definition,
  smallText,
  onDone,
  ...rest
}: SuccessModalProps) => {
  useEffect(() => {
    let timeId: number;

    if (visible) {
      setTimeout(() => {
        onDone();
      }, 10 * 1000);
    }
    return () => clearTimeout(timeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      maskStyle={{
        backdropFilter: "blur(10px)",
      }}
      title={<Header>{title}</Header>}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={"60%"}
      footer={[
        <Form.Action
          style={{ width: "40%" }}
          position="center"
          key="customFooter"
        >
          <Button
            key="done"
            onClick={onDone}
            width="80%"
            className="test-success-button"
          >
            <Form.ButtonContainer>
              Done
              <Icon size="20px" color="white" src={ArrowCircleright} />
            </Form.ButtonContainer>
          </Button>
        </Form.Action>,
      ]}
      {...rest}
    >
      <DoneContainer>
        <img src={icon} alt="" />
        <Typography.Text type="success">
          <DoneFrame>
            <DoneText color={theme.colors.success["500"]}>{text}</DoneText>
          </DoneFrame>
        </Typography.Text>
        <Small>{smallText}</Small>
        <DoneFrame width="70%">
          <Typography.Text>{definition}</Typography.Text>
        </DoneFrame>
      </DoneContainer>
    </Modal>
  );
};

export default SuccessModal;
