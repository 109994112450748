import { Flex } from "src/components/Flex";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex: 1;

  & .ant-card {
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
  }
`;
