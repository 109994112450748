import { Button, Card, Tabs } from "antd";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export const SingleSmartfolioTitle = styled.h1`
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const SingleSmartfolioDescription = styled.p`
  color: #565656;
  font-weight: 400;
  font-size: 16px;
`;
export const StyledHeaderButton = styled(Button)`
  border-radius: 5px;
  margin-left: 8px
`;
export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2478f6;
    font-weight: 600;
    background-color: #f9fafb;
  }
  .ant-tabs-ink-bar {
    background-color: ${theme.colors.primary["500"]};
  }
  .ant-tabs-tab:hover {
    color: ${theme.colors.primary["600"]};
  }
  div.ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }
  .ant-tabs-tab-btn {
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    color: #667085;
    font-weight: 600;
    width: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
  }
  div.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }
  div.ant-tabs-nav-list {
    overflow-x: hidden;
  }
`;
export const StyledSecurityCard = styled(Card)`
  .ant-card-head-title {
    font-size: 20px;
    color: #101828;
    font-weight: bold;
  }
`;
export const StyledMainExposureBox = styled.div`
  border-radius: 5px;
  width: 231px;
  /* height: 80px; */
  padding: 7px 15px 7px 15px;
  background-color: #f7f9fb;
`;
export const StyledNumberOfIndividualExposuresBox = styled.div`
  background-color: #2478f6;
  width: 100%;
  height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export const PieChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  columns: 2;
  flex-direction: column;
  @media only screen and (max-width: 767px) {
  }
`;
