import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl, baseUrl_V2 } from "src/utils/config";

const useCustomGroupedPositions = () =>
  useQuery(
    "/Positions/stat/v2",
    async () =>
      (await axios(`${baseUrl_V2}/dashboard/investments/positions`)).data.data,
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
    },
  );

export { useCustomGroupedPositions };
