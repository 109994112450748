import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';

const ButtonContent = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading, icon, children, customIcon } = props;

  return (
    <React.Fragment>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
      {!loading ? <span>{children}</span> : null}
      {!loading && (icon || customIcon) ? (
        <div className="form-control-icon">
          <FontAwesomeIcon
            icon={customIcon ? customIcon : faArrowCircleRight}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ButtonContent;
