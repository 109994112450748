import ProTable from "@ant-design/pro-table";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export const ProTableTheme = styled(ProTable)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${theme.colors.primary[500]};
        border-color: ${theme.colors.primary[500]};
    }
`

