import { useQuery } from "react-query";
import axiosInstance from "src/utils/axiosInstance";

const getTickerPrice = async (symbol: string) => {
    const TICKER_LIVE_PRICE_ENDPOINT = "dashboard/prices/live/symbol"
    const res = await axiosInstance.get(`${TICKER_LIVE_PRICE_ENDPOINT}/${symbol}`, { baseURL: "v2" })
    return res.data
}

export const useGetTickerPrice = (symbol: string) => {
    return useQuery(["stockPrice", symbol], () => getTickerPrice(symbol));
}
