import { IMarketClassifiedSmartfolios } from "src/interfaces/ClassifiedSmartfolios";
import { userInterface } from "src/interfaces/Client";
import { Layers } from "src/utils/products";

const activeStatus = ["Open", "Exiting"];

const riskProfile = {
  [Layers?.LBASE]: {
    prudent: 65,
    moderate: 50,
    risky: 34,
  },
  [Layers?.LTHEMATIC]: {
    prudent: 25,
    moderate: 33,
    risky: 33,
  },
  [Layers?.LOPPURTUNISTIC]: {
    prudent: 10,
    moderate: 17,
    risky: 33,
  },
};

const clientTypes = {
  prudent: { min: 0, max: 400 },
  moderate: { min: 400, max: 750 },
  risky: { min: 750 },
};

const getClientType = (score) => {
  return score < clientTypes.prudent.max
    ? "prudent"
    : score < clientTypes.moderate.max
    ? "moderate"
    : "risky";
};

export const getCurrentLayerPercentageColor = (percentage, score, layer) => {
  return riskProfile[layer][getClientType(score)] - 5 < percentage &&
    percentage < riskProfile[layer][getClientType(score)] + 5
    ? "#00c92f"
    : "#f2a20c";
};

export const getRiskProfileDetails = ({
  values,
  positions,
  data,
  user,
}: {
  values: any;
  positions: any;
  data: IMarketClassifiedSmartfolios;
  user: userInterface;
}) => {
  const score = user?.data?.riskProfile?.spiderWebScore?.totalScore;

  if (data && positions && values.layer && values.smartfolio) {
    const { smartfolio } = values;
    const currentSmartfolio = data?.All.assetClass.All.smartfolio?.find(
      (a) => a.name === smartfolio,
    );
    if (!currentSmartfolio) return;

    const { layer: currentLayer, assetClass: currAssetClass } =
      currentSmartfolio;

    const activePositions = positions.filter((elm) =>
      activeStatus.includes(elm.status),
    );

    const activePositionsNav = activePositions.reduce(
      (acc, cur) => acc + cur.investmentNAV,
      0,
    );

    const currentLayerActivePositions = activePositions.filter(
      (elm) => elm.layer === currentLayer,
    );

    const currentLayerActivePositionsNav = currentLayerActivePositions.reduce(
      (acc, cur) => acc + cur.investmentNAV,
      0,
    );

    const currentLayerPercentage =
      activePositionsNav === 0
        ? 0
        : Math.abs((currentLayerActivePositionsNav / activePositionsNav) * 100);

    const color = getCurrentLayerPercentageColor(
      currentLayerPercentage,
      score,
      currentLayer,
    );

    const suggestedPercentage = riskProfile[currentLayer][getClientType(score)];

    return {
      currentLayer,
      currentLayerNav: currentLayerActivePositionsNav,
      currentLayerPercentage: Number(currentLayerPercentage).toFixed(0),
      suggestedPercentage,
      color,

      activePosionsNav: activePositionsNav,
      currAssetClass,
    };
  }
};
