import { InputNumber, Modal } from "antd";
import { colors } from "src/theme/colors";
import styled from "styled-components";

export const Wrapper = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }

  & .graph {
    @media (max-width: 991px) {
      display: none;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;

  &.head > * {
    color: #0d4ba8;
    margin-left: 8px;
  }
`;

export const Input = styled(InputNumber)`
  border-radius: 5px;
  border: 1px solid ${colors.primary["500"]};
  background: #f7f9fb;
  padding: 4px 0;
  color: ${colors.primary["500"]};
`;

export const Background = styled.div`
  border-radius: 8px;
  background: #f7f9fb;
  height: 100%;
  padding: 1rem;
`;

export const Title = styled.h1`
  font-size: 1.2rem;
`;
