import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";

export interface IStyleProviderProps {
  children: React.ReactNode;
}

const StyleProvider = ({ children }: IStyleProviderProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default StyleProvider;
