import React, { Children, FC, useState } from "react";
import * as Styled from "./Card.css";
import { CardProps } from "antd";
import { IWrapper, Toggle } from "../Toggle";
import { Flex } from "src/components/Flex";

interface ICard extends CardProps {
  hideExtra?: boolean;
}

export const Card: FC<ICard> = ({ children, extra, hideExtra, ...rest }) => {
  const [side, setSide] = useState<IWrapper["side"]>("left");

  const handleClick = () => {
    setSide((previous) => {
      if (previous === "left") return "right";

      return "left";
    });
  };

  const childrenArray = React.Children.toArray(children);
  const content = side === "left" ? childrenArray[0] : childrenArray[1];

  return (
    <Styled.Wrapper
      {...rest}
      extra={
        hideExtra ? null : (
          <Flex gap="1rem">
            <Flex onClick={handleClick}>
              <Toggle side={side} />
            </Flex>
            {extra}
          </Flex>
        )
      }
      headStyle={{
        padding: "initial",
        margin: "0 24px",
      }}
    >
      {content}
    </Styled.Wrapper>
  );
};
