import React from "react";
import { StyledFormSection, StyledPredictionForm } from "../predictions";
import { FrequencyRadioField } from "../components/GoalAmountFormInputs";
import TimeDurationInput from "./Duration";
import AmountPredictionChart from "../components/AmountPredictionChart";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "src/store/rootReducer";
import AmountSlider from "../components/AmountSlider";
import { updateContributionField } from "src/store/Goal-Based/ContributionAmountSlice";
import { useGetContributionAmountStatsQuery } from "src/store/Goal-Based/goalBasedApi";
import { ContributionData, GoalStats } from "src/store/Goal-Based/goal";

export enum Fields {
  period = "period",
  startDate = "start_date",
  endDate = "end_date",
  contributionType = "contribution_type",
  initialAmount = "initial_amount",
  contributionAmount = "contribution_amount",
}

const ContributionForm: React.FC = () => {
  const contributionFormValues = useSelector(
    (state: RootStateType) => state.ContributionAmountReducer,
  );
  const dispatch = useDispatch();

  const { data: stats, isFetching } = useGetContributionAmountStatsQuery({
    input: contributionFormValues,
  });

  return (
    <StyledPredictionForm>
      <StyledFormSection>
        <AmountSlider
          titles={{
            optional: true,
            title: "Investment Initial Amount",
            subtitle: "If I Would Like to Start with:",
          }}
          name={Fields.initialAmount}
          max={50000}
          value={Number(contributionFormValues[Fields.initialAmount])}
          onChange={(number) =>
            dispatch(
              updateContributionField({
                name: Fields.initialAmount,
                value: number.toString(),
              }),
            )
          }
        />
      </StyledFormSection>
      <StyledFormSection>
        <AmountSlider
          titles={{
            title: "Contribution Amount",
            subtitle: "If I Would Like To Contribute:",
          }}
          max={1000000}
          value={Number(contributionFormValues[Fields.contributionAmount])}
          name={Fields.contributionAmount}
          onChange={(number) =>
            dispatch(
              updateContributionField({
                name: Fields.contributionAmount,
                value: number.toString(),
              }),
            )
          }
        />
        <FrequencyRadioField<ContributionData>
          updateField={updateContributionField}
          value={contributionFormValues[Fields.contributionType]}
        />
      </StyledFormSection>
      <StyledFormSection>
        <TimeDurationInput<ContributionData>
          updateField={updateContributionField}
          value={contributionFormValues[Fields.period]}
          startDate={contributionFormValues[Fields.startDate]}
        />
      </StyledFormSection>
      <AmountPredictionChart
        titles={{
          title: "Final / Goal Amount",
          subtitle: "I Should Expect To Get At The End:",
        }}
        data={stats as GoalStats}
        isLoading={isFetching}
      />
    </StyledPredictionForm>
  );
};
export default ContributionForm;
