import { ColorMap } from "src/interfaces/Theme";

export const themePalette = [
  "#00a6ed",
  "#dd5800",
  "#006ffc",
  "#eb862f",
  "#5e40dd",
  "#00d5de",
  "#ff4d88",
  "#00586d",
  "#96006a",
  "#6674db",
  "#f0ad4e",
];

const colors: ColorMap = {
  primary: "#2178FF",
  secondary: "#9ebff0",
  dark: "#343a40",
  accent: "#eaf2fa",
  darkBlue: "#083b6b",
  gray: "#92a3bb",
  grayLight: "#dce5ec",
  grayLighter: "#f7f9fb",
  primaryDark: "#1b69df",
  primarylight: "#2178FF3D",
  fadePink: "#ffdce0",
  darkPink: "#f1baba",
  white: "#fff",
  success: "#16b881",
  successLight: "#7ecd78",
  danger: "#dd093c",
  dangerLight: "#f8cdd8",
  transparent: "transparent",
  orange: "#f0ad4e",
  black: "black",
};

export default colors;
