import React from "react";
import { InputNumber } from "antd";
import styled from "styled-components";
import { theme } from "src/theme/theme";

const MAX_YEARS = 30;
const MAX_MONTHS = 11;

interface Props {
  years: number;
  months: number;
  handleMonthsChange: (value: number | undefined) => void;
  handleYearsChange: (value: number | undefined) => void;
}

const DurationInputContainer = styled.div`
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.primary[500]};
  background: ${theme.colors.primary[25]};
  display: flex;
  gap: 10px;
  align-items: space-between;
`;
const NumberInput = styled(InputNumber)`
  border: none;
  width: 25px;
  &.ant-input-number {
    background: ${theme.colors.primary[25]};
  }
  .ant-input-number-input {
    padding: 0;
    color: ${theme.colors.primary[500]};
  }
  &.ant-input-number-focused {
    box-shadow: none;
    border-color: transparent;
  }
  & > div {
    position: relative;
  }

  & > div > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div > div::before,
  & > div > div::after {
    display: none;
  }
`;

const Label = styled.span`
  margin: 0;
  padding: 0;
  z-index: 5;
`;

const NoWrappingDiv = styled.div`
  white-space: nowrap;
`;

const DurationInput: React.FC<Props> = ({
  years,
  months,
  handleMonthsChange,
  handleYearsChange,
}) => {
  return (
    <DurationInputContainer>
      <NoWrappingDiv>
        <NumberInput
          controls={false}
          onChange={handleYearsChange}
          value={years}
          min={months ? 0 : 1}
          max={MAX_YEARS}
        />
        <Label>Years</Label>
      </NoWrappingDiv>
      <NoWrappingDiv>
        <NumberInput
          controls={false}
          value={months}
          onChange={handleMonthsChange}
          min={years ? 0 : 1}
          max={MAX_MONTHS}
        />
        <Label>Months</Label>
      </NoWrappingDiv>
    </DurationInputContainer>
  );
};

export default DurationInput;
