import { useQuery } from "react-query"
import axiosInstance from "src/utils/axiosInstance"

const GET_BROKERAGE_POSITIONS_ENDPOINT = "dashboard/direct-orders/positions"

const fetshBrokeragePositions = async () => {
    const { data: { data: positions } } = await axiosInstance.get(GET_BROKERAGE_POSITIONS_ENDPOINT, { baseURL: "v2", params: { page_size: 10, page_number: 1, flag: "OPEN" }, })
    return positions
}

export const useBrokeragePositions = () => {
    return useQuery("brokeragePositions", fetshBrokeragePositions)
}