import styled from "styled-components/macro";
import { typography } from "../../../utils/styledComponents/typography";

import { Button as AntButton } from "antd";
import { IPAD } from "../../../utils/constants/media";
import { theme } from "src/theme/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  ${typography.CustomText("1em")}
`;

export const Form = styled.div`
  /* margin-top: 4.8125rem; */
  width: 100%;

  @media (max-width: ${IPAD}px) {
    width: 100% !important;
  }
`;

export const Title = styled.h1`
  ${typography.CustomMeriText("1.625rem")}
  font-weight: bold;
`;

export const Tag = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 5px;
  max-width: 78px;
  gap: 0px 5px;
`;

export const TagTitle = styled.span`
  font-size: 12px;
  color: white;
  font-weight: bold;
`;

export const Small = styled.span`
  ${typography.CustomText("0.875rem")}
  font-weight: normal;
  color: #000;
  opacity: 0.5;
`;

export const Header = styled.h1`
  margin-bottom: 3rem;
  color: ${theme.colors.primary["500"]};
  ${typography.CustomMeriText("1.625rem")}
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled(AntButton)`
  &.ant-btn,
  &.ant-btn:hover {
    margin-right: 8px;
    margin-bottom: 12px;
    border-radius: 5px;
    color: ${theme.colors.primary["500"]};
    border-color: ${theme.colors.primary["500"]};
    background: ${theme.colors.primary["100"]};
  }
  &.ant-btn-dangerous {
    color: #ff4d4f;
    background: #dc354533;
    border-color: #ff4d4f;
    font-weight: 600;
  }

  &.ant-btn-primary {
    font-weight: 600;
    &.ant-btn-background-ghost {
      background: #e8f1ff !important;
      &:hover {
        background: transparent !important;
      }
    }
  }
`;

export const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;

  .ant-avatar {
    border-radius: 12px;
  }
  @media (max-width: ${IPAD}px) {
    flex-direction: column;
  }
`;

export const PictureIcon = styled.div`
  height: 40px;
  width: 40px;
  border: 4px solid #fff;
  background-color: #e8f1ff;
  position: absolute;
  bottom: -21px;

  left: 38%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 5px;
`;

export const Picture = styled.div`
  position: relative;

  ${PictureIcon} {
  }
`;

export const Timestamp = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #000;
  opacity: 0.5;
  font-size: 12px;

  span {
    text-transform: capitalize;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${IPAD}px) {
    flex-direction: column;
  }
`;

export const Name = styled.h1`
  margin: 0;
  text-transform: capitalize;
`;

export const Action = styled.div``;

export const Job = styled.div``;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2em;

  @media (max-width: ${IPAD}px) {
    margin: 0;
    margin-top: 1em;
    align-items: center;
  }
`;

export const PasswordHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
