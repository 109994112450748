import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { userI } from "src/interfaces/Client";
// import { RootStateType } from "./rootReducer";
import { apiCallBegan } from "./ApiActions";
import { userInterface } from "src/interfaces/Client";
/*
 ** Notes:
 ** - client state object is of type ClinetDataRes (src/intefaces/Client.d.ts)
 ** - client is the current user.
 */

/*
 *******************************************************************************
 ** reducer
 */

const initialState = {
  data: [],
  isLoading: true,
};

const slice = createSlice({
  name: "smartfolio",
  initialState,
  reducers: {
    smartfolioRequest: (state) => {
      state.isLoading = true;
    },
    smartfolioResponse: (
      state,
      action,
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});
export const { smartfolioRequest, smartfolioResponse } =
  slice.actions;

/*
 *******************************************************************************
 ** action Creators
 */

const loadSmartfolio = () =>
  apiCallBegan({
    url: "/products/public",
    onStart: smartfolioRequest.type,
    onSuccess: smartfolioResponse.type,
    // onFail: clientFailed.type,
  });

/*
 *******************************************************************************
 ** exports
 */

export { loadSmartfolio };
export default slice.reducer;
