import axios from "src/utils/axiosInstance";

export const fetchAccountStatementFile = async (
  filename: string,
  reportDate: string,
) => {
  try {
    const res = await axios.get(`/account-statement-file/${filename}`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Activity ${reportDate}.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  } catch ({ response }) {
    const data = response?.data;
    let message = data?.message;
    message = message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};
