import { Button } from "antd";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export const ButtonTheme = styled(Button)`
    ${(props) => props.display === "flex" && `
        display: flex;
        align-items: center;
        justify-content: center;
    `}

    ${(props) => (props.primary && !props.danger) && `
        color: white;
        border-color: ${theme.colors.primary[500]};
        background: ${theme.colors.primary[500]};
        &:hover {
            background: ${theme.colors.primary[400]};
            color: white;
            border-color: ${theme.colors.primary[400]};
        }
        &:focus {
            background: ${theme.colors.primary[400]};
            color: white;
            border-color: ${theme.colors.primary[400]};
        }
    `}
    
    ${(props) => (!props.primary && !props.danger) && `
        &:hover {
            color: ${theme.colors.primary[500]};
            border-color: ${theme.colors.primary[400]};
        }
        &:focus {
            color: ${theme.colors.primary[500]};
            border-color: ${theme.colors.primary[400]};
        }
    `}
`