import React, { useCallback, useState } from "react";
import { StyledFormSection, StyledPredictionForm } from "../predictions";
import { FrequencyRadioField } from "../components/GoalAmountFormInputs";
import TimeDurationInput, { StyledDate, StyledDatePicker } from "./Duration";
import AmountPredictionChart from "../components/AmountPredictionChart";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "src/store/rootReducer";
import AmountSlider from "../components/AmountSlider";
import { updateSolveDurationField } from "src/store/Goal-Based/SolveDurationSlice";
import { useGetSolveDurationStatsQuery } from "src/store/Goal-Based/goalBasedApi";
import { GoalStats, SolveDurationData } from "src/store/Goal-Based/goal";
import { StyledSubtitle } from "../components/SliderTitle";
import moment from "moment";

export enum Fields {
  startDate = "start_date",
  contributionType = "contribution_type",
  contributionAmount = "contribution_amount",
  goalAmount = "goal_amount",
  initialAmount = "initial_amount",
}

const SolveDurationForm: React.FC = () => {
  const solveDurationFormValues = useSelector(
    (state: RootStateType) => state.SolveDurationReducer,
  );
  const dispatch = useDispatch();

  const { data: stats, isFetching } = useGetSolveDurationStatsQuery(
    {input: solveDurationFormValues},
  );

  const [startDate, setStartDate] = useState(moment(moment(solveDurationFormValues[Fields.startDate])));

  const disabledDate = useCallback((current) => {
    return current && current < moment().startOf("day");
  }, []);

  const handleStartDateChange = useCallback((date) => {
    setStartDate(date);
    dispatch(
      updateSolveDurationField({
        name: Fields.startDate,
        value: date.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
      }),
    );
  }, []);

  return (
    <StyledPredictionForm>
      <StyledFormSection>
        <AmountSlider
          titles={{
            optional: true,
            title: "Investment Initial Amount",
            subtitle: "If I Would Like to Start with:",
          }}
          name={Fields.initialAmount}
          max={50000}
          value={Number(solveDurationFormValues[Fields.initialAmount])}
          onChange={(number) =>
            dispatch(
              updateSolveDurationField({
                name: Fields.initialAmount,
                value: number.toString(),
              }),
            )
          }
        />
      </StyledFormSection>
      <StyledFormSection>
        <AmountSlider
          titles={{
            title: "Final Amount",
            subtitle: "If I Would Like To Get At The End:",
          }}
          name={Fields.goalAmount}
          max={10000000}
          value={Number(solveDurationFormValues[Fields.goalAmount])}
          onChange={(number) =>
            dispatch(
              updateSolveDurationField({
                name: Fields.goalAmount,
                value: number.toString(),
              }),
            )
          }
        />
      </StyledFormSection>
      <StyledFormSection>
        <AmountSlider
          titles={{
            title: "Contribution Amount",
            subtitle: "If I Would Like To Contribute:",
          }}
          max={10000}
          value={Number(solveDurationFormValues[Fields.contributionAmount])}
          name={Fields.contributionAmount}
          onChange={(number) =>
            dispatch(
              updateSolveDurationField({
                name: Fields.contributionAmount,
                value: number.toString(),
              }),
            )
          }
        />
        <StyledDate>
          <StyledSubtitle>Starting From:</StyledSubtitle>
          <StyledDatePicker
            value={startDate}
            onChange={handleStartDateChange}
            disabledDate={disabledDate}
          />
        </StyledDate>
        <FrequencyRadioField<SolveDurationData>
          updateField={updateSolveDurationField}
          value={solveDurationFormValues[Fields.contributionType]}
        />
      </StyledFormSection>
      <AmountPredictionChart
        titles={{
          title: "Investment Duration",
          subtitle: "I Should Expect To Contribute For:",
        }}
        data={stats as GoalStats}
        isLoading={isFetching}
      />
    </StyledPredictionForm>
  );
};
export default SolveDurationForm;
