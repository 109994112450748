import styled, { css } from "styled-components";
import { typography } from "src/utils/styledComponents/typography";
import {
  Collapse as AntCollapse,
  Modal as AntModal,
  Alert as AntAlret,
  Select as MSelect,
  InputNumber as AntInputNumber,
  Input as AntInput,
  Radio as ARadio,
} from "antd";

import { LARGE_SCREEN, IPAD, DESKTOP, MOBILE } from "src/utils/constants/media";
import { colors } from "src/theme/colors";

export const Container = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  ${typography.CustomText("1em")}
  align-items: center;
  gap: 3rem;
`;

export const Alert = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: #00c92f1a;
  padding: 1em 2em;
  border-radius: 5px;
`;

const WhiteIcon = css`
  filter: invert(100%);
`;

const IconSize = css<{ size?: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

export const Icon = styled.img<{
  color?: "blue" | "white" | "green";
  size?: string;
}>`
  filter: invert(64%) sepia(48%) saturate(6743%) hue-rotate(101deg)
    brightness(104%) contrast(101%);
  ${({ color }) => color === "white" && WhiteIcon}
  ${({ size }) => size && IconSize}
`;

export const Message = styled.div`
  margin-left: 1em;
`;

export const ActiveStep = css`
  font-weight: 600;
  color: #2478f6;
  opacity: 1;
`;

export const DoneStep = css`
  font-weight: 600;
  color: #00c92f;
  opacity: 1;
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  /* margin: 2em 0; */
  font-size: 20px;
  font-family: "proxima-nova";

  @media (max-width: ${IPAD}px) {
    display: none;
  }
`;

export const Step = styled.div<{ active?: boolean; done?: boolean }>`
  color: ${colors.primary[900]};
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
  ${({ active }) => active && ActiveStep}
  ${({ done }) => done && DoneStep}
  color: ${colors.primary[500]};
`;

export const Divider = styled.div<{ current }>`
  flex: 0.8;
  height: 2px;
  background: ${({ current }) =>
    current > 1 ? colors.primary[400] : colors.primary[500]};
  position: relative;
  transition: all 0.2s linear;

  &::before {
    position: absolute;
    content: " ";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${colors.primary[500]};
    top: -6px;
    ${({ current }) => (current > 1 ? "right: 0" : "left: 0")};
  }
`;

export const StepDot = styled.span`
  height: 15px;
  width: 15px;
  background-color: ${colors.primary[500]};
  border-radius: 50%;
  margin: 0 7px;
  transition: all 0.2s linear;

  &::after {
    content: " ";
    position: absolute;
    width: 85%;
    background: #efefef;
    height: 5px;
    top: 44%;
    right: -150px;
  }
`;

export const ActiveItem = css`
  color: ${colors.primary[500]};
  ${StepDot} {
    visibility: hidden;
  }
`;

export const DoneItem = css`
  color: #00c92f;
  font-weight: 500;
  ${StepDot} {
    visibility: visible;
  }
`;

export const StepsItem = styled.div<{ active?: boolean; done?: boolean }>`
  display: flex;
  align-items: center;
  color: #000;
  ${({ active, done }) => (active ? ActiveItem : done ? DoneItem : null)}
  width: 100%;
  position: relative;

  &:last-child {
    justify-content: flex-end;
    ${StepDot} {
      &::after {
        left: -35%;
      }
    }
  }
`;

export const StepText = styled.span`
  font-size: 1.4em;
`;

export const StepTail = styled.div<{ left?: boolean }>`
  position: absolute;
  height: 5px;
  background: #efefef;
  margin: 0 0 0 7px;
  padding: 0;
  width: 35%;
  ${({ left }) => (!left ? "right" : "left")}: 5px;
`;

export const Info = styled.div`
  margin: 2em 0;
  transition: all 0.2s ease-in-out;
`;

export const Form = styled.div`
  /* border: 1px solid black; */

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* margin: 3rem 0; */
`;

export const InputElement = styled.div`
  width: 100%;
`;

export const Select = styled(MSelect) <{ error?: string }>`
  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
  }
`;

export const FeedbackContainer = styled.div`
  position: absolute;
  top: -16px;
  right: 0;
`;

export const LabelContainer = styled.div`
  margin: 0.5em 0;
`;

export const Action = styled.div<{ position?: string }>`
  max-width: 80%;
  margin: 0em auto;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => position || "space-between"};
  gap: 1em;

  button {
    padding: 20px 0 !important;
    font-size: 1rem !important;
  }

  @media (max-width: ${DESKTOP}px) {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: ${IPAD}px) {
    flex-wrap: wrap;
    width: 100% !important;
    flex-direction: column-reverse;
  }
`;

const headerCollapsed = css`
  border: none;
  padding: 0;

  .anticon {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 5.0005px;
    transition: all 0.3s ease-in;
    transform: none;
  }
`;

export const Collapse = styled(AntCollapse) <{ isCollapsed: boolean }>`
  word-break: keep-all;
  &.ant-collapse {
    background-color: #f7f9fb;
    border-radius: 5px;
    border: 1px solid #e8f1ff;
    font-weight: 700;

    .ant-collapse-content-active {
      background-color: #f7f9fb;
    }

    > .ant-collapse-item {
      border-bottom: 0px;

      .ant-collapse-header {
        padding-top: 18px;
        padding-bottom: 18;
        transition: all 0.2s ease-in-out;

        .anticon {
          position: absolute;
          top: 5.0005px;
          border: 1px solid #e8f1ff;
          border-radius: 5px;
          padding: 14px;
          background: #e8f1ff;
          color: #0d4ba8;
          margin: 0;
          transform: none;
        }
        ${({ isCollapsed }) => !isCollapsed && headerCollapsed}
      }
    }
    .ant-collapse-content {
      border-top: 0px;

      .ant-collapse-content-box {
        font-weight: normal;
        display: flex;
        flex-direction: column;
        padding: 16px 16px 16px 30px;
        row-gap: 0.25rem;
        font-size: 14px;
        font-weight: 500;
        gap: 0.8em;
      }
    }
  }
`;

export const ItemPanel = styled.div`
  background-color: #f7f9fb;
  border-radius: 5px;
  border: 1px solid #e8f1ff;
  border-top: 0px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 30px;
  row-gap: 0.25rem;
  font-size: 14px;
  font-weight: 500;
  gap: 0.8em;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8em;
`;

export const CheckIcon = styled.img``;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Value = styled.div`
  color: #00c92f;
  font-weight: 600;
  text-align: right;
`;
export const Percentage = styled.div`
  font-weight: 600;
  text-align: right;
`;
export const Text = styled.div`
  width: 85%;
`;

export const Description = styled.div`
  font-size: 12px;
  color: ${(props) => props.color === "disabled" && "#00000054"};
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]} !important;

    .ant-input-number-input {
      padding: 8px 16px;
    }

    &.ant-input-number-disabled {
      background-color: ${colors.primary[25]};
      color: #000;
    }
  }
`;

export const Input = styled(AntInput)`
  &.ant-input {
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]} !important;

    &.ant-input-disabled {
      background-color: #f7f9fb;
      color: #000;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.color};
`;

export const Modal = styled(AntModal)`
  ${Action} {
    width: 50%;
  }
  &.ant-modal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0;
      padding-bottom: 3em;
      display: flex;
      justify-content: center;
      // flex-direction: column;
      @media (max-width: ${IPAD}px) {
        flex-direction: column;
      }
    }
    ${typography.CustomText("1em")}
    .ant-modal-body {
      padding-top: 0px;
    }
    .ant-modal-header {
      border-radius: 12px;
      border-bottom: 0px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-close-x {
      .anticon {
        background: #dc3545;
        border-radius: 50%;
        padding: 4px;
        color: white;
      }
    }
  }

  @media (max-width: ${LARGE_SCREEN}px) {
    &.ant-modal {
      width: 80% !important;
    }
  }

  @media (max-width: ${IPAD}px) {
    flex-wrap: wrap;
    width: 100% !important;

    &.ant-modal {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  margin-top: 1em;
  font-weight: 700;
  color: ${colors.primary["500"]}};
  ${typography.CustomMeriText("1.5em")}
`;

export const Small = styled.span`
  font-size: 1em;
  opacity: 0.5;
  text-align: center;
  // width: 40%;
  @media (max-width: ${IPAD}px) {
    width: 100%;
  }
`;

export const PmDescription = styled.div`
  opacity: 0.5;
  text-align: center;
  width: 60%;
  p {
    margin: 0;
    padding-left: 0.2rem;
    text-align: justify;
  }
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typography.CustomText("1em")}
  align-items: center;
`;

export const Wrapper = styled.div`
  // width: 60%;
  background-color: ${colors.primary[10]};
  border: 1px solid #e8f1ff;
  border-radius: 12px;
  margin-top: 2em;
  padding: 2.25em 5em;
  text-align: center;
  display: flex;
  flex-direction: column;

  #otpCode {
    border: solid 1px ${colors.primary[500]};
  }

  > h3.ant-typography {
    margin-bottom: 1rem;
  }

  @media (max-width: ${IPAD}px) {
    width: 100%;
    padding: 19px;
  }
`;

export const Title = styled.span`
  text-align: center;
`;

export const List = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-transform: capitalize; */

  @media (max-width: ${MOBILE}px) {
    flex-direction: column;
  }
`;

export const Name = styled.span<{ color?: string }>`
  text-align: left;
`;

export const RightValue = styled.span<{ color?: string }>`
  text-align: right;
  font-weight: 600;
  color: ${({ color }) => (color ? color : "#000")};

  @media (min-width: ${MOBILE}px) {
    width: 50%;
  }
`;

export const OTPContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typography.CustomText("1em")}
  align-items: center;
`;

export const OTPWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 10px auto;
  width: 100%;
  input {
    background-color: #ffffff;
    border-radius: 4px;
    text-align: center;
    border: solid 1px #e3e8f0;
    height: 40px;
    margin: 10px 0;
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const ErrorMessage = styled(AntAlret)`
  &.ant-alert {
    width: 60%;
    margin-top: 5px;
    border-radius: 12px;

    .ant-alert-content {
      .ant-alert-message {
        color: rgb(228 0 0 / 85%);
      }
    }
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 1.2rem;
`;
export const DoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${typography.CustomText("1em")}
  align-items: center;
  justify-content: center;

  ${Small} {
    width: 45%;
  }
`;

export const DoneText = styled.span`
  margin-right: 23px;
  font-weight: 700;
  font-size: 1.5em;
`;

export const DoneFrame = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  width: ${({ width }) => (width ? width : "auto")};
  justify-content: center;
  font-size: 1rem;

  .ant-typography {
    text-align: center;
  }

  ${Icon} {
    width: 34px;
    transform: translateX(-11px);
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  font-size: 12px;
`;

export const RadioElement = styled.div`
  background-color: ${(props) =>
    props.color === "disabled" ? colors.primary[50] : colors.primary[25]};
  border: 1px solid ${(props) => colors.primary[500]};
  border-color: ${(props) =>
    props.color === "disabled" ? "#F7F9FB" : colors.primary[500]};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 30px;
  row-gap: 0.25rem;
  font-size: 14px;
  font-weight: 500;
  align-content: stretch;
  width: 100%;
`;

export const RadioGroup = styled(ARadio.Group)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 1.2rem;
  margin-bottom: 3rem;
  .ant-radio-inner::after {
    background-color: ${colors.primary[500]}} !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.primary[500]}} !important;
  }
`;

export const RadioGroupLabel = styled.label`
  display: flex;
  margin-bottom: 2px;
  color: ${colors.primary[500]}};
  font-size: 12px;
`;

export const FooterParagraph = styled.p`
  color: ${colors.primary[500]}};
  margin-top: 1rem;
`;

export const LinkText = styled.div`
  text-decoration: underline;
  color: ${colors.primary[800]};
  cursor: pointer;
`;

export const CheckBokContainer = styled.div`
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding: 8px;

  & label {
  }

  & input {
    margin: 0 0.5rem;
    vertical-align: middle;
  }
`;
