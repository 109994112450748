// 7 days in milliseconds
const EXECUTION_DELAY_DAYS = 604800000;

const addLeadingZero = (dateValue: Number) =>
  ("0" + dateValue.toString()).slice(-2);

export const getExecutionTime = () => {
  const date = new Date(Date.now() + EXECUTION_DELAY_DAYS);
  return `${addLeadingZero(date.getDate())}-${addLeadingZero(
    date.getMonth() + 1,
  )}-${date.getFullYear()}`;
};
