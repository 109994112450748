import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InputNumber as AntInputNumber,
  Input as AntInput,
  Typography,
} from "antd";
import { IPAD } from "src/utils/constants/media";
import { colors } from "src/theme/colors";

export const IconContainer = styled.span`
  background-color: #e8f1ff;
  padding: 3px 5px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DollarSign = styled(FontAwesomeIcon)<{ color?: string }>`
  font-size: 14px;
  color: ${({ color }) => (color ? color : "#000")};
`;

export const FormContainer = styled.div`
  font-size: 0.75rem;
  width: 100%;

  @media (max-width: ${IPAD}px) {
    width: 100%;
  }
`;

export const FormElement = styled.div`
  margin: 2rem 0;
  position: relative;
`;

export const Amount = styled.span<{ color?: string }>`
  color: ${({ color }) => (color ? color : "#000")};
  font-weight: bold;
  font-size: 0.938rem;
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]};
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  // margin-bottom: 2px;
  color: ${colors.primary[500]};
`;

export const ColContainer = styled.div<{ justify?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  gap: 8px;
  align-items: center;
  white-space: nowrap;

  @media (max-width: ${IPAD}px) {
    flex-direction: column;
  }
`;

export const MoneyIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-38px);
  font-weight: bold;
  color: ${colors.primary[500]};
  span {
    font-weight: normal;
    margin-top: -5px;
  }

  @media (max-width: ${IPAD}px) {
    display: none;
  }
`;

export const MoneyIcon = styled.img`
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const Wrapper = styled.div`
  margin-top: 3rem;
`;

export const Text = styled(Typography.Text)`
  &.ant-typography {
    font-size: 12px;
    opacity: 0.8;
    .ant-typography-danger {
      color: red !important;
    }
    color: ${({ type }) => !type && colors.primary["600"]};
  }
  color: ${({ type }) => !type && colors.primary[500]};
}
  
`;

export const Input = styled(AntInput)`
  &.ant-input {
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]};

    // padding: 8px 16px;

    &.ant-input-disabled {
      background-color: ${colors.primary["25"]};
      color: #000;
      cursor: unset;

      &:hover {
        border: 1px solid ${colors.primary["700"]};
      }
    }
  }
  &.ant-input:focus {
    outline: none;
    border: none;
  }

  &.ant-input-affix-wrapper {
    border-radius: 5px;
  }
`;

export const Password = styled(AntInput.Password)`
  &.ant-input-affix-wrapper {
    border-radius: 5px;
    border-color: ${colors.primary["600"]};

    &.ant-input-disabled {
      background-color: ${colors.primary["100"]};
      color: #000;
    }
  }
`;

export const Action = styled.div<{ position?: string }>`
  margin: 2em 0;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => position || "space-between"};
  gap: 1em;

  button {
    padding: 20px 0 !important;
    font-size: 1rem !important;
  }

  @media (max-width: ${IPAD}px) {
    flex-direction: column-reverse;
    width: 100% !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextArea = styled(AntInput.TextArea)`
  &.ant-input {
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]} !important;
    &.ant-input-disabled {
      background-color: #f7f9fb;
      color: #000;
    }
  }

  &.ant-input-affix-wrapper {
    border-radius: 5px;

    &.ant-input-disabled {
      background-color: #f7f9fb;
      color: #000;
    }
  }
`;

export const PasswordRules = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 15px;
  margin-top: 5px;

  @media (max-width: ${IPAD}px) {
    flex-direction: column;
  }
`;

const pickRuleDotColor = ({
  startValidating,
  isValid,
}: {
  isValid?: boolean;
  startValidating?: boolean;
}) => (startValidating ? (isValid && "#00C92F") || "#DC3545" : "#92a3bb");

export const Rule = styled.span<{
  isValid?: boolean;
  startValidating?: boolean;
  isVisible?: boolean;
}>`
  flex-basis: 33%;
  font-size: 12px;
  font-weight: 300;
  color: ${({ isValid }) => (isValid ? "#00C92F" : "#92a3bb")};
  margin-bottom: 8px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: all 0.2s ease-in-out;

  &::before {
    content: "";
    margin-right: 8px;
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: ${(props) => pickRuleDotColor(props)};
  }
`;
