import { Alert } from "antd";
import React, { FC } from "react";
import * as Styled from "./LoadingContainer.css";
import { LoadingOutlined } from "@ant-design/icons";

export const BLUE_CRAYOLA = "#2178FF";

export interface ILoadingContainer extends Styled.IContainer {
  errorMessage?: React.ReactNode;
}

const Loading = ({ color }) => {
  return (
    <Styled.SpinnerWrapper>
      <LoadingOutlined
        style={{
          fontSize: 28,
          color: color ?? "",
        }}
      />
    </Styled.SpinnerWrapper>
  );
};

export const LoadingContainer: FC<ILoadingContainer> = ({
  isLoading,
  isError,
  errorMessage,
}) => {
  const message = errorMessage ?? "Oops, something went wrong.";

  return (
    <Styled.Container isLoading={isLoading} isError={isError}>
      {isError ? (
        <Alert type="error" message={message} banner />
      ) : (
        <Loading color={BLUE_CRAYOLA} />
      )}
    </Styled.Container>
  );
};
