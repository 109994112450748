import styled from "styled-components";
import { antd_breakingPts } from "src/utils/constants/media";

export const Container = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 5rem;
  z-index: 999;

  [data-display="show"] {
    div {
      display: block;
    }
  }

  @media (min-width: ${antd_breakingPts.lg}px) {
    border: 3px solid red;
    display: none;
  } ;
`;

export const DropDownBtn = styled.div`
  background-color: black;
  padding: 1.25rem;
  border-radius: 5px;
  cursor: pointer;

  & img {
    width: 1rem;
    aspect-ratio: 1/1;
  }
`;

export const DropDownContainer = styled.div`
  position: absolute;
  top: 0;
  right: 3.5rem;

  padding-right: 0.5rem;
`;

export const DropDownContent = styled.div`
  display: none;

  min-width: 10rem;
  padding: 1.5rem 1rem 0.5rem;

  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
`;

export const Content = styled.a`
  display: block;
  color: black;

  padding-bottom: 1rem;

  & img {
    width: 1rem;
    aspect-ratio: 1/1;
    margin-right: 0.5rem;
  }
`;
