import { FC } from "react";
import * as Styled from "./Toggle.css";
import {
  PieChartOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { theme } from "src/theme/theme";

export const Toggle: FC<Styled.IWrapper> = ({ side }) => {
  return (
    <Styled.Wrapper side={side}>
      <UnorderedListOutlined style={{ color: theme.colors.primary[500] }} />
      <PieChartOutlined style={{ color: theme.colors.primary[500] }} />
    </Styled.Wrapper>
  );
};
