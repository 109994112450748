import { Flex } from "src/components/Flex";
import styled from "styled-components";
import { css } from "styled-components";

export interface IWrapper {
  side: "left" | "right";
}

export const Wrapper = styled(Flex)<IWrapper>`
  --background: #f7f9fb;

  position: relative;
  background-color: var(--background);
  border-radius: 30px;
  color: #2478f6;
  cursor: pointer;
  padding: 10px 15px;
  gap: 30px;

  & > * {
    z-index: 1;
  }

  ${({ side }) => {
    const selector = side == "right" ? "first-child" : "last-child";

    return css`
      &>*: ${selector} {
        opacity: 0.5;
      }
    `;
  }}

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: calc(50%);
    background-color: white;
    top: 0;
    left: ${({ side }) => (side == "left" ? "0" : "initial")};
    right: ${({ side }) => (side == "right" ? "0" : "initial")};
    border-radius: 30px;
    border: 2px solid var(--background);
  }
`;
