import { useQuery } from "react-query";
import axiosInstance from "src/utils/axiosInstance";

const GET_BROKERAGE_ORDERS_ENDPOINT = "dashboard/direct-orders/orders";
export const ORDERS_PAGE_SIZE = 5;

interface IOrderByTickerID {
  currentPage: number;
  ticker_id: number;
}
interface IOrder {
  currentPage: number;
}

const fetchBrokerageOrdersByTickerID = async (payload: IOrderByTickerID) => {
  const { data } = await axiosInstance.get(GET_BROKERAGE_ORDERS_ENDPOINT, {
    baseURL: "v2",
    params: {
      ticker_id: payload.ticker_id,
      page_size: ORDERS_PAGE_SIZE,
      page_number: payload.currentPage,
      flag: "OPEN",
    },
  });
  return data;
};

export const useBrokerageOrderByTickerID = (payload: IOrderByTickerID) => {
  return useQuery(["brokerageOrders", payload], () =>
    fetchBrokerageOrdersByTickerID(payload),
  );
};

const fetchBrokerageOrders = async (payload: IOrder) => {
  const { data } = await axiosInstance.get(GET_BROKERAGE_ORDERS_ENDPOINT, {
    baseURL: "v2",
    params: {
      page_size: ORDERS_PAGE_SIZE,
      page_number: payload.currentPage,
      flag: "OPEN",
    },
  });
  return data;
};

export const useBrokerageOrder = (payload: IOrder) => {
  return useQuery(["brokerageOrders", payload], () =>
    fetchBrokerageOrders(payload),
  );
};
