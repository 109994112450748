import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTheme } from "styled-components";
import { Itheme } from "src/theme/types/theme";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading: React.FC = () => {
  const theme: Itheme = useTheme()
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
  >
    <Spin indicator={antIcon} size="large" style={{color: theme.colors.primary[800]}}/>
  </div>
  )
};

export default Loading;
