import { FC, useEffect, useRef } from "react"

interface SymbolInfoWidgetProps {
    symbol: string
}

export const SymbolInfoWidget: FC<SymbolInfoWidgetProps> = ({ symbol }) => {

    const widgetRef = useRef(null);

    useEffect(() => {
        if (widgetRef.current && symbol) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
            script.async = true;
            script.innerHTML = JSON.stringify({
                "symbol": symbol,
                "width": "100%",
                "locale": "en",
                "colorTheme": "light",
                "isTransparent": false
            })
            widgetRef.current.appendChild(script);
        }
    }, [symbol])
    return (<div ref={widgetRef} />)
}