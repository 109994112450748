import styled from "styled-components/macro";
import { EXTRA_LARGE_SCREEN } from "src/utils/constants/media";
import { typography } from "src/utils/styledComponents/typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 57%;
  ${typography.CustomText("1em")}
  align-items: center;

  @media (max-width: ${EXTRA_LARGE_SCREEN - 1}px) {
    width: 90%;
  }
`;
