import styled from "styled-components/macro";
import { Radio as AntRadio } from "antd";
import { colors } from "src/theme/colors";

export const Radio = styled(AntRadio)`
  &.ant-radio-wrapper {
    font-family: inherit;
    font-size: 1rem;
    margin-right: 36px;
    .ant-radio-inner {
      width: 18px;
      height: 18px;
      border-color: ${colors.primary[500]};
    }

    .ant-radio-inner::after {
      background-color: ${colors.primary[500]}};
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: ${colors.primary[500]}};
    }
    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        border-color: ${colors.primary[500]};
        border-width: 2px;

        &::after {
          width: 6px;
          height: 6px;
          border-color: ${colors.primary[500]};
          top: 4px;
          left: 4px;
        }
      }
    }
  }
`;
