import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl,baseUrl_V2 } from "src/utils/config";

const useInvestments = () => {
  const res = useQuery(
    "/Investments/count",
    async () =>
      (
        await axios.request({
          url: `${baseUrl}/investments/count`,
          method: "get",
          params: {
            status: "ACTIVE,PENDING_EXIT,ACTIVE_EXIT",
          },
        })
      ).data,
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
    },
  );
  const { data, ...rest } = res;
  return {
    countData: data,
    ...rest,
  };
};

const useCustomInvestments = () => {
  const res = useQuery(
    "/Investments/count/v2",
    async () =>
      (
        await axios.request({
          url: `${baseUrl_V2}/dashboard/investments/status`,
          method: "get"
        })
      ).data,
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
    },
  );
  const { data, ...rest } = res;
  if(!data){
    return  {
      data: {},
      ...rest,
    };
  }
  else if(data.success){
    return {
      data: data.status,
      ...rest,
    };
  }else{
    return  {
      data: {},
      ...rest,
    };
  }

};




export  {useInvestments, useCustomInvestments};
