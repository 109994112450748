import { colors } from 'src/theme/colors';
import { theme } from 'src/theme/theme';
import styled, { css } from 'styled-components/macro';

export const Alert = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.primary[30]};
  padding: 1em 2em;
  border-radius: 5px;

  @media only screen and (max-width: 767px) {
    padding: 0;
  }
`;

const WhiteIcon = css`
  filter: invert(100%);
`;

const IconSize = css<{ size?: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

export const Icon = styled.img<{
  color?: 'blue' | 'white' | 'green';
  size?: string;
}>`
  filter: invert(64%) sepia(48%) saturate(6743%) hue-rotate(101deg)
    brightness(104%) contrast(101%);
  ${({ color }) => color === 'white' && WhiteIcon}
  ${({ size }) => size && IconSize}
`;

export const Message = styled.div`
  margin-left: 1em;
  font-size: 0.875em;
  font-weight: 700;
`;
