import styled from "styled-components/macro";
import { typography } from "src/utils/styledComponents/typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${typography.CustomText("1em")}
`;

export const Wrapper = styled.div`
  width: 40%;
  margin-top: 3em;
`;

export const Small = styled.span`
  color: #000;
  display: inline-block;
  text-align: center;
  opacity: 0.5;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
`;

export const Amount = styled.span`
  color: #2478f6;
`;

export const AmountContainer = styled.div`
  margin-top: 0.8em;
  text-align: center;
`;
