import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl_V2 } from "src/utils/config";

// due FinaTable pagination issue, page and size are hardcoded for later to fix
const fetchTransactions = (pageNumber, pageSize) =>
  axios.get(`${baseUrl_V2}/dashboard/transactions`, {
    params: {
      page_number: 1,
      page_size: 1000,
    },
  });

const useTransactions = (pageNumber, pageSize) =>
  useQuery({
    queryKey: ["/transactions", pageNumber, pageSize],
    queryFn: () => fetchTransactions(pageNumber, pageSize),
    refetchOnWindowFocus: false,
  });

export default useTransactions;
