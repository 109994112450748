import { FC } from "react"
import { Typography, Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import { theme } from "src/theme/theme";

const { Text } = Typography

interface InputLabelDescriptionProps {
    label: string;
    description: string;
}

export const InputLabelDescription: FC<InputLabelDescriptionProps> = ({ label, description }) => {

    return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
        <Text style={{ color: theme.colors.primary[500], paddingLeft: 10 }}>
            {label}
        </Text>
        <Tooltip title={description}>
            <InfoCircleOutlined style={{ fontSize: "12px", paddingRight: 10 }} />
        </Tooltip>
    </div>
}