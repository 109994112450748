import { darken } from "polished";
import colors from "src/utils/styledComponents/colors";

const defaultBg = colors.primary;
const shadow = "0px 12px 24px 0px rgba(25, 85, 176, 0.2)";
const primaryBg = colors.primary;
const secondaryBg = "#f7f9fb";

const btnVariables = {
  shadow,
  fontFamily: "proxima-nova",
  fontWeight: "600",
  paddingBaseVertical: "21px",
  paddingBaseHorizontal: "0px",
  fontSize: "20px",
  borderRadius: "5px",
  defaultColor: colors.primary,
  defaultBg,
  defaultBorder: `${darken(0.05, defaultBg)}`,
  defaultDisabledBg: "#cccccc",
  secondaryColor: colors.gray,
  defaultDisabledColor: "#444655",
  primaryColor: "#fff",
  primaryBg,
  primaryBorder: `${darken(0.05, primaryBg)}`,
  primaryDisabledBg: "#f0f3f7",
  primaryDisabledColor: "#92a3bb",
  secondaryBg,
  secondaryBorder: `${darken(0.05, secondaryBg)}`,
  secondaryActiveBorder: colors.primary,
  secondaryActiveColor: colors.primary,
  secondaryActiveBg: colors.grayLighter,
  secondaryDisabledBg: `${darken(0.1, secondaryBg)}`,
  secondaryDisabledBorder: `${darken(0.12, secondaryBg)}`,
};

export default btnVariables;
