import React from "react";
import { Radio } from "antd";
import styled from "styled-components";
import { Field, FieldProps } from "formik";
import { Fields } from "./GoalAmountFormInputs";
import { StyledSubtitle } from "./SliderTitle";
import { theme } from "src/theme/theme";

const { Button } = Radio;

export enum FrequencyInMonths {
  Monthly = 1,
  Quarterly = 3,
  SemiAnnually = 6,
  Annually = 12,
}

type FrequencyValues = 1 | 3 | 6 | 12;

export const StyledRadioGroup = styled(Radio.Group)`
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 6px;
  .ant-radio-button-wrapper {
    height: fit-content;
    border: 1px solid ${theme.colors.primary[500]};
    background: ${theme.colors.primary[25]};
    color: ${theme.colors.primary[500]};
    border-color:${theme.colors.primary[500]};
    padding: 0.3em 2.6em;

    &:hover {
      color: ${theme.colors.primary[700]};
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${theme.colors.primary[500]};
      color: #fff;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
      background-color: ${theme.colors.primary[500]};
    }

    &.ant-radio-button-wrapper:first-child {
      border-radius: 5px 0 0 5px;
    }

    &.ant-radio-button-wrapper:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
`;

const FrequencyRadio: React.FC = () => {
  return (
    <>
      <StyledSubtitle>With A Regular Frequency</StyledSubtitle>
      <Field name={Fields.contributionType} id={Fields.contributionType}>
        {({ field: { value }, form: { setFieldValue } }: FieldProps<number>) => (
          <StyledRadioGroup value={value} onChange={(e: any) => setFieldValue(Fields.contributionType, e.target.value)}>
            <Button value={FrequencyInMonths.Monthly}>Monthly</Button>
            <Button value={FrequencyInMonths.Quarterly}>Quarterly</Button>
            <Button value={FrequencyInMonths.SemiAnnually}>Semi-Annually</Button>
            <Button value={FrequencyInMonths.Annually}>Annually</Button>
          </StyledRadioGroup>
        )}
      </Field>
    </>
  );
};

export default FrequencyRadio;
