import styled from "styled-components/macro";
import { Modal as AntModal } from "antd";
import { typography } from "src/utils/styledComponents/typography";
import { colors } from "src/theme/colors";

export const Modal = styled(AntModal)<{
  visibleHeader?: boolean;
  disableCancel?: boolean;
  contentPadding?: string;
}>`
  &.ant-modal {
    .ant-modal-content {
      padding: 0.5rem 1rem;
      border-radius: 12px;
      border: 1px solid ${colors.primary[500]};
      ${(props) =>
        props.contentPadding ? `padding: ${props.contentPadding};` : ""}
      /* @media (min-width: 375px) {
        padding: 1rem 2rem;
      }
      @media (min-width: 768px) {
        padding: 2rem 4rem;
      } */
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0;
      padding-bottom: 3em;
      display: flex;
      justify-content: center;
    }
    ${typography.CustomText("1em")}
    .ant-modal-body {
      padding: 0px;
    }
    .ant-modal-header {
      display: ${(props) => (!props.visibleHeader ? "none" : "block")};
      border-radius: 12px;
      border-bottom: 0px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-close-x {
      display: ${(props) => props.disableCancel && "none"};
      .anticon {
        background: #dc3545;
        border-radius: 50%;
        padding: 4px;
        color: white;
      }
    }
  }
`;

export const Header = styled.div`
  margin-top: 1em;
  font-weight: 700;
  line-height: 1.9rem;
  color: ${colors.primary["500"]};
  ${typography.CustomMeriText("1.5em")}
`;
