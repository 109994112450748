import { useState } from "react";
import Invest from "src/components/Invest";
import Button from "src/components/Button";

import checkIcon from "src/assets/check.svg";

type Props = {
  isModalVisible: Boolean;
  handleCancel: Function;
  handleSubmit: Function;
  handleOtpResend: Function;
  isLoading?: Boolean;
  formik: any;
};

// TODO : resend cold off timer

const OtpModal = ({
  isModalVisible,
  handleCancel,
  handleSubmit,
  handleOtpResend,
  isLoading,
  formik,
}: Props) => {
  const [otpValue, setOtpValue] = useState("");

  return (
    <Invest.Modal
      title="OTP Verification"
      isModalVisible={isModalVisible}
      handleOk={() => console.log("OK")}
      handleCancel={handleCancel}
      footer={[
        <Invest.Action key="customFooter">
          <Button
            key="back"
            outline={true}
            width="100%"
            onClick={() => handleOtpResend()}
            disabled={formik.isSubmitting || isLoading}
          >
            Resend
          </Button>
          <Button
            key="submit"
            onClick={() => handleSubmit(otpValue)}
            width="100%"
            type="submit"
            disabled={formik.isSubmitting || otpValue === "" || otpValue.includes("_") || isLoading}
          >
            <Invest.ButtonContainer>
              Verify
              <Invest.Icon size="20px" color="white" src={checkIcon} />
            </Invest.ButtonContainer>
          </Button>
        </Invest.Action>,
      ]}
    >
      <Invest.OTP
        error={null}
        onChange={({ target }) => {
          setOtpValue(target.value);
        }}
        onBlur={formik.handleBlur}
        value={otpValue}
      ></Invest.OTP>
    </Invest.Modal>
  );
};

export default OtpModal;
