import axios from "src/utils/axiosInstance";
import { errorWrapper } from "src/action";
import { IReturn } from "src/action/result";

export const updateSettings = async (values) => {
  try {
    const res = await axios.put("/settings", {
      gender: values.gender,
      numOfDependents: values.children,
      homeAddress: values.address,
      city: values.city,
      country: values.country,
      pobox: values.poBox,
      middleName: values.middleName,
      secondaryNumber: values.secondaryNumber
    });
    return { data: res.data, status: res.status, success: true };
  } catch ({ response }) {
    const data = response?.data;
    let message = data?.message;
    message = message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};

/** By Mouaad Labhairi
 * Adding verify secret
 * 
 * */
 export const verifyOTPSecret = async (data: any) : Promise<IReturn> => {
  try {
    const res = await axios.put("/enable/authenticator", data);
    return { data: res?.data, status: res.status, success: res?.data?.status };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const updateAbaBankAccount = async (id, values) => {
  try {
    const res = await axios.put(`/bankAccount/${id}/aba`, {
      aba: values.aba,
    });
    return { data: res.data, status: res.status, success: true };
  } catch ({ response }) {
    const data = response?.data;
    let message = data?.message;
    message = message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};

export const deleteBankAccount = async (
  id: string,
  providerCustomerId?: string,
) => {
  try {
    let res = {} as IReturn;
    if (providerCustomerId) {
      const { data } = await axios.delete(
        `/payment/source/${id}?provider=leantech`,
      );
      res = data;
    } else {
      res = await axios.delete(`/bankAccount/${id}`);
    }
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const uploadPicture = (file) => {
  const formData = new FormData();
  formData.append("picture", file);
  return axios
    .post(`/picture/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch(() => "Failed");
};

export const deletePicture = () => {
  return axios.delete('/picture');
}

export const updateProfession = async (value) => {
  try {
    const res = await axios.put(`/updateProfession`, value);
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const fetchSecurityQuestion = async () => {
  try {
    const res = await axios.get("/security-questions/get-all");
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const updatePassword = async (values): Promise<IReturn> => {
  try {
    const res = await axios.put("/updatePassword", values);
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const updatePasswordWithOTP = async (values): Promise<IReturn> => {
  try {
    const res = await axios.put("/updatePassword/otp", values);
    return { data: res.data, status: res.status, success: true, ...res };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const updateSawDashboard = async (values): Promise<IReturn> => {
  try {
    const res = await axios.put("/meta/dashboard", values);
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const agreeOnDisclaimer = async (values = null): Promise<IReturn> => {
  try {
    const res = await axios.put("/meta/cryptoDisclaimer", values);
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const agreeOnPublicMarketDisclaimer = async (values = null): Promise<IReturn> => {
  try {
    const res = await axios.put("/meta/publicMarketDisclaimer", values);
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const getTwoFactorSecret = async (values = null): Promise<IReturn> => {
  try {
    const res = await axios.post("/generate/secret", values);
    return {
      data: res?.data?.data,
      status: res.status,
      success: res?.data?.status,
    };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const enableTwoFactor = async (values = null): Promise<IReturn> => {
  try {
    const res = await axios.post("/enable/twoFactor", values);
    return { data: res?.data, status: res.status, success: res?.data?.status };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const disableTwoFactor = async (data: any): Promise<IReturn> => {
  try {
    const res = await axios.put("/disable/authenticator", data);
    return {
      data: res?.data?.data,
      status: res.status,
      success: res?.data?.status,
    };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const generateOTP = async (type: any): Promise<IReturn> => {
  try {
    const res = await axios.put("/generate/otp", { type: type });
    return {
      data: res?.data?.data,
      status: res.status,
      success: res?.data?.status,
    };
  } catch (err) {
    return errorWrapper(err);
  }
};

export const setDefaultTwoFactorAuthenticationMethod = async (data: any): Promise<IReturn> => {
  try {
    const res = await axios.put("/updateDefault2fa", {type: data});
    return {
      data: res?.data?.data,
      status: res.status,
      success: res?.data?.status,
    };
  } catch (err) {
    return errorWrapper(err);
  }
};
