import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./ApiActions";

const initialState = {
  loading: false,
  data: [],
};

const slice = createSlice({
  name: "tickers",
  initialState,
  reducers: {
    tickersRequest: (state) => {
      state.loading = true;
    },
    tickersResponse: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    tickersRemove: (state) => {
      state.loading = true;
      state.data = null;
    },
  },
});

export const { tickersRequest, tickersResponse, tickersRemove } = slice.actions;

const loadTickers = () =>
  apiCallBegan({
    url: "roboAdvisor/tickers",
    onStart: tickersRequest.type,
    onSuccess: tickersResponse.type,
  });

export { loadTickers };
export default slice.reducer;
