const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
    >
      <path
        fillRule="evenodd"
        d="M5.215.943a.757.757 0 00-.7.512l-.5 1.878-1.9-.266a.637.637 0 00-.7.421L.317 5.958a.757.757 0 00.2.777L1.714 8l-1.2 1.265a.68.68 0 00-.1.849l1.401 2.471a.625.625 0 00.7.345l1.5-.249.5 1.864a.759.759 0 00.699.512h3.5a.673.673 0 00.7-.512l.6-1.864 1.5.249a.783.783 0 00.7-.345l1.4-2.471a.68.68 0 00-.1-.849L12.215 8l1.3-1.265a.73.73 0 00.1-.777l-1-2.47a.845.845 0 00-.799-.421l-1.8.266-.6-1.878a.675.675 0 00-.701-.512h-3.5zm0 3.369l.5-1.958h2.5l.601 1.958a.698.698 0 00.799.505l1.9-.276.6 1.534-1.4 1.426a.667.667 0 000 1l1.4 1.383-.9 1.575-1.6-.272a.685.685 0 00-.799.5l-.601 1.958h-2.5l-.5-1.958a.745.745 0 00-.8-.5l-1.599.272-.9-1.575 1.299-1.383a.667.667 0 00.168-.774.667.667 0 00-.168-.226L1.816 6.075l.7-1.534 1.9.276a.7.7 0 00.799-.505zm1.8 5.1a1.412 1.412 0 111.4-1.411 1.39 1.39 0 01-1.4 1.41v.001zm-2.8-1.411a2.78 2.78 0 012.8-2.823 2.823 2.823 0 010 5.646 2.775 2.775 0 01-2.6-1.742A2.78 2.78 0 014.216 8v.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
export { SettingsIcon as default };
