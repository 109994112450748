import styled from 'styled-components/macro';
import { Modal, Button } from 'antd';

import { typography } from '../../../utils/styledComponents/typography';

export const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0;
      padding-bottom: 3em;
      display: flex;
      justify-content: center;

      .ant-btn + .ant-btn {
        margin-left: 0;
      }
    }
    ${typography.CustomText('1em')}
    .ant-modal-body {
      padding: 10px;
      padding-top: 0px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-close-x {
      .anticon {
        background: #dc3545;
        border-radius: 50%;
        padding: 4px;
        color: white;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    border-radius: 5px;
    height: 48px;

    &.ant-btn-dangerous {
      color: #dc3545;
      background: #fff;
      border-color: #dc3545;

      &:hover,
      &:focus {
        color: white;
        background: #dc3545;
        border-color: #ff7875;
      }
    }

    &.ant-btn-primary {
      background-color: #2478f6;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  width: 50%;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: rgba(220, 53, 69, 0.2);
  margin-top: 32px;
  margin-bottom: 22px;
  padding: 5px;
`;

export const Title = styled.span`
  ${typography.CustomText('20px')}
  font-weight: bold;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  opacity: 0.5;
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
