import { ReactComponent as Home } from "src/assets/layoutIcons/home.svg";
import { ReactComponent as Invest } from "src/assets/layoutIcons/invest.svg";
import { ReactComponent as Deposit } from "src/assets/layoutIcons/deposit.svg";
import { ReactComponent as Withdraw } from "src/assets/layoutIcons/withdraw.svg";
import { ReactComponent as Markets } from "src/assets/layoutIcons/Markets.svg";
import { ReactComponent as GoalBased } from "src/assets/layoutIcons/goalBased.svg";
import { ReactComponent as Brokerage } from "src/assets/layoutIcons/brokerage.svg";


import { TRouteItem } from "./types";
import NotificationIcon from "./components/FinaSidebar/components/NotificationIcon";
import SettingsIcon from "./components/FinaSidebar/components/SettingsIcon";
import LogOutIcon from "./components/FinaSidebar/components/LogOutIcon";

export type MenuTypes = "analytics" | "invest" | "cash";
export type IRouteItem = Record<MenuTypes, TRouteItem[]>;

export const routes: IRouteItem = {
  analytics: [
    {
      key: "dashboard",
      path: "/dashboard",
      name: "Dashboard",
      icon: <Home />,
      type: "analytics",
    },
  ],
  invest: [
    {
      key: "Markets",
      path: "/markets",
      name: "Markets",
      icon: <Invest />,
      type: "invest",
    },
    {
      key: "GoalBased",
      path: "/goal-based-investment",
      name: "Goal-Based",
      icon: <GoalBased />,
      type: "invest",
    },
    {
      key: "Brokerage",
      path: "/brokerage",
      name: "Trading",
      icon: <Brokerage />,
      type: "invest",
    },
  ],
  cash: [
    {
      key: "deposit",
      path: "/deposit",
      name: "Deposit Cash",
      icon: <Deposit />,
      type: "cash",
    },
    {
      key: "withdraw",
      path: "/withdraw",
      name: "Withdraw Cash",
      icon: <Withdraw />,
      type: "cash",
    },
  ],

};
export const settings: TRouteItem[] = [
  {
    key: "settings",
    path: "/settings",
    name: "Settings",
    icon: <SettingsIcon />,
    type: "settings",
  },
  {
    key: "LogOut",
    path: "/logout",
    name: "Log out",
    icon: <LogOutIcon />,
    type: "settings",
  },
];
