import React from "react";
import { Modal as IModal, Header } from "./styles/modal";
import { ModalProps } from "antd";

interface IModalProps extends ModalProps {
  children?: React.ReactNode;
  visibleHeader?: boolean;
  contentPadding?: string;
  Imodal?: any;
}

const Modal = ({
  visibleHeader = true,
  footer,
  title,
  width = "70%",
  children,
  onCancel,
  Imodal = IModal,
  ...rest
}: IModalProps) => {
  return (
    <Imodal
      maskStyle={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255,255,255,0)",
        backgroundOrigin: "padding-box",
      }}
      title={<Header>{title}</Header>}
      width={width}
      footer={footer}
      centered
      visibleHeader={visibleHeader}
      disableCancel={onCancel ? false : true}
      onCancel={onCancel}
      {...rest}
    >
      {children}
    </Imodal>
  );
};

export default Modal;
