// import { ReactComponent as FirmLogo } from "src/assets/banklogo.svg";
// import { ReactComponent as CollapsedLogo } from "src/assets/collapsedLogo.svg";

// export const LogoComponents = {
//   firmLogo: FirmLogo,
//   collapsedLogo: CollapsedLogo,
// };
const SNB_DEFAULT_COLORS = {
  10: "#fbfbfa",
  15: "#F2F5F4",
  25: "#F9FBF2",
  30: "#edf4e2",
  50: "#E4F3C2",
  100: "#CFEE92",
  200: "#BBE762",
  300: "#A5D741",
  400: "#8EC721",
  500: "#78B703",
  600: "#5C9909",
  700: "#3F7A18",
  800: "#245B23",
  900: "#093C2F",
};
export const BANK_NAME = "BANK";

const colors = process.env.REACT_APP_BANK_COLORS || "[]";

const parsedColors = JSON.parse(colors);

function createColorObject(colors: Array<string>) {
  if (colors.length === 0) {
    return SNB_DEFAULT_COLORS;
  }
  const keys = [
    10, 15, 25, 30, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
  ];

  let colorObject = {};

  keys.forEach((key, index) => {
    colorObject[key] = colors[index % colors.length];
  });

  return colorObject;
}

export const BANK_COLORS = createColorObject(parsedColors);
