import { Badge, Col, Row } from "antd";
import { FC } from "react";
import { Flex } from "src/components/Flex";
import * as Styled from "./CustomizeSmartfolio.css";
import Text from "antd/lib/typography/Text";
import { subCardStyle } from "src/pages/SmartfolioAnalytics/components";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {
  PieChart,
  PieColors,
} from "src/pages/SmartfolioAnalytics/components/PieChart";
import { useDispatch, useSelector } from "react-redux";
import {
  addBasketItem,
  removeBasketItem,
  selectBasket,
  selectCashWeight,
  selectDescription,
  selectName,
  setDescription,
  setName,
  updateBasketInfo,
  updateBasketItem,
} from "src/store/Goal-Based/SelfMadeSmartfolioSlice";
import { useGetSecuritiesQuery } from "src/store/Goal-Based/goalBasedApi";
import { theme } from "src/theme/theme";

export interface ICustomizeSmartfolio {
  setSecurities: React.Dispatch<React.SetStateAction<any[]>>;
  securities: any[];
}

export const CustomizeSmartfolio: FC = () => {
  const dispatch = useDispatch<any>();
  const { data: tickers } = useGetSecuritiesQuery();
  const basket = useSelector(selectBasket);
  const cashWeight = useSelector(selectCashWeight);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription)

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} lg={8}>
        <Flex
          height="100%"
          flexDirection="column"
          width="100%"
          gap="16px"
          style={{ ...subCardStyle, padding: "16px" }}
        >
          <Flex flexDirection="column" width="100%" height="200px">
            <PieChart
              legend={false}
              data={basket.map((item) => {
                return {
                  key: item.symbol,
                  value: Number((item.weight * 100).toFixed(0)),
                };
              })}
            />
          </Flex>
          <Flex flexDirection="column" width="100%" gap="8px">
            <Text style={{ color: "rgba(0, 0, 0, .7)" }}>
              Name{" "}
              {name.length < 5 && <span
                style={{
                  color: "red",
                  marginLeft: "5px",
                  fontSize: "12px"
                }}
              >
                * (Min 5 chars.)
              </span>}
            </Text>
            <Styled.InputText
              maxLength={90}
              placeholder="Your Smartfolio Name"
              value={useSelector(selectName)}
              onChange={(e) => dispatch(setName(e.target.value))}
            />
          </Flex>
          <Flex flexDirection="column" width="100%" gap="8px">
            <Text style={{ color: "rgba(0, 0, 0, .7)" }}>
              Description{" "}
              {description.length < 5 && <span
                style={{
                  color: "red",
                  marginLeft: "5px",
                  fontSize: "12px"
                }}
              >
                * (Min 5 chars.)
              </span>}
            </Text>
            <Styled.InputTextArea
              rows={4}
              placeholder="Your Smartfolio Description"
              maxLength={225}
              value={useSelector(selectDescription)}
              onChange={(e) => dispatch(setDescription(e.target.value))}
            />
          </Flex>
        </Flex>
      </Col>
      <Col span={24} lg={16}>
        <Flex
          flexDirection="column"
          width="100%"
          style={{ ...subCardStyle, padding: "20px", overflowX: "scroll" }}
        >
          <Title level={5}>Securities</Title>
          <Styled.Row>
            <Text style={{ marginLeft: "14px", color: "rgba(0, 0, 0, .7)" }}>
              Symbols
            </Text>
            <Text style={{ color: "rgba(0, 0, 0, .7)", whiteSpace: "nowrap" }}>
              Name
            </Text>
            <Text style={{ color: "rgba(0, 0, 0, .7)" }}>Weight</Text>
            <Text style={{ color: "rgba(0, 0, 0, .7)" }}>Min Weight</Text>
            <Text style={{ color: "rgba(0, 0, 0, .7)" }}>Max Weight</Text>
            <Text style={{ color: "rgba(0, 0, 0, .7)" }}>Action</Text>
            {basket.map(
              (
                {
                  symbol,
                  name,
                  weight,
                  minimum_bound_weight,
                  maximum_bound_weight,
                },
                index,
              ) => {
                return (
                  <>
                    <Flex alignItems="center">
                      <Badge color={PieColors[index]} />
                      <Styled.Select
                        disabled={symbol === "CASH"}
                        value={symbol}
                        style={{ width: 100 }}
                        onChange={(value) => {
                          const selectedTicker = tickers.find(
                            (item) => item.symbol === value,
                          );
                          dispatch(
                            updateBasketInfo({
                              index,
                              security: selectedTicker,
                            }),
                          );
                        }}
                        options={tickers
                          .map(({ symbol }) => ({
                            value: symbol,
                            label: symbol,
                          }))
                          .slice(1)
                          .filter((item) => {
                            const selectedSymbols = basket.map(
                              (basketItem) => basketItem.symbol,
                            );
                            return !selectedSymbols.includes(item.value);
                          })}
                      />
                    </Flex>
                    <Text style={{ color: theme.colors.primary[500], whiteSpace: "nowrap" }}>
                      <p
                        style={{
                          maxWidth: "10ch",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          margin: 0,
                        }}
                      >
                        {name}
                      </p>
                    </Text>
                    <Styled.InputNum
                      disabled={symbol === "CASH"}
                      id="Weight"
                      value={(weight * 100).toFixed(0)}
                      min={0}
                      max={(+cashWeight + weight) * 100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value!.replace("%", "")}
                      onChange={(e) =>
                        dispatch(
                          updateBasketItem({
                            index,
                            attribute: "weight",
                            value: e,
                          }),
                        )
                      }
                    />
                    <Styled.InputNum
                      id="Min Weights"
                      value={(minimum_bound_weight * 100).toFixed(0)}
                      min={0}
                      max={(weight * 1000) / 10}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value!.replace("%", "")}
                      onChange={(e) =>
                        dispatch(
                          updateBasketItem({
                            index,
                            attribute: "minimum_bound_weight",
                            value: e,
                          }),
                        )
                      }
                    />
                    <Styled.InputNum
                      id="Max Weights"
                      value={(maximum_bound_weight * 100).toFixed(0)}
                      min={(weight * 1000) / 10}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value!.replace("%", "")}
                      onChange={(e) =>
                        dispatch(
                          updateBasketItem({
                            index,
                            attribute: "maximum_bound_weight",
                            value: e,
                          }),
                        )
                      }
                    />
                    {symbol === "CASH" ? (
                      <CloseCircleOutlined
                        style={{ color: "#333333", opacity: 0.6 }}
                      />
                    ) : (
                      <CloseCircleOutlined
                        style={{ color: "#FF0000", opacity: 0.6 }}
                        onClick={() => {
                          dispatch(removeBasketItem(index));
                        }}
                      />
                    )}
                  </>
                );
              },
            )}
            <Flex
              onClick={() => dispatch(addBasketItem())}
              alignItems="center"
              gap="10px"
              style={{ cursor: "pointer" }}
            >
              <PlusCircleOutlined style={{ color: theme.colors.primary[500] }} />
              <Text
                style={{
                  color: theme.colors.primary[500],
                  whiteSpace: "nowrap",
                  fontWeight: "bolder",
                }}
              >
                Add a Security
              </Text>
            </Flex>
          </Styled.Row>
        </Flex>
      </Col>
    </Row>
  );
};
