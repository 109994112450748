import { Flex } from "src/components/Flex";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  background-color: ${theme.colors.primary[500]};
  padding: 1rem;
  border-radius: 4px;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Stat = styled(Flex)`
  padding: 0.6rem 1rem;
  background-color: ${theme.colors.primary[10]};
  flex-direction: column;
  border-radius: 4px;
  height: 100%;
`;
