import styled from "styled-components/macro";
import { Modal as AntModal } from "antd";
import { typography } from "src/utils/styledComponents/typography";
import { IPAD } from "src/utils/constants/media";
import { colors } from "src/theme/colors";

export const Container = styled.div``;

export const Modal = styled(AntModal)`
  &.ant-modal {
    .ant-modal-footer {
      margin-top: -50px;
      text-align: center;
      border-top: 0;
      padding-bottom: 3em;
      display: flex;
      justify-content: center;
    }
    ${typography.CustomText("1em")}
    .ant-modal-body {
      padding-top: 0px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-close-x {
      .anticon {
        background: #dc3545;
        border-radius: 50%;
        padding: 4px;
        color: white;
      }
    }
  }

  @media (max-width: ${IPAD}px) {
    flex-wrap: wrap;
    width: 100% !important;

    &.ant-modal {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  margin-top: 1em;
  font-weight: 700;
  color: ${colors.primary[500]};
  ${typography.CustomMeriText("1.5em")}
`;

export const Small = styled.span`
  font-size: 1em;
  opacity: 0.5;
  text-align: center;
  width: 38%;
`;

export const DoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${typography.CustomText("1em")}
  align-items: center;
  justify-content: center;

  ${Small} {
    width: 45%;
  }
`;

export const DoneText = styled.span`
  margin-right: 23px;
  ${typography.CustomMeriText("1.5em")}
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.primary[500])};
`;

export const DoneFrame = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  width: ${({ width }) => (width ? width : "auto")};
  justify-content: center;
  font-size: 1rem;

  .ant-typography {
    text-align: center;
  }
`;
