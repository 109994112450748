import Cookies from "universal-cookie";
import { getSessionId } from "src/utils/axiosInstance";

export const appendToken = (headers: Headers) => {
    const cookies = new Cookies();
    if (!headers.get("token")) {
      const token = cookies.get("JWToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
    if (headers.get("x-session-id"))
      headers.set("x-session-id", getSessionId());
    return headers;
  }