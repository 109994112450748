import { theme } from "src/theme/theme";

const ContributionAmountIcon = ({ width = "139", height = "104" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 139 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.5245 98.6114C105.691 98.6114 127.713 76.5365 127.713 49.3057C127.713 22.0749 105.691 0 78.5245 0C51.3584 0 29.3359 22.0749 29.3359 49.3057C29.3359 76.5365 51.3584 98.6114 78.5245 98.6114Z"
        fill="#EAEEF9"
      />
      <path
        d="M99.5052 98.8094C92.4782 99.0079 83.9667 98.8094 78.0285 98.7102C72.0902 98.8094 63.5787 99.0079 56.5518 98.8094C63.4797 94.8412 65.954 87.4007 66.3499 82.5396H89.707C90.2019 87.4007 92.5772 94.8412 99.5052 98.8094Z"
        fill="url(#paint0_linear_11_15575)"
      />
      <path
        d="M57.2449 99.9999H98.9117C99.5055 99.9999 100 99.5039 100 98.9087C100 98.3134 99.5055 97.8174 98.9117 97.8174H57.2449C56.6511 97.8174 56.1562 98.3134 56.1562 98.9087C56.1562 99.5039 56.6511 99.9999 57.2449 99.9999Z"
        fill="#EBF0F6"
      />
      <path
        d="M122.269 20.635V81.7462C122.269 83.2343 121.081 84.3256 119.695 84.3256H36.7578C35.2733 84.3256 34.1846 83.1351 34.1846 81.7462V20.635C34.1846 19.1469 35.3722 18.0556 36.7578 18.0556H119.695C121.081 17.9564 122.269 19.1469 122.269 20.635Z"
        fill="#F5F8FF"
      />
      <path
        d="M122.269 20.635V73.2145H34.1846V20.635C34.1846 19.1469 35.3722 18.0556 36.7578 18.0556H119.695C121.081 17.9564 122.269 19.1469 122.269 20.635Z"
        fill="#172D45"
      />
      <path
        d="M118.904 21.3301H37.1543V70.3382H118.904V21.3301Z"
        fill="white"
      />
      <path
        d="M42.0034 26.8841C42.714 26.8841 43.29 26.3066 43.29 25.5944C43.29 24.8821 42.714 24.3047 42.0034 24.3047C41.2928 24.3047 40.7168 24.8821 40.7168 25.5944C40.7168 26.3066 41.2928 26.8841 42.0034 26.8841Z"
        fill="#FD724F"
      />
      <path
        d="M46.1606 26.8841C46.8712 26.8841 47.4473 26.3066 47.4473 25.5944C47.4473 24.8821 46.8712 24.3047 46.1606 24.3047C45.4501 24.3047 44.874 24.8821 44.874 25.5944C44.874 26.3066 45.4501 26.8841 46.1606 26.8841Z"
        fill="#FFCB00"
      />
      <path
        d="M50.3169 26.8841C51.0275 26.8841 51.6035 26.3066 51.6035 25.5944C51.6035 24.8821 51.0275 24.3047 50.3169 24.3047C49.6063 24.3047 49.0303 24.8821 49.0303 25.5944C49.0303 26.3066 49.6063 26.8841 50.3169 26.8841Z"
        fill={theme.colors.primary["500"]}
      />
      <path
        d="M118.904 29.8623H54.7711H41.707H37.1543V70.3386H54.7711V30.4575H118.904V29.8623Z"
        fill="#E6EDF5"
      />
      <path
        d="M46.1608 38.5917C47.3633 38.5917 48.3381 37.6145 48.3381 36.4091C48.3381 35.2037 47.3633 34.2266 46.1608 34.2266C44.9582 34.2266 43.9834 35.2037 43.9834 36.4091C43.9834 37.6145 44.9582 38.5917 46.1608 38.5917Z"
        fill="white"
      />
      <path
        d="M52.0983 41.8646H40.1228C39.8259 41.8646 39.6279 41.6662 39.6279 41.3686C39.6279 41.071 39.8259 40.8726 40.1228 40.8726H52.0983C52.3952 40.8726 52.5931 41.071 52.5931 41.3686C52.6921 41.567 52.3952 41.8646 52.0983 41.8646Z"
        fill="white"
      />
      <path
        d="M52.0983 44.9408H40.1228C39.8259 44.9408 39.6279 44.7424 39.6279 44.4448C39.6279 44.1471 39.8259 43.9487 40.1228 43.9487H52.0983C52.3952 43.9487 52.5931 44.1471 52.5931 44.4448C52.6921 44.6432 52.3952 44.9408 52.0983 44.9408Z"
        fill="white"
      />
      <path
        d="M52.0983 48.0155H40.1228C39.8259 48.0155 39.6279 47.8171 39.6279 47.5195C39.6279 47.2219 39.8259 47.0234 40.1228 47.0234H52.0983C52.3952 47.0234 52.5931 47.2219 52.5931 47.5195C52.6921 47.7179 52.3952 48.0155 52.0983 48.0155Z"
        fill="white"
      />
      <path
        d="M52.0983 51.0917H40.1228C39.8259 51.0917 39.6279 50.8933 39.6279 50.5956C39.6279 50.298 39.8259 50.0996 40.1228 50.0996H52.0983C52.3952 50.0996 52.5931 50.298 52.5931 50.5956C52.6921 50.7941 52.3952 51.0917 52.0983 51.0917Z"
        fill="white"
      />
      <path
        d="M52.0983 54.1659H40.1228C39.8259 54.1659 39.6279 53.9675 39.6279 53.6699C39.6279 53.3722 39.8259 53.1738 40.1228 53.1738H52.0983C52.3952 53.1738 52.5931 53.3722 52.5931 53.6699C52.6921 53.8683 52.3952 54.1659 52.0983 54.1659Z"
        fill="white"
      />
      <path
        d="M46.358 57.2422H40.0238C39.8259 57.2422 39.6279 57.0437 39.6279 56.8453V56.5477C39.6279 56.3493 39.8259 56.1509 40.0238 56.1509H46.358C46.5559 56.1509 46.7538 56.3493 46.7538 56.5477V56.8453C46.6549 57.0437 46.5559 57.2422 46.358 57.2422Z"
        fill="white"
      />
      <path
        d="M78.0289 81.2499C79.2314 81.2499 80.2063 80.2727 80.2063 79.0673C80.2063 77.8619 79.2314 76.8848 78.0289 76.8848C76.8264 76.8848 75.8516 77.8619 75.8516 79.0673C75.8516 80.2727 76.8264 81.2499 78.0289 81.2499Z"
        fill="#CED7E2"
      />
      <path
        d="M138.5 17.2621C138.5 18.2542 138.401 19.147 138.203 20.1391C138.203 20.2383 138.203 20.2383 138.203 20.3375C137.214 24.5042 134.146 27.9764 130.286 29.4645C130.088 29.5637 129.989 29.5637 129.791 29.6629C128.504 30.159 127.119 30.3574 125.634 30.3574C118.508 30.3574 112.669 24.5042 112.669 17.3613C112.669 10.2184 118.508 4.36523 125.634 4.36523C132.76 4.36523 138.5 10.1192 138.5 17.2621Z"
        fill="url(#paint1_linear_11_15575)"
      />
      <path
        d="M138.203 20.1395C138.203 20.2387 138.203 20.2387 138.203 20.3379C137.214 24.5046 134.145 27.9769 130.286 29.465C130.088 29.5642 129.989 29.5642 129.791 29.6634L121.576 21.4292L122.764 17.2625L122.665 15.6752L124.842 12.203L126.921 11.2109L131.176 12.9967L138.203 20.1395Z"
        fill="url(#paint2_linear_11_15575)"
      />
      <path
        d="M64.7665 52.5806H60.2139V63.5925H64.7665V52.5806Z"
        fill="#E6EDF5"
      />
      <path
        d="M75.9501 47.5205H71.3975V63.592H75.9501V47.5205Z"
        fill="#E6EDF5"
      />
      <path
        d="M87.1337 52.5806H82.5811V63.5925H87.1337V52.5806Z"
        fill="#E6EDF5"
      />
      <path
        d="M98.3173 39.7827H93.7646V63.5923H98.3173V39.7827Z"
        fill="#E6EDF5"
      />
      <path
        d="M109.502 47.2241H104.949V63.5932H109.502V47.2241Z"
        fill="#E6EDF5"
      />
      <path
        d="M64.7665 61.1128H60.2139V63.593H64.7665V61.1128Z"
        fill="#CED7E2"
      />
      <path
        d="M75.9501 60.0205H71.3975V63.5919H75.9501V60.0205Z"
        fill="#CED7E2"
      />
      <path
        d="M87.1337 61.1128H82.5811V63.593H87.1337V61.1128Z"
        fill="#CED7E2"
      />
      <path
        d="M98.3173 58.2349H93.7646V63.592H98.3173V58.2349Z"
        fill="#CED7E2"
      />
      <path
        d="M109.502 59.9229H104.949V63.5935H109.502V59.9229Z"
        fill="#CED7E2"
      />
      <g filter="url(#filter0_d_11_15575)">
        <path
          d="M62.8861 61.9062C60.9067 61.9062 59.2242 61.3109 58.2345 61.0133C57.4427 60.7157 57.0468 59.922 57.2448 59.1284C57.5417 58.3347 58.3335 57.9379 59.1252 58.1363C61.7974 59.0292 64.9645 59.7236 67.7357 56.6482L68.5275 55.7554C72.0904 51.8863 74.1688 49.6046 80.7009 53.176C85.9464 56.1522 87.8268 55.0609 89.9052 51.6879C90.697 50.299 91.2908 48.8109 91.8846 47.422C92.9733 44.6442 94.062 41.7672 97.0311 42.1641H97.229C100.099 42.4617 103.563 42.8585 107.918 36.8069C108.512 35.914 109.106 35.3188 109.6 34.8228C110.194 34.2275 111.184 34.3267 111.679 34.922C112.273 35.5172 112.174 36.5093 111.58 37.0053C111.184 37.3029 110.788 37.8982 110.293 38.5926C105.048 45.9339 100 45.4379 96.9321 45.1403H96.7342C96.0414 45.041 95.4476 46.3307 94.6558 48.5133C94.062 50.0014 93.4682 51.6879 92.4784 53.2752C89.3114 58.5332 85.4515 59.2276 79.3153 55.8546C74.8616 53.3744 74.2678 54.0689 70.8038 57.8387L69.9131 58.8308C67.6367 61.3109 65.0635 61.9062 62.8861 61.9062Z"
          fill="url(#paint3_linear_11_15575)"
        />
      </g>
      <path
        d="M66.3505 37.601H60.3133C59.8184 37.601 59.5215 37.2042 59.5215 36.8073C59.5215 36.3113 59.9174 36.0137 60.3133 36.0137H66.3505C66.8453 36.0137 67.1422 36.4105 67.1422 36.8073C67.1422 37.3034 66.7464 37.601 66.3505 37.601Z"
        fill="#E6EDF5"
      />
      <path
        d="M71.7939 41.4691H60.3133C59.8184 41.4691 59.5215 41.0723 59.5215 40.6755C59.5215 40.1795 59.9174 39.8818 60.3133 39.8818H71.6949C72.1898 39.8818 72.4867 40.2787 72.4867 40.6755C72.5856 41.0723 72.1898 41.4691 71.7939 41.4691Z"
        fill="#E6EDF5"
      />
      <g filter="url(#filter1_d_11_15575)">
        <path
          d="M42.4985 67.2621C42.4985 68.7502 42.3005 70.1391 41.9047 71.4288C40.4201 76.4883 36.2633 80.3574 31.0179 81.5478C30.0282 81.7463 29.0384 81.8455 27.9498 81.8455C20.0321 81.8455 13.5 75.2978 13.5 67.2621C13.5 59.2264 20.0321 52.6787 28.0487 52.6787C36.0654 52.6787 42.4985 59.2264 42.4985 67.2621Z"
          fill={theme.colors.primary["500"]}
        />
      </g>
      <path
        d="M41.9049 71.4278C40.4203 76.4873 36.2635 80.3564 31.0181 81.5469L22.1107 72.6183L23.2983 71.527L22.0117 70.2373L23.9911 68.7492L31.7109 63.3921L32.6016 62.3008L41.9049 71.4278Z"
        fill={theme.colors.primary["500"]}
      />
      <path
        d="M30.9724 66.8618H32.358C32.6549 66.8618 32.8528 67.0602 32.8528 67.3578V72.3182C32.8528 72.6158 32.6549 72.8142 32.358 72.8142H30.9724C30.6755 72.8142 30.4775 72.6158 30.4775 72.3182V67.3578C30.4775 67.0602 30.6755 66.8618 30.9724 66.8618Z"
        fill="url(#paint6_linear_11_15575)"
      />
      <path
        d="M26.8152 69.3408H28.2008C28.4977 69.3408 28.6956 69.5392 28.6956 69.8369V72.317C28.6956 72.6146 28.4977 72.8131 28.2008 72.8131H26.8152C26.5183 72.8131 26.3203 72.6146 26.3203 72.317V69.8369C26.3203 69.5392 26.5183 69.3408 26.8152 69.3408Z"
        fill="url(#paint7_linear_11_15575)"
      />
      <path
        d="M22.6578 71.3247H24.0434C24.3403 71.3247 24.5383 71.5231 24.5383 71.8207V72.3168C24.5383 72.6144 24.3403 72.8128 24.0434 72.8128H22.6578C22.3609 72.8128 22.163 72.6144 22.163 72.3168V71.8207C22.064 71.5231 22.3609 71.3247 22.6578 71.3247Z"
        fill="url(#paint8_linear_11_15575)"
      />
      <path
        d="M32.4564 62.0001C32.6543 62.0001 32.8523 62.1985 32.8523 62.3969V64.9763C32.8523 65.0755 32.7533 65.1747 32.6544 65.1747H31.8626C31.7636 65.1747 31.6646 65.0755 31.6646 64.9763V63.9842C28.9924 66.5636 25.8254 68.6469 22.3614 70.135C22.2624 70.135 22.2624 70.135 22.1634 70.135L22.0645 70.0358V69.143C22.0645 69.0438 22.1634 68.9445 22.2624 68.9445C25.4295 67.5557 28.3986 65.5715 30.8729 63.0914H29.7842C29.6852 63.0914 29.5863 62.9922 29.5863 62.8929V62.0993C29.5863 62.0001 29.6852 61.9009 29.7842 61.9009L32.4564 62.0001Z"
        fill="url(#paint9_linear_11_15575)"
      />
      <path
        d="M130.536 17.7104H125.983C125.785 17.7104 125.587 17.512 125.587 17.3136V12.7501C125.587 12.3533 125.29 12.1549 124.994 12.0557H124.895C121.827 12.4525 119.748 15.3295 120.144 18.4049C120.54 21.4803 123.41 23.5636 126.478 23.1668C128.952 22.8692 130.932 20.8851 131.229 18.4049C131.229 18.1073 130.932 17.7104 130.536 17.7104C130.635 17.7104 130.536 17.7104 130.536 17.7104Z"
        fill="url(#paint10_linear_11_15575)"
      />
      <path
        d="M126.775 11.5594V16.0237C126.775 16.3213 126.973 16.5197 127.27 16.5197H131.724C132.021 16.5197 132.219 16.3213 132.219 16.0237C132.021 13.5435 129.843 11.1626 127.27 11.0634C126.973 10.9642 126.775 11.1626 126.775 11.5594C126.775 11.5594 126.775 11.4602 126.775 11.5594Z"
        fill="white"
      />
      <path
        d="M28.8408 98.7109H129.989"
        stroke="#EBF0F6"
        stroke-width="0.666667"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_11_15575"
          x="43.738"
          y="29.3767"
          width="81.7965"
          height="54.3961"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.41021" />
          <feGaussianBlur stdDeviation="6.72817" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11_15575"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11_15575"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_11_15575"
          x="0.043663"
          y="47.6326"
          width="55.9107"
          height="56.0797"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.41021" />
          <feGaussianBlur stdDeviation="6.72817" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11_15575"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11_15575"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11_15575"
          x1="78.026"
          y1="98.3686"
          x2="78.026"
          y2="83.7873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F8FF" />
          <stop offset="0.9913" stop-color="#D8E0EB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_15575"
          x1="125.585"
          y1="4.36523"
          x2="125.585"
          y2="30.3574"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0208333" stop-color="#FFC46B" />
          <stop offset="1" stop-color="#F79B11" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_15575"
          x1="124.084"
          y1="15.0343"
          x2="133.93"
          y2="24.8572"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDC36C" />
          <stop offset="1" stop-color="#FEBF61" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_15575"
          x1="69.8924"
          y1="36.9613"
          x2="79.6748"
          y2="66.7122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#78B700" />
          <stop offset="1" stop-color="#69A000" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11_15575"
          x1="20.2092"
          y1="55.3727"
          x2="36.2981"
          y2="79.7337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#78B700" />
          <stop offset="1" stop-color="#69A000" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11_15575"
          x1="27.6973"
          y1="66.6802"
          x2="34.2116"
          y2="73.4706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#78B700" />
          <stop offset="1" stop-color="#78B700" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11_15575"
          x1="33.3151"
          y1="71.4925"
          x2="27.6552"
          y2="65.8461"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#C2D1FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11_15575"
          x1="30.6178"
          y1="74.1947"
          x2="24.9579"
          y2="68.5482"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#C2D1FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11_15575"
          x1="28.0282"
          y1="76.7897"
          x2="22.3682"
          y2="71.1432"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#C2D1FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11_15575"
          x1="33.0224"
          y1="71.7847"
          x2="27.3626"
          y2="66.1383"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#C2D1FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_11_15575"
          x1="129.034"
          y1="22.4363"
          x2="121.461"
          y2="14.8812"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#FFF1DD" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ContributionAmountIcon;
