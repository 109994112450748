export enum FONTWEIGHT {
  light = 300,
  regular = 400,
  medium = 500,
  bold = 700,
}

export enum FONTSIZE {
  xs = "12px",
  sm = "14px",
  md = "16px",
  lg = "18px",
  xl = "20px",
  "2xl" = "24px",
  "3xl" = "32px",
}

export enum DISPLAYSIZE {
  xs = "24px",
  sm = "30px",
  md = "36px",
  lg = "48px",
  xl = "60px",
  "2xl" = "72px",
}

export enum FONTFAMILY {
  DM_Sans = "DM Sans",
  sans_serif = "sans-serif",
}

export enum BOXSHADOW {
  xs = "0px 1px 2px rgba(0, 0, 0, 0.1)",
  sm = "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
  md = " 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
  lg = "  0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  xl = " 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  "2xl" = " 0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
  "3xl" = "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
}

export enum BLURFILTER {
  sm = "blur(8px)",
  md = "blur(16px)",
  lg = "blur(24px)",
  xl = "blur(40px)",
}
