import { colors } from "src/theme/colors";
import { theme } from "src/theme/theme";
import styled, { css } from "styled-components/macro";

export const Button = styled.button`
  display: flex;
  background-color: ${colors.primary[25]};
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "tnum";
  font-size: 14px;
  font-variant: tabular-nums;
  height: 22px;
  line-height: 22px;
  list-style: none;
  margin: 0;
  min-width: 60px;
  padding: 0;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;

  &:focus {
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    outline: 0;
  }
`;

export const Image = styled.img`
  filter: invert(55%) sepia(100%) saturate(3.5) hue-rotate(45deg);
  height: 20px;
  width: auto;
  opacity: 0.25;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
`;

const CheckedImage = css`
  background-color: ${theme.colors.primary[20]};
  transition: all 0.2s ease-in-out;
  ${Image} {
    opacity: 1;
  }
`;

export const ImageContainer = styled.span`
  ${({ selected }: { selected?: boolean }) => selected && CheckedImage}
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 50%;
  height: 20px;
`;
