import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

const useStatements = () =>
  useQuery(
    "/Account-statements",
    () => axios(`${baseUrl}/account-statements`),
    {
      refetchInterval: 1800000,
      refetchOnWindowFocus: false,
    },
  );

export default useStatements;
