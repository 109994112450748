import { Route } from "react-router-dom";

import { WEBAPPLINK } from "./config";

const isOnboardingApproved = (user) => {
  return user.data.onboardingStep === "DONE" && user.data.isApproved === true;
};

const PrivateRoute = ({ user, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.isLoading === false) {
          if (user.isAuthenticated) {
            if (!isOnboardingApproved(user))
              window.location.href = `${WEBAPPLINK}/onboarding`;
            return children;
          }
          window.location.replace(`${WEBAPPLINK}/login`);
          return null;
        }
      }}
    />
  );
};

export default PrivateRoute;
