import { useEffect, useState } from "react";
import { InfoCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    RadioChangeEvent,
    Row,
    Typography,
    Radio,
    Menu,
    Dropdown,
    DatePicker,
    Tooltip,
} from "antd";
import { SymbolInfoWidget } from "../../widgets/SymbolInfo";
import { RadioGroup, RadioGroupPL } from "../RadioGroup";
import { StockTextInput } from "../StockTextInput";
import { StockProfitLossInput } from "../StockProfitLossInput";
import { FormItem } from "../FormItem";
import { useMediaQuery } from "src/components/FinaLayout/hooks/hooks";
import { StockNumberInput } from "../StockNumerInput";
import moment from "moment";

import {
    optionsTif,
    optionsOrderType,
    optionsProfitLossType,
    orderTypeLabel,
    sellBuyValidationRules,
    orderType,
    tifDefinition,
    sideOrder,
} from "../../utils/constants";
import { tickerInfo } from "../../utils/interfaces";
import { useGetTickerPrice } from "../../api/useGetTickerPrice";
import { SummaryModal } from "../../Layouts/SummaryModal";
import { InputLabelDescription } from "../InputLabelDescription";
import { useCustomInvestments } from "src/store/hooks";
import { formatMoney } from "src/utils/number";
import { theme } from "src/theme/theme";
import { ButtonTheme } from "src/components/Button/ButtonTheme";

const { Text } = Typography;

function disabledDate(current: moment.Moment) {
    return current && current < moment().startOf("day");
}

interface TradingFormProps {
    side: string;
    stockInfos: tickerInfo;
    onCancel: () => void;
}

export const TradingForm: React.FC<TradingFormProps> = ({ side, stockInfos, onCancel }) => {
    const isMobile = useMediaQuery("(max-width: 785px)");
    const [form] = Form.useForm();
    const { symbol: tickerSymbol, name: tickerName } = stockInfos;
    const [order_type, setOrderType] = useState("Market");
    const [tif, setTif] = useState("DAY");
    const [amount, setAmount] = useState<number | undefined>(0);
    const [dateAndTime, setDateAndTime] = useState<moment.Moment>(null);
    const [profitLossType, setProfitLossType] = useState("");
    const [tickePrice, setTickerPrice] = useState(0)

    const [orderData, setOrderData] = useState({ formData: {}, otherData: {} });
    const [showSummarytModal, setShowSummarytModal] = useState(false);


    const { data: tickerPriceData } = useGetTickerPrice(tickerSymbol)

    const investmentOverView = useCustomInvestments();
    const balance = investmentOverView.data?.totalCash

    useEffect(() => {
        if (tickerPriceData) {
            setAmount(tickerPriceData?.price);
            setTickerPrice(tickerPriceData?.price)
        }
    }, [tickerPriceData]);

    const handleSelectOrderType = (e: any) => {
        setOrderType(e.key);
        form.setFieldsValue({ order_type: e.key });
        form.resetFields([
            "order_quantity",
            "order_limit_price",
            "tif",
            "order_stop_price",
            "trailingStep",
            "take_profit",
            "stop_loss",
        ]);
        setDateAndTime(null);
    };

    const handleChangeOrderType = ({ target: { value } }: RadioChangeEvent) => {
        setOrderType(value);
        form.resetFields([
            "order_quantity",
            "order_limit_price",
            "tif",
            "order_stop_price",
            "trailingStep",
            "take_profit",
            "stop_loss",
        ]);
        setDateAndTime(null);
    };

    const handleChangeSharesNumber = (value: number) => {
        setAmount(value * tickePrice);
    };

    const handleChangeStopPrice = (value: number) => { };
    const handleChangeLimitPrice = (value: number) => { };
    const handleChangeTrailingStep = (value: number) => { };

    const handleSelectTif = (e: any) => {
        setTif(e.key);
        form.setFieldsValue({ tif: e.key });
        if (!["GTD", "GAT"].includes(tif)) setDateAndTime(null);
    };

    const onOk = (dateObj: moment.Moment) => {
        setDateAndTime(dateObj);
    };

    const handleChangeProfitLossType = ({
        target: { value },
    }: RadioChangeEvent) => {
        setProfitLossType(value);
    };

    const handleChangeTakeProfit = (value: number) => { };
    const handleChangeStopLoss = (value: number) => { };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const handleShowSummarytModal = () => {
        setShowSummarytModal(!showSummarytModal);
    };
    const handleSubmit = (e: any) => {
        let values = form.getFieldsValue();
        // currency is hardcoded for demo purposes TODO: get it from selected client account
        values = {
            ...values,
            ticker_id: stockInfos.id,
            order_price: tickePrice,
            order_value: amount,
            currency: "USD",
            order_type: orderType[values.order_type]
        };
        if (dateAndTime !== null)
            values = { ...values, duration: dateAndTime };
        if (order_type === "TrailingStop") {
            values = { ...values, order_stop_price: amount - values.trailingStep }
        }
        delete values.trailingStep
        setOrderData((prevState) => ({
            ...prevState, formData: values, otherData: { order_side: side, ticker_symbol: tickerSymbol, ticker_name: tickerName }
        }));
        handleShowSummarytModal()
    };

    const menu = (
        <Menu onClick={handleSelectOrderType}>
            {optionsOrderType.map((item) => (
                <Menu.Item key={item.label}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                        {item.value}
                        <Tooltip title={item.definition} key={item.value}>
                            <InfoCircleOutlined style={{ fontSize: "12px" }} />
                        </Tooltip>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    const menuTif = (
        <Menu onClick={handleSelectTif}>
            {optionsTif.map((item) => (
                <Menu.Item key={item.label}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                        {item.value}: ({item.meaning})
                        <Tooltip title={item.definition} key={item.value}>
                            <InfoCircleOutlined style={{ fontSize: "12px" }} />
                        </Tooltip>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    const stopPriceValidation = (rule, value) => {
        if (order_type === "StopLimit") {
            const limitValue = form.getFieldValue("order_limit_price");
            if (side === sideOrder.BUY && value < limitValue)
                return Promise.reject(
                    "The limit price should be set at or below the stop price!",
                );
            if (side === sideOrder.SELL && value > limitValue)
                return Promise.reject(
                    "The limit price should be set at or above the stop price!",
                );
        }
        return Promise.resolve();
    };

    const limitPriceValidation = (rule, value) => {
        if (order_type === "Limit") {
            // ensure that the limit price is not set absurdly high (for example 50% above or below last available price of the security)
            const diffrence = Math.abs(value - tickePrice);
            const percentage = (diffrence / tickePrice) * 100;
            if (percentage > 50)
                return Promise.reject(
                    "Limit price can't exceed 50% range from last available price!",
                );
        }
        return Promise.resolve();
    }

    const amountValidation = (rule, value) => {
        // ensure that the trade amount does not exceed the available cash balance.
        if (amount + (amount / 100) > balance) // 1% including fees
            return Promise.reject(
                "You cannot exceed the available cash balance!",
            );
        return Promise.resolve();
    }

    const durationValidation = (rule, value) => {
        if (["GTD", "GAT"].includes(tif) && dateAndTime === null)
            return Promise.reject("Select the time! ");
        return Promise.resolve();
    };

    return (
        <>
            <Form
                initialValues={{ order_type: "Market", order_quantity: 1, tif: "DAY", take_profit: 0, stop_loss: 0 }}
                form={form}
                style={{ width: "100%" }}
                onFinish={handleSubmit}
            >
                <Row gutter={[16, 16]} style={{ paddingTop: 24 }}>
                    <Col span={24}>
                        <SymbolInfoWidget symbol={tickerSymbol} />
                    </Col>
                    <Col span={24}><Text strong>Available Cash: </Text><Text strong style={{ color: theme.colors.primary[500] }}>${formatMoney(balance)}</Text></Col>
                    <Col span={24}>
                        <FormItem name="order_type">
                            {isMobile ? (
                                <Dropdown overlay={menu}>
                                    <Button size="large" style={{ width: "100%" }}>
                                        {order_type}
                                    </Button>
                                </Dropdown>
                            ) : (
                                <RadioGroup
                                    size="large"
                                    style={{ width: "100%" }}
                                    onChange={handleChangeOrderType}
                                    optionType="button"
                                    buttonStyle="solid"
                                >
                                    {optionsOrderType.map((option) => (
                                        <Radio.Button value={option.value}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                                {option.label}
                                                <Tooltip title={option.definition} key={option.value}>
                                                    <InfoCircleOutlined style={{ fontSize: "12px" }} />
                                                </Tooltip>
                                            </div>
                                        </Radio.Button>
                                    ))}
                                </RadioGroup>
                            )}
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        {tickePrice !== 0 && (
                            <FormItem name="order_quantity"
                                rules={[
                                    ...sellBuyValidationRules.quntity,
                                    {
                                        validator: amountValidation,
                                    }
                                ]}
                            >
                                <StockTextInput
                                    stockPrice={tickePrice}
                                    leftLabel="Number Of Shares"
                                    rightLabel="Indicative Value"
                                    onChange={handleChangeSharesNumber}
                                />
                            </FormItem>
                        )}
                    </Col>
                    {/* {["Stop", "StopLimit", "OCO"].includes(order_type) && (
                        <Col span={24}>
                            <FormItem
                                name="order_stop_price"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the stop loss number!",
                                    },
                                    {
                                        validator: stopPriceValidation,
                                    },
                                ]}
                            >
                                <StockNumberInput
                                    leftLabel="Stop Price"
                                    rightLabel={orderTypeLabel[order_type].first}
                                    onChange={handleChangeStopPrice}
                                />
                            </FormItem>
                        </Col>
                    )} */}
                    {["Limit", "StopLimit", "OCO"].includes(order_type) && (
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <FormItem
                                name="order_limit_price"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the limit price number!",
                                    },
                                    {
                                        validator: limitPriceValidation,
                                    }
                                ]}
                            >
                                <StockNumberInput
                                    leftLabel="Limit Price"
                                    rightLabel={orderTypeLabel[order_type].second}
                                    onChange={handleChangeLimitPrice}
                                />
                            </FormItem>
                        </Col>
                    )}
                    {/* {order_type === "TrailingStop" && (
                        <>
                            <Col span={24}>
                                <FormItem
                                    name="trailingStep"
                                    rules={sellBuyValidationRules.price}
                                >
                                    <StockNumberInput
                                        leftLabel="Trailing Step"
                                        rightLabel={orderTypeLabel[order_type].second}
                                        onChange={handleChangeTrailingStep}
                                    />
                                </FormItem>
                            </Col>
                        </>
                    )} */}
                    {["Limit", "Stop", "StopLimit", "OCO", "TrailingStop"].includes(
                        order_type,
                    ) && (
                            <>
                                <Col xs={24} sm={24} md={12} lg={12} >
                                    <InputLabelDescription label="Time in Force (TIF)" description={tifDefinition} />
                                    <FormItem name="tif">
                                        <Dropdown overlay={menuTif}>
                                            <Button size="large" style={{ width: "100%" }}>
                                                {tif}
                                            </Button>
                                        </Dropdown>
                                    </FormItem>
                                </Col>
                                {/* <Col xs={24} sm={24} md={12} lg={12} >
                                    <Text style={{ color: "#0D4BA8", paddingLeft: 10 }}>
                                        Duration
                                    </Text>
                                    <FormItem
                                        name="duration"
                                        rules={[{ validator: durationValidation }]}
                                    >
                                        <DatePicker
                                            value={dateAndTime}
                                            showTime
                                            disabledDate={disabledDate}
                                            allowClear={false}
                                            size="large"
                                            onOk={onOk}
                                            format={"YYYY-MM-DD HH:mm z"}
                                            style={{ width: "100%" }}
                                            disabled={!["GTD", "GAT"].includes(tif)}
                                        />
                                    </FormItem>
                                </Col> */}
                            </>
                        )}
                    {/* {!["OCO"].includes(order_type) && (
                        <>
                            <Col span={24}>
                                <FormItem
                                    name="take_profit"
                                >
                                    <StockProfitLossInput
                                        type="profit"
                                        leftLabel="Take Profit"
                                        rightLabel={
                                            <RadioGroupPL
                                                size="small"
                                                onChange={handleChangeProfitLossType}
                                                value={profitLossType}
                                            >
                                                {optionsProfitLossType.map((option) => (
                                                    <Radio.Button value={option.value} key={option.value}>
                                                        &#x2022; {option.label}
                                                    </Radio.Button>
                                                ))}
                                            </RadioGroupPL>
                                        }
                                        profitLossType={profitLossType}
                                        initialAmount={amount}
                                        onChange={handleChangeTakeProfit}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem name="stop_loss"
                                >
                                    <StockProfitLossInput
                                        type="loss"
                                        leftLabel="Stop Loss"
                                        profitLossType={profitLossType}
                                        initialAmount={amount}
                                        onChange={handleChangeStopLoss}
                                    />
                                </FormItem>
                            </Col>
                        </>
                    )} */}
                    <Col span={12} style={{ paddingTop: 18 }}>
                        <ButtonTheme
                            // type="primary"
                            // ghost
                            size="large"
                            style={{ width: "100%", fontWeight: 600 }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </ButtonTheme>
                    </Col>
                    <Col span={12} style={{ paddingTop: 18 }}>
                        <ButtonTheme
                            primary={true}
                            size="large"
                            style={{
                                width: "100%",
                                fontWeight: 600,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            htmlType="submit"
                            disabled={false}
                        >
                            {side.toUpperCase()}
                            <RightCircleOutlined />
                        </ButtonTheme>
                    </Col>
                </Row>
            </Form >
            <SummaryModal isSummaryModalVisible={showSummarytModal} setSummaryModalVisible={handleShowSummarytModal} order={orderData} onCancel={onCancel} />
        </>
    );
};
