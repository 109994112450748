import React from "react";
import {
  StyledModal,
  StyledButton,
  ButtonContainer,
  IconWrapper,
  Title,
  SubTitle,
  Frame,
} from "./styles/confirm";
import { ModalProps } from "antd";
import { Icon } from "../.";
import trashIcon from "src/assets/trash.svg";

interface IConfirmModal extends ModalProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  buttonLoading?: boolean;
}

const Confirm = ({
  buttonLoading,
  onConfirm,
  onCancel,
  ...rest
}: IConfirmModal) => {
  return (
    <StyledModal
      maskStyle={{
        backdropFilter: "blur(10px)",
      }}
      centered
      closable={false}
      width={480}
      footer={[
        <ButtonContainer key="customAction">
          <StyledButton
            block
            danger
            onClick={onConfirm}
            loading={buttonLoading}
          >
            Yes, I'm Sure
          </StyledButton>
          <StyledButton block type="primary" onClick={onCancel}>
            No, Go Back
          </StyledButton>
        </ButtonContainer>,
      ]}
      {...rest}
    >
      <Frame>
        <IconWrapper>
          <Icon src={trashIcon} color="red" />
        </IconWrapper>
        <Title>Are you sure you want to cancel this Order?</Title>
        <SubTitle>This action is not reversible. Please confirm.</SubTitle>
      </Frame>
    </StyledModal>
  );
};

export default Confirm;
