import React, { CSSProperties } from "react";

import { Icon as TIcon } from "./style";

const Icon = ({
  src,
  color,
  size,
  disabled = false,
  ...rest
}: {
  src: string;
  color: "white" | "green" | "blue" | "red" | "dark-green" | "default";
  size?: string;
  style?: CSSProperties;
  disabled?: boolean;
}) => {
  return (
    <TIcon disabled={disabled} {...rest} size={size} color={color} src={src} />
  );
};

export default Icon;
