import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AssetClassObject } from "./goal";
import { RootStateType } from "../rootReducer";

const initialState: AssetClassObject = {
  name: "",
  description: "",
  asset_items: []
};

const AssetClassSlice = createSlice({
  name: "assetclass",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    updateAssetItem: (
      state,
      action: PayloadAction<{
        index: number;
        attribute: string;
        value: number | string;
      }>,
    ) => {
      const { index, attribute } = action.payload;
      let newValue = action.payload.value;
      if (attribute.toLowerCase().includes("weight"))
        newValue = +((newValue as number) / 100).toFixed(2);
      state.asset_items[index][attribute] = newValue;
    },
    setAssetClass: (state, action: PayloadAction<AssetClassObject>) => {
      return action.payload;
    },
    setAssetName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setAssetDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload
    },
  },
});

export const { updateAssetItem, resetState, setAssetClass, setAssetName, setAssetDescription } =
  AssetClassSlice.actions;

export const selectAssetClass = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.AssetClassReducer.asset_items,
);

export const selectAssetName = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.AssetClassReducer.name,
);
export const selectAssetDescription = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.AssetClassReducer.description,
);

export default AssetClassSlice.reducer;
