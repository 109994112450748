import { InputNumber, Modal } from "antd";
import styled from "styled-components";

export const Wrapper = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
    background-color: #f7f9fb;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const Input = styled(InputNumber)`
  border-radius: 5px;
  border: 1px solid rgba(33, 120, 255, 0.5);
  background: #f7f9fb;
  padding: 4px 0;
  color: #2478f6;
`;
