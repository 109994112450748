import React from "react";
import styled, { keyframes, css, useTheme } from "styled-components";
import { GREY } from "../../../utils/colors";
import { ReactComponent as ArrowIcon } from "src/assets/collapseArrow.svg";
import { Itheme } from "src/theme/types/theme";

type Props = {
  collapse: boolean;
  setCollapse: (collapse) => void;
  absolute?: boolean;
};

const AbsoluteStyle = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
  background-color: ${GREY};
`;

const Container = styled.div<{ absolute: boolean }>`
  width: 32px;
  height: 32px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ absolute }) => (absolute ? AbsoluteStyle : null)}
`;

const Circle = styled.div`
  width: 80%;
  height: 80%;
  background-color: ${(props) => props.theme[500]};

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimationLeft = keyframes`
    from{
        transform: rotate(180deg)
    }
    to{
        transform: rotate(-720deg)

    }
`;
const AnimationRight = keyframes`
    from{
        transform: rotate(0deg)
    }
    to{
        transform: rotate(900deg)
    }
`;

const Arrow = styled(ArrowIcon)<{ $collapse: boolean }>`
  animation-name: ${({ $collapse }) =>
    $collapse ? AnimationRight : AnimationLeft};
  animation-duration: 1s;
  transform: ${({ $collapse }) =>
    $collapse ? "rotate(180deg) " : "rotate(0deg) "};
`;

const CollapseButton: React.FC<Props> = ({
  collapse,
  setCollapse,
  absolute = true,
}) => {
  const theme: Itheme = useTheme();

  return (
    <Container absolute={absolute}>
      <Circle
        onClick={() => setCollapse(!collapse)}
        theme={theme.colors.primary}
      >
        <Arrow
          $collapse={collapse}
          fill={theme.colors["white"]}
          width={"16px"}
          height={"16px"}
        />
      </Circle>
    </Container>
  );
};

export default CollapseButton;
