export const errorWrapper = (err: any) => {
  const data = err?.response?.data ?? err?.data;
  let messageV1 = data?.message;
  let messageV2 = data?.message?.detail;

  const message =
    messageV2 ?? messageV1 ?? data?.error?.message ?? "Something went wrong";

  return {
    ...(err?.response?.data ?? {}),
    message,
    status: data?.status ?? data?.statusCode,
    success: false,
    data: null,
  };
};
