import styled from "styled-components/macro";
import { Select as MSelect, Typography } from "antd";
import { customScrollbar } from "src/utils/styledComponents/scroll";
import { theme } from "src/theme/theme";

export const SelectTheme = styled(MSelect)`
  & .ant-select-selector {
    border-radius: 5px !important;
    border: 1px solid ${theme.colors.primary[500]} !important;
    background: ${theme.colors.primary[10]};
    color: ${theme.colors.primary[500]};
    height: 40px !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

export const Select = styled(MSelect) <{ error?: string; color?: string }>`
  &.ant-select {
    &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 44px;
    }
    .ant-select-selector {
      border: 1px solid
        ${({ error }) =>
          error
            ? theme.colors.error[500]
            : theme.colors.primary[500]} !important;
      padding: 8px 16px;
      box-shadow: none !important;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
      .ant-select-selection-placeholder {
        line-height: 30px !important;
        margin-left: 30px;
      }
    }
  }
  &.ant-select-disabled {
    cursor: default !important;
    .ant-select-selector {
      background-color: #f7f9fb !important;
      color: #000 !important;
      cursor: default !important;

      .ant-select-selection-search-input {
        cursor: default !important;
      }
    }
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 8px 16px;
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
  }

  ${customScrollbar}
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  // margin-bottom: 2px;
  .ant-typography-danger {
    color: ${theme.colors.error[500]} !important;
  }
  color: ${theme.colors.primary[500]};
`;

export const Text = styled(Typography.Text)`
  &.ant-typography {
    font-size: 12px;
    opacity: 0.8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .ant-typography-danger {
      color: ${theme.colors.error[500]} !important;
    }
    color: ${({ type }) => !type && theme.colors.primary[500]};
  }
`;
