import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./ApiActions";

const initialState = {
  loading: false,
  data: [],
};

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productsRequest: (state) => {
      state.loading = true;
    },
    productsResponse: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    productsRemove: (state) => {
      state.loading = true;
      state.data = null;
    },
  },
});

export const { productsRequest, productsResponse, productsRemove } =
  slice.actions;

const loadProducts = () =>
  apiCallBegan({
    url: "/trackers/?structure=flat",
    onStart: productsRequest.type,
    onSuccess: productsResponse.type,
  });

export { loadProducts };
export default slice.reducer;
