import React from "react";
import { Slider } from "antd"
import styled from "styled-components"
import { theme } from "src/theme/theme";

const CustomSlider = styled(Slider)`

  .ant-slider-rail {
    height: 5px;
    background-color: ${theme.colors.primary[15]};
  }

  .ant-slider-track {
    height: 5px;
    background-color: ${theme.colors.primary[600]};
  }

  .ant-slider-handle {
    border-color: ${theme.colors.primary[600]};
    border-width: 4px;
  }

  &:hover {
    .ant-slider-track {
      background-color: ${theme.colors.primary[600]};
    }

    .ant-slider-rail {
      height: 5px;
      background-color: ${theme.colors.primary[15]};
    }

    .ant-slider-handle:hover {
      border-color: ${theme.colors.primary[600]};
    }
  }
  width: 100%;
`;

export default CustomSlider