import { useQuery } from "react-query";
import { errorWrapper } from "src/action";
import {
  ExitCustomPartialInvestmentData,
  IReturn,
  IReturnV2,
} from "src/action/result";
import axios from "src/utils/axiosInstance";
import { baseUrl_V2 } from "src/utils/config";

export const updateTriggers = async (
  id: string,
  {
    amount,
    takeProfit,
    stopLoss,
  }: { amount: number; takeProfit: number; stopLoss: number },
  otpCode: string | null,
): Promise<IReturnV2> => {
  try {
    let res;
    const values = {
      amount,
      new_take_profit: takeProfit,
      new_stop_loss: stopLoss,
      triggers_in_pct: false,
    };

    if (otpCode === null)
      res = await axios.put(
        `dashboard/investments/${id}/adjusting-triggers?resend=true`,
        values,
        { baseURL: "v2" },
      );
    else
      res = await axios.put(
        `dashboard/investments/${id}/adjusting-triggers?otp=${otpCode}`,
        values,
        { baseURL: "v2" },
      );
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const updateCustomTriggers = async (
  id: string,
  {
    amount,
    takeProfit,
    stopLoss,
  }: { amount: number; takeProfit: number; stopLoss: number },
  otpCode: string | null,
): Promise<IReturnV2> => {
  try {
    let res;
    const values = {
      amount,
      new_take_profit: takeProfit,
      new_stop_loss: stopLoss,
      triggers_in_pct: true,
    };

    if (otpCode === null)
      res = await axios.put(
        `dashboard/investments/${id}/adjusting-triggers?resend=true`,
        values,
        { baseURL: "v2" },
      );
    else
      res = await axios.put(
        `dashboard/investments/${id}/adjusting-triggers?otp=${otpCode}`,
        values,
        { baseURL: "v2" },
      );
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    throw new Error(error);
    return errorWrapper(error);
  }
};

export const exitInvestment = async (id: string): Promise<IReturn> => {
  try {
    const res = await axios.post(
      `/dashboard/investments/${id}/exit`,
      {},
      { baseURL: "v2" },
    );
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

// export const partialExitInvestment = async (
//   id: string,
//   values: any,
// ): Promise<IReturn> => {
//   try {
//     const res = await axios.post(`partial-exit/${id}/`, values);

//     return { data: res.data, status: res.status, success: true };
//   } catch (error) {
//     return errorWrapper(error);
//   }
// };

export const cancelExitPartialInvestment = async (id: string, payload: any) => {
  try {
    const res = await axios.put(`partial-exit/${id}/cancel`, payload);
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const updateExitPartialInvestment = async (id: string, payload: any) => {
  try {
    const res = await axios.put(`partial-exit/${id}`, payload);
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const triggerPartialExitOtp = async (id: string): Promise<IReturn> => {
  try {
    const res = await axios.post(`/partial-exit/otp?resourceId=${id}`);
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const customTriggerPartialExit = async (
  id: string,
  body: any,
  isAfterOTP: boolean,
  otp?: string,
): Promise<IReturnV2> => {
  try {
    if (!otp) {
      const res = await axios.post(
        `${baseUrl_V2}/dashboard/investments/${id}/partial-exit`,
        body,
      );
      return { data: res.data, status: res.status, success: true };
    } else {
      const res = await axios.post(
        `${baseUrl_V2}/dashboard/investments/${id}/partial-exit?otp=${otp
          .toString()
          .replace(/\s/g, "")}`,
        body,
      );
      return { data: res.data, status: res.status, success: true };
    }
  } catch (error) {
    console.error("error", error);
    return errorWrapper(error);
  }
};

export const exitPartialInvestementStatus = async (
  investmentOrderId: string,
  status?: string,
): Promise<ExitCustomPartialInvestmentData[]> => {
  try {
    const response = await axios.request({
      url: `${baseUrl_V2}/dashboard/investments/${investmentOrderId}/partial-exit`,
    });
    if (response?.data.length === 0) return null;
    return response?.data?.data;
  } catch (error) {
    return errorWrapper(error);
  }
};
//*****************investment orders ************************************/
const investementTransactionsHistory = async (investment_id: number) => {
  const {
    data: { data: transactions },
  } = await axios.get(`dashboard/investments/events`, {
    baseURL: "v2",
    params: { investment_id: investment_id, page_number: 1, page_size: 10 },
  });

  // await new Promise((resolve) => setTimeout(resolve, 1000));

  // const dummyOrdersData = [
  //   {
  //     id: 44,
  //     event_type: "Partial Exit",
  //     flag: "PENDING",
  //     created_at: "2015-05-12",
  //     updated_at: "2015-05-12",
  //     amount: -1000,
  //     indicative_nav: null
  //   },
  //   {
  //     id: 45,
  //     event_type: "Contribution",
  //     flag: "EXECUTED",
  //     created_at: "2015-05-12",
  //     updated_at: "2015-05-12",
  //     amount: 2000,
  //     indicative_nav: 2561
  //   },
  //   {
  //     id: 46,
  //     event_type: "Creation",
  //     flag: "EXECUTED",
  //     created_at: "2015-05-12",
  //     updated_at: "2015-05-12",
  //     amount: 2000,
  //     indicative_nav: 2561
  //   }
  // ]
  // return dummyOrdersData
  return transactions;
};

export const useInvestementTransactionsHistory = (investmentId: number) => {
  return useQuery(
    "investmentTransactions",
    () => investementTransactionsHistory(investmentId),
    { enabled: true },
  );
};
// **********************************************************************/

export const validateExitInvestment = async (
  id: string,
  values: any,
): Promise<IReturn> => {
  try {
    const res = await axios.post(
      `/dashboard/investments/${id}/exit?otp=` +
        values.otpCode.replace(/[^\d]/g, ""),
      values,
      {
        baseURL: "v2",
      },
    );
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const cancelOrders = async (
  orderType: string,
  id: string,
  cancelRoute: string,
): Promise<IReturn> => {
  try {
    const res = await axios.put(`${orderType}/${id}/${cancelRoute}`);
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const validateCancelOrders = async (
  orderType: string,
  id: string,
  values: { otpCode: string },
  cancelRoute: string,
): Promise<IReturn> => {
  try {
    const res = await axios.put(
      `${orderType}/${id}/${cancelRoute}/otp`,
      values,
    );
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    return errorWrapper(error);
  }
};

export const exitPublicInvestment = async (id) => {
  try {
    const res = axios.post(
      `/dashboard/investments/${id}/exit?resend=true`,
      {},
      { baseURL: "v2" },
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const exitPublicInvestmentOtp = async (id, otp) => {
  try {
    const res = axios.post(
      `/dashboard/investments/${id}/exit?otp=${otp}`,
      {},
      { baseURL: "v2" },
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const partialExitInvestment = async (
  id: string,
  values: any,
): Promise<IReturn> => {
  try {
    const res = await axios.post(
      `dashboard/investments/${id}/partial-exit?resend=true`,
      values,
      { baseURL: "v2" },
    );
    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    throw errorWrapper(error);
  }
};

export const partialExitInvestmentOtp = async (
  id: string,
  values: any,
  otp: string,
): Promise<IReturn> => {
  try {
    const res = await axios.post(
      `dashboard/investments/${id}/partial-exit?otp=${otp}`,
      values,
      { baseURL: "v2" },
    );

    return { data: res.data, status: res.status, success: true };
  } catch (error) {
    throw new Error(error);
  }
};
