import React from "react";
import styled from "styled-components";
import { ReactComponent as FirmLogo } from "src/assets/logo.svg";
import { ReactComponent as CollapsedLogo } from "src/assets/collapsedLogo.svg";

const Container = styled.a`
  height: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
`;

const Logo: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  return (
    <Container>{isCollapsed ? <CollapsedLogo /> : <FirmLogo />}</Container>
  );
};

export default Logo;
