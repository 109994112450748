import styled from "styled-components";

export const Wrapper = styled.div`
  border: 1px solid #e8f1ff;
  border-radius: 12px;
  align-self: stretch;
  padding: 1rem 3rem 1rem;
  width: 100%;
  background-color: #F7F9FB;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  [aria-selected="false"] {
    opacity: 50%;
  }
`;