import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import { FC } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useMediaQuery } from "src/components/FinaLayout/hooks/hooks";

interface CardTitleProps {
    title: string,
    redirectUrl: string
}

const CardTitle: FC<CardTitleProps> = (props) => {
    const isMobile = useMediaQuery("(max-width: 450px)");
    const history = useHistory();

    const { title, redirectUrl } = props
    const { Title } = Typography

    const queryClient = useQueryClient()

    return (
        <Space style={{ display: "flex", alignItems: "stretch" }}>
            <ArrowLeftOutlined
                onClick={() => {
                    queryClient.invalidateQueries({ queryKey: ['getWatchlists'] })
                    history.push(redirectUrl)
                }}
                style={{ paddingRight: "0.5em", cursor: "pointer" }}
            />
            {!isMobile &&
                <Title level={4} style={{ margin: 0 }}>
                    {title}
                </Title>
            }
        </Space>
    );
};

export default CardTitle;