import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { userI } from "src/interfaces/Client";
// import { RootStateType } from "./rootReducer";
import { apiCallBegan } from "./ApiActions";
import { userInterface } from "src/interfaces/Client";
/*
 ** Notes:
 ** - client state object is of type ClinetDataRes (src/intefaces/Client.d.ts)
 ** - client is the current user.
 */

/*
 *******************************************************************************
 ** reducer
 */

const initialState: Partial<userInterface> = {
  data: null,
  isLoading: true,
  isAuthenticated: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clientRequested: (state: Partial<userInterface>) => {
      state.isLoading = true;
    },
    clientReceived: (
      state: Partial<userInterface>,
      action: PayloadAction<userI>,
    ) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.data = action.payload;
    },
    clientFailed: (state: Partial<userInterface>) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.data = null;
    },
    clientRemoved: (state: Partial<userInterface>) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.data = null;
    },
  },
});
export const { clientReceived, clientRequested, clientRemoved, clientFailed } =
  slice.actions;

/*
 *******************************************************************************
 ** action Creators
 */

const loadUser = () =>
  apiCallBegan({
    url: "/me",
    onStart: clientRequested.type,
    onSuccess: clientReceived.type,
    onFail: clientFailed.type,
  });

/*
 *******************************************************************************
 ** exports
 */

export { loadUser };
export default slice.reducer;
