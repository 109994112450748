import {
  Badge,
  Button,
  Col,
  Input,
  ModalProps,
  Row,
  Tooltip,
  message,
} from "antd";
import { FC, useMemo } from "react";
import * as Styled from "./EditAssetClass.css";
import { Flex } from "src/components/Flex";
import Text from "antd/lib/typography/Text";
import { PieChart, PieColors } from "../PieChart";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAssetClass,
  selectAssetDescription,
  selectAssetName,
  setAssetDescription,
  setAssetName,
  updateAssetItem,
} from "src/store/Goal-Based/AssetClassSlice";
import { useEditAssetClassesMutation } from "src/store/Goal-Based/goalBasedApi";
import TextArea from "antd/lib/input/TextArea";
import { theme } from "src/theme/theme";
import { colors } from "src/theme/colors";
import styled from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 8px;
  background-color: ${({ title }) =>
    title === "Cancel" ? "white" : colors.primary["500"]};

  border: none;
  border: ${({ title }) => (title === "Cancel" ? "1px solid #D9D9D9" : "none")};
  &:hover {
    background-color: ${colors.primary["600"]};
    border: none;
    color: ${({ title }) => (title === "Cancel" ? "black" : "white")};
  }
`;

export interface IEditAssetClass {
  setVisible: (boolean) => void;
  smartfolioId: string;
  planId: number;
  setIsAssetClassSet: (boolean) => void;
}

export const subCardStyle = {
  backgroundColor: theme.colors.primary[10],
  borderRadius: "5px",
  padding: 8,
};

message.config({ duration: 6 });

export const EditAssetClass: FC<ModalProps & IEditAssetClass> = ({
  setVisible,
  smartfolioId,
  setIsAssetClassSet,
  planId,
  ...rest
}) => {
  const data = useSelector(selectAssetClass) ?? [];
  const name = useSelector(selectAssetName);
  const [editAssetClass, { isLoading }] = useEditAssetClassesMutation();
  const scaledWeightsSum = useMemo(() => {
    return data.reduce((acc, curr) => acc + curr.weight * 1000, 0);
  }, [data]);
  const buttons = [
    {
      title: "Cancel",
      handler: () => {
        setVisible(false);
        setIsAssetClassSet(false);
      },
    },
    {
      title: "Save As A New Smartfolio",
      handler: async () => {
        const payload = {
          planId,
          display_name: name,
          asset_class_items: data.map(
            ({ weight, minWeight, maxWeight, id }) => ({
              weight,
              asset_class_id: id,
              maximum_bound_weight: maxWeight,
              minimum_bound_weight: minWeight,
            }),
          ),
        };
        try {
          await editAssetClass({ payload, smartfolioId }).unwrap();
          setIsAssetClassSet(false);
          message.success(
            "You have successfully edited asset class allocations for the suggested smartfolio!",
          );
        } catch (error) {
          message.error("Failed to edit asset class allocations!");
        }
        setVisible(false);
      },
      primary: true,
    },
  ];

  const dispatch = useDispatch<any>();
  const conditionalColor =
    scaledWeightsSum === 1000 ? colors.primary[500] : "#F04438";

  return (
    <Styled.Wrapper {...rest}>
      <Flex flexDirection="column" alignItems="center" gap="1rem">
        <Styled.Title>Edit Asset Class</Styled.Title>
        <Flex flexDirection="column" width="100%">
          <Row gutter={[16, 16]}>
            <Col lg={8} className="graph">
              <Styled.Background>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  height="100%"
                >
                  <Flex flexDirection="column" width="100%" height="200px">
                    <PieChart
                      legend={false}
                      data={data?.map(({ assetClass, weight }) => ({
                        key: assetClass,
                        value: +(weight * 100).toFixed(0),
                      }))}
                    />
                  </Flex>
                  <Flex flexDirection="column" width="100%" gap="8px">
                    <Text style={{ color: "rgba(0, 0, 0, .7)" }}>
                      Name{" "}
                      {name.length === 0 && (
                        <span
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Text>
                    <Input
                      maxLength={90}
                      style={{ borderRadius: "5px" }}
                      placeholder="Your Smartfolio Name"
                      value={useSelector(selectAssetName)}
                      onChange={(e) => dispatch(setAssetName(e.target.value))}
                    />
                  </Flex>
                  <Flex flexDirection="column" width="100%" gap="8px">
                    <Text
                      style={{ marginTop: "1rem", color: "rgba(0, 0, 0, .7)" }}
                    >
                      Description{" "}
                      <span style={{ fontStyle: "italic" }}>- optional</span>
                    </Text>
                    <TextArea
                      style={{ borderRadius: "5px" }}
                      rows={4}
                      placeholder="Your Smartfolio Description"
                      maxLength={225}
                      value={useSelector(selectAssetDescription)}
                      onChange={(e) =>
                        dispatch(setAssetDescription(e.target.value))
                      }
                    />
                  </Flex>
                </Flex>
              </Styled.Background>
            </Col>
            <Col span={24} lg={16}>
              <Styled.Background>
                <Flex flexDirection="column" width="100%" gap="1rem">
                  <Styled.Row className="head">
                    <Text
                      style={{
                        color: colors.primary["900"],
                        fontWeight: "bold",
                      }}
                    >
                      Asset Class
                    </Text>
                    <Text
                      style={{
                        color: colors.primary["900"],
                        fontWeight: "bold",
                      }}
                    >
                      Weight
                    </Text>
                    <Text
                      style={{
                        color: colors.primary["900"],
                        fontWeight: "bold",
                      }}
                    >
                      Min Weight
                    </Text>
                    <Text
                      style={{
                        color: colors.primary["900"],
                        fontWeight: "bold",
                      }}
                    >
                      Max Weight
                    </Text>
                  </Styled.Row>
                  <Styled.Row>
                    <Badge
                      color={conditionalColor}
                      text="Total Allocated Weights"
                      style={{ color: conditionalColor }}
                    />
                    <Styled.Input
                      style={{
                        color: conditionalColor,
                        borderColor: conditionalColor,
                      }}
                      disabled
                      value={scaledWeightsSum / 10}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value!.replace("%", "")}
                    />
                    {scaledWeightsSum !== 1000 && (
                      <Text style={{ gridColumn: "3 / 5", color: "red" }}>
                        Weights must add up to 100% !
                      </Text>
                    )}
                  </Styled.Row>
                  {data?.map(
                    ({ assetClass, weight, minWeight, maxWeight }, index) => {
                      return (
                        <Styled.Row>
                          <Badge
                            color={PieColors[index]}
                            text={assetClass}
                            style={{ color: colors.primary["500"] }}
                          />
                          <Styled.Input
                            value={+((weight * 1000) / 10).toFixed(0)}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value!.replace("%", "")}
                            onChange={(e) => {
                              dispatch(
                                updateAssetItem({
                                  index,
                                  attribute: "weight",
                                  value: e,
                                }),
                              );
                            }}
                          />
                          <Styled.Input
                            value={((minWeight * 1000) / 10).toFixed(0)}
                            min={0}
                            max={(weight * 1000) / 10}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value!.replace("%", "")}
                            onChange={(e) =>
                              dispatch(
                                updateAssetItem({
                                  index,
                                  attribute: "minWeight",
                                  value: e,
                                }),
                              )
                            }
                          />
                          <Styled.Input
                            value={((maxWeight * 1000) / 10).toFixed(0)}
                            min={(weight * 1000) / 10}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value!.replace("%", "")}
                            onChange={(e) =>
                              dispatch(
                                updateAssetItem({
                                  index,
                                  attribute: "maxWeight",
                                  value: e,
                                }),
                              )
                            }
                          />
                        </Styled.Row>
                      );
                    },
                  )}
                </Flex>
              </Styled.Background>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[16, 16]}
            style={{ width: "100%", marginTop: "1.8rem" }}
          >
            {buttons.map(({ title, handler, primary }) => (
              <Col lg={4}>
                <StyledButton
                  block={title === "Cancel"}
                  size={"large"}
                  type={primary ? "primary" : null}
                  onClick={handler}
                  title={title}
                  loading={title === "Save As A New Smartfolio" && isLoading}
                  disabled={
                    title === "Save As A New Smartfolio" &&
                    (scaledWeightsSum !== 1000 || name.length <= 5)
                  }
                >
                  {title}
                </StyledButton>
              </Col>
            ))}
          </Row>
        </Flex>
      </Flex>
    </Styled.Wrapper>
  );
};
