import { Card } from "antd";
import Title from "antd/lib/typography/Title";
import { FC } from "react";
import { Flex } from "src/components/Flex";
import * as Styled from "./PageLayout.css";
import { LoadingContainer } from "../LoadingContainer";
import { getErrorMessage } from "src/utils/getErrorMessage";

export interface PageLayoutProps {
  children: React.ReactNode;
  extra?: React.ReactNode;
  title: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  extra,
  title,
  isLoading,
  isError,
  error,
}) => {
  let Loader;
  if (isLoading || isError)
    Loader = (
      <LoadingContainer
        isLoading={isLoading}
        isError={isError}
        errorMessage={getErrorMessage(error)}
      />
    );

  return (
    <Styled.Wrapper>
      <Card
        title={<Title level={2}>{title}</Title>}
        extra={extra}
        style={{ width: "100%" }}
        bodyStyle={{ padding: "0 24px 24px" }}
        headStyle={{ border: "none" }}
      >
        {Loader ?? (
          <Flex flexDirection="column" gap="1.4rem">
            {children}
          </Flex>
        )}
      </Card>
    </Styled.Wrapper>
  );
};
