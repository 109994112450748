import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GroupedPositionsInterface,
  PositionsInterface,
} from "src/interfaces/Positions";

const initialState: PositionsInterface = {
  isLoading: false,
  groupedPositions: {
    products: [
      {
        portalName: "",
        amount: 0,
      },
    ],
    themes: [
      {
        theme: "",
        amount: 0,
      },
    ],
    layers: [
      {
        layer: "",
        amount: 0,
      },
    ],
    riskSolutions: [
      {
        riskSolution: "",
        amount: 0,
      },
    ],
  },
};

const slice = createSlice({
  name: "Positions",
  initialState,
  reducers: {
    positionsRequested: (state: PositionsInterface) => {
      state.isLoading = true;
    },
    GroupedPositionsReceived: (
      state: PositionsInterface,
      action: PayloadAction<GroupedPositionsInterface>,
    ) => {
      state.groupedPositions = action.payload;
      state.isLoading = false;
    },
    positionsRemoved: (state: PositionsInterface) => {
      state.isLoading = true;
      state.groupedPositions = null;
    },
  },
});

export const {
  GroupedPositionsReceived,
  positionsRequested,
  positionsRemoved,
} = slice.actions;

/*
 *******************************************************************************
 ** action Creators
 */

// const loadGroupedPositions = () =>
//   apiCallBegan({
//     url: "positions/stat",
//     onStart: positionsRequested.type,
//     onSuccess: GroupedPositionsReceived.type,
//   });

// export { loadGroupedPositions };
export default slice.reducer;
