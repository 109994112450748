import React from "react";
import { Container, Inner, Button, Frame, List } from "./style";

const Disclaimer = ({ children }) => {
  return <Container>{children}</Container>;
};

Disclaimer.Inner = function InnerDesclaimer({ children, ...rest }) {
  return <Inner {...rest}>{children}</Inner>;
};

Disclaimer.List = ({ children, ...rest }) => {
  return <List {...rest}>{children}</List>;
};
Disclaimer.Button = function ButtonDisclaimer({ children, ...rest }) {
  return (
    <Button {...rest} size="large" type="primary">
      {children}
    </Button>
  );
};

Disclaimer.Frame = function ButtonFrame({ children }) {
  return <Frame>{children}</Frame>;
};

export default Disclaimer;
