import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InvestementCountInterface,
  InvestementInterface,
} from "src/interfaces/Investements";
import { apiCallBegan } from "./ApiActions";

const initialState: InvestementInterface = {
  isLoading: false,
  countData: {
    count: 0,
    amount: 0,
    residual: 0,
    nav: 0,
    pl: 0,
    balance: 0,
    reserved: 0,
  },
};

/*
 *******************************************************************************
 **  actions and reducers
 */

const slice = createSlice({
  name: "Investements",
  initialState,
  reducers: {
    investementsRequested: (state: InvestementInterface) => {
      state.isLoading = true;
    },
    investementsCountReceived: (
      state: InvestementInterface,
      action: PayloadAction<InvestementCountInterface>,
    ) => {
      state.countData = action.payload;
      state.isLoading = false;
    },
    investementsRemoved: (state: InvestementInterface) => {
      state.isLoading = true;
      state.countData = null;
    },
  },
});

/*
 *******************************************************************************
 ** action Creators
 */
const loadInvestemntsCount = () =>
  apiCallBegan({
    url: "investments/count",
    params: {
      status: "ACTIVE,PENDING_EXIT,ACTIVE_EXIT",
    },
    onStart: investementsRequested.type,
    onSuccess: investementsCountReceived.type,
  });

export const {
  investementsCountReceived,
  investementsRequested,
  investementsRemoved,
} = slice.actions;
export { loadInvestemntsCount };
export default slice.reducer;
