import ProTable from "@ant-design/pro-table";
import {
  Button,
  ConfigProvider,
  Input,
  Space,
  Tag,
  Tooltip,
} from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import { FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex } from "src/components/Flex";
import {
  selectBasket,
  setBasket,
} from "src/store/Goal-Based/SelfMadeSmartfolioSlice";
import { BasketItem, Security } from "src/store/Goal-Based/goal";
import { useGetSecuritiesQuery } from "src/store/Goal-Based/goalBasedApi";
import { noWrapTextRender } from "../../../TextWrapper";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { theme } from "src/theme/theme";
import { ProTableTheme } from "src/components/ProTable";

type DataIndex =
  | "symbol"
  | "security"
  | "type"
  | "sector"
  | "market"
  | "exchange"
  | "currency";

const tableAlertRender = ({ selectedRows }) => {
  return (
    <Flex>
      {selectedRows.map((item) => (
        <Tag color="green">{item?.symbol}</Tag>
      ))}
    </Flex>
  );
};

export interface ISecuritiesTable {
  setSecurities: React.Dispatch<React.SetStateAction<any[]>>;
  securities: any[];
}

export const SecuritiesTable: FC = () => {
  const dispatch = useDispatch<any>();
  const basket = useSelector(selectBasket);
  const { data, isLoading } = useGetSecuritiesQuery();
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const rowSelection = {
    selectedRowKeys: basket.slice(1).map(({ ticker_id }) => ticker_id),
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(
        setBasket(
          selectedRows.map((item: Security) => {
            const basketItem: BasketItem = {
              minimum_bound_weight: 0,
              maximum_bound_weight: 1,
              weight: 0,
              ticker_id: item.key,
              name: item.security,
              symbol: item.symbol,
            };
            return basketItem;
          }),
        ),
      );
    },
  };

  const getColumnSearchProps = (dataIndex: DataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) =>
      visible && setTimeout(() => searchInput.current.focus(), 100),
  });

  const columns = [
    {
      title: noWrapTextRender("Symbol"),
      sorter: (a, b) => a?.symbol.localeCompare(b?.symbol),
      key: "symbol",
      dataIndex: "symbol",
      width: 115,
      render: (symbol) => <strong>{symbol}</strong>,
      ...getColumnSearchProps("symbol"),
    },
    {
      title: noWrapTextRender("Security"),
      sorter: (a, b) => a?.security.localeCompare(b?.security),
      key: "security",
      dataIndex: "security",
      ...getColumnSearchProps("security"),
      render: (security) => (
        <Tooltip title={security}>
          <p
            style={{
              maxWidth: "10ch",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              margin: 0,
            }}
          >
            {security}
          </p>
        </Tooltip>
      ),
    },
    {
      title: noWrapTextRender("Type"),
      key: "type",
      dataIndex: "type",
      filterSearch: true,
      filters: [
        { text: "ETF", value: "ETF" },
        { text: "Stock", value: "Stock" },
        { text: "Composite_benchmark", value: "Composite_benchmark" },
      ],
      filterIcon: () => <FilterOutlined />,
      onFilter: (value: string, record) =>
        (record?.type as string).toLowerCase() === value.toLowerCase(),
      width: 100,
      render: (type) => <Tag>{type}</Tag>,
    },
    {
      title: noWrapTextRender("Sector"),
      key: "sector",
      dataIndex: "sector",
      render: (sector) => sector.join(", "),
      filterSearch: true,
      filters: [
        { text: "Energy", value: "Energy" },
        { text: "Information Technology", value: "Information Technology" },
        { text: "Materials", value: "Materials" },
        { text: "Consumer Discretionary", value: "Consumer Discretionary" },
        { text: "Health Care", value: "Health Care" },
        { text: "Communication Services", value: "Communication Services" },
        { text: "Financials", value: "Financials" },
        { text: "Real Estate", value: "Real Estate" },
        { text: "Consumer Staples", value: "Consumer Staples" },
        { text: "Others", value: "Others" },
      ],
      filterIcon: () => <FilterOutlined />,
      onFilter: (value: string, record) =>
        (record?.sector as string[]).some(
          (item) => item.toLowerCase() === value.toLowerCase(),
        ),
    },
    {
      title: noWrapTextRender("Market"),
      key: "market",
      dataIndex: "market",
      width: 100,
      filterSearch: true,
      filters: [
        { text: "SW", value: "SW" },
        { text: "US", value: "US" },
        { text: "LN", value: "LN" },
      ],
      filterIcon: () => <FilterOutlined />,
      onFilter: (value: string, record) =>
        (record?.market as string).toLowerCase() === value.toLowerCase(),
    },
    {
      title: noWrapTextRender("Exchange"),
      key: "exchange",
      dataIndex: "exchange",
      width: 130,
      filterSearch: true,
      filters: [
        { text: "NYSE", value: "NYSE" },
        { text: "NASDAQ", value: "NASDAQ" },
        { text: "LSE", value: "LSE" },
        { text: "SWX", value: "SWX" },
        { text: "NYSE Arca", value: "NYSE Arca" },
      ],
      filterIcon: () => <FilterOutlined />,
      onFilter: (value: string, record) =>
        (record?.exchange as string).toLowerCase() === value.toLowerCase(),
    },
    {
      title: noWrapTextRender("Currency"),
      key: "currency",
      dataIndex: "currency",
      width: 110
    },
  ];

  return (
    <ConfigProvider locale={enUSIntl}>
      <ProTableTheme
        loading={isLoading}
        bordered={true}
        search={false}
        pagination={false}
        columns={columns}
        dataSource={data?.filter((item) => item.symbol !== "CASH")}
        tableAlertRender={tableAlertRender}
        tableAlertOptionRender={({ onCleanSelected }) => null}
        scroll={{ y: 400, x: "max-content" }}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          ...rowSelection,
        }}
      />
    </ConfigProvider>
  );
};
