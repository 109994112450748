import { FC } from "react";
import * as Styled from "./StockSummary.css";
import { MiniMetric } from "src/pages/SingleSmartfolio/components/SmallStat";
import { Tabs, Tag } from "antd";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import { uniqueId } from "lodash";
import { SyncOutlined } from "@ant-design/icons";

interface IStockSummary {
  performance: Record<string, any>;
}

export const StockSummary: FC<IStockSummary> = ({ performance }) => {
  const stats = [
    {
      date: "1D",
      stat: performance?.one_day_return,
    },
    {
      date: "1W",
      stat: performance?.one_week_return,
    },
    {
      date: "1M",
      stat: performance?.one_month_return,
    },
    {
      date: "3M",
      stat: performance?.three_months_return,
    },
    {
      date: "6M",
      stat: performance?.six_months_return,
    },
    {
      date: "12M",
      stat: performance?.one_year_return,
    },
    {
      date: "YTD",
      stat: performance?.year2date_return,
    },
    {
      date: "3Y",
      stat: performance?.three_years_return,
    },
    {
      date: "VOLATITY 30D",
      stat: performance?.volatity_30d,
    },
  ];

  return (
    <>
      {
        performance?.one_day_return === null ? <Tag style={{ padding: 8 }} icon={<SyncOutlined spin />} color="orange">Calculating results, please wait!</Tag> :
          <Styled.Wrapper>

            <Tabs>
              {stats.map((element) => (
                <TabPane key={uniqueId()} tab={<MiniMetric {...element} />} />
              ))}
            </Tabs>
          </Styled.Wrapper>
      }
    </>
  );
};
