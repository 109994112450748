export enum ERiskSolutions {
  fullExposure = "fullexposure",
  vip = "autopilot",
}

export enum ERiskSolutionLabels {
  fullexposure = "Full Exposure",
  autopilot = "AI Autopilot",
}

export enum ERoboRiskSolutionsLabels {
  "AUTO-PILOT" = "AI Autopilot",
  "FULL-EXPOSURE" = "Full Exposure",
}
/**
 * list of available risk solutions and their description
 * they're hardcoded values and should be removed
 */
export const RiskSolutions = {
  Retail: [
    {
      id: ERiskSolutions.vip,
      name: ERiskSolutionLabels.autopilot,
      description:
        "Automated personalized rebalancing, to remain in line with your risk profile.",
    },
  ],
  Professional: [
    {
      id: ERiskSolutions.fullExposure,
      name: ERiskSolutionLabels.fullexposure,
      description:
        "No personalized rebalancing, following the target market performance.",
    },
    {
      id: ERiskSolutions.vip,
      name: ERiskSolutionLabels.autopilot,
      description:
        "Automated personalized rebalancing, to remain in line with your risk profile.",
    },
  ],
};

// export enum RiskSolutionNameToId {
//   "Full Exposure" = ERiskSolutions.fullExposure,
//   "AI Autopilot" = ERiskSolutions.vip,
// }

export enum EType {
  TPREMIUM = "premium",
  TVIP = "vip",
}

// export const PremiumType = {
//   "AI Autopilot": "VIP",
//   "Full Exposure": "thematic",
//   personalized: "standard",
// };

export const Layers = {
  LBASE: "Diversified",
  LOPPURTUNISTIC: "Opportunistic",
  LTHEMATIC: "Thematic",
  LALL: "All",
};

export const FinamazeBank = {
  USA: "USA - Signature Bank New York",
  UAE: "UAE - unknown",
};

/**
 * Investment type
 * @deprecated
 */
export const TypeByRiskSolution = {
  [ERiskSolutions.fullExposure]: "premium",
  [ERiskSolutions.vip]: "vip",
  // [ERiskSolutions.personalized]: "standard",
};
