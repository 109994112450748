import React from "react";
import { InputNumber } from "antd";
import styled from "styled-components";

interface Props {
  years: number;
  months: number;
}

const DurationInputContainer = styled.div`
  padding: 3px 16px;
  border-radius: 5px;
  border: 1px solid #12b76a40;;
  background: #12b76a1a;
  display: flex;
  gap: 10px;
  align-items: space-between;
`;
const NumberInput = styled(InputNumber)`
  border: none;
  width: 30px;
  &.ant-input-number {
    background: inherit;
  }
  .ant-input-number-input {
    padding: 0;
    color: #12b76a;
  }
  &.ant-input-number-focused {
    box-shadow: none;
    border-color: transparent;
  }
  & > div {
    position: relative;
  }

  & > div > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div > div::before,
  & > div > div::after {
    display: none;
  }
`;

const Label = styled.span`
  margin: 0;
  padding: 0;
  z-index: 5;
`;

const NoWrappingDiv = styled.div`
  white-space: nowrap;
`;

const SolveDurationInput: React.FC<Props> = ({
  years,
  months,
}) => {
  return (
    <DurationInputContainer>
      <NoWrappingDiv>
        <NumberInput
          disabled
          controls={false}
          value={years}
          min={months ? 0 : 1}
        />
        <Label>Years</Label>
      </NoWrappingDiv>
      <NoWrappingDiv>
        <NumberInput
          disabled
          controls={false}
          value={months}
          min={years ? 0 : 1}
        />
        <Label>Months</Label>
      </NoWrappingDiv>
    </DurationInputContainer>
  );
};

export default SolveDurationInput;