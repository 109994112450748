import { Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import { FC } from "react";
import { Flex } from "src/components/Flex";
import * as Styled from "./Exposure.css";
import { theme } from "src/theme/theme";

interface IExposure {
  data: { key: string; value: number }[];
  totalIExposures: number;
}

const style = {
  color: theme.colors.primary[500],
};

export const Exposure: FC<IExposure> = ({ data, totalIExposures }) => {
  return (
    <Flex flexDirection="column" gap="1rem">
      <Row gutter={8}>
        <Col>
          <Text strong style={{ color: theme.colors.primary[500] }}>
            Indicative Exposure
          </Text>
        </Col>
        <Col>
          <Text strong style={{ color: theme.colors.primary[500] }}>
            %
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {data.map(({ key, value }) => (
          <Col span={24} lg={12} xl={8}>
            <Styled.Stat>
              <Text style={{ color: "#475467" }}>{key}</Text>
              <Text style={{ color: theme.colors.primary[500], fontSize: 16 }}>
                {value.toString().replace(" ", " - ")}
              </Text>
            </Styled.Stat>
          </Col>
        ))}
      </Row>
      <Text type="secondary">
        * Indicate Exposure: Depending on the risk profile, it changes over time
        within the range to calibrate for the risk involved.
      </Text>
      <Styled.Wrapper>
        <Col>
          <Flex alignItems="center">
            <Text strong style={{ color: "white" }}>
              Number of Individual Exposures
            </Text>
          </Flex>
        </Col>
        <Col
          style={{
            backgroundColor: theme.colors.primary[600],
            padding: "0.4rem 0.8rem",
            borderRadius: "4px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Text strong style={{ color: "white", whiteSpace: "nowrap" }}>
            {totalIExposures}
          </Text>
        </Col>
      </Styled.Wrapper>
    </Flex>
  );
};
