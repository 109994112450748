import { Modal } from "antd";
import styled from "styled-components";

export const Wrapper = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }

  & .ant-table-header table {
    border-radius: 8px 8px 0 0;
  }

  & table {
    overflow: hidden !important;
    border: 1px solid #f0f0f0;
  }

  & .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }

  & .ant-alert.ant-alert-info.ant-alert-no-icon {
    padding: 0;
    background: none;
    border: none;
  }

  & .ant-pro-table .ant-table-tbody > tr > td > span {
    border-radius: 6px;
  }
`;
