import React from "react";
import { SelectValue } from "antd/es/select";
import { SelectProps } from "antd";

import { Select as MSelect, Label, Text } from "./styles/select";
import { SearchOutlined } from "@ant-design/icons";

const { Option, OptGroup } = MSelect;

interface Props extends SelectProps<any> {
  name: string;
  id?: string;
  label?: string;
  onSelectionChange?: (value: SelectValue, name: string) => void;
  group?: boolean;
  items?: any[];
  defaultValue?: any;
  error?: any;
  isNew?: boolean;
}

const Select = ({
  label,
  onSelectionChange,
  group,
  items,
  name,
  error,
  defaultValue,
  id,
  isNew,
  ...rest
}: Props) => {

  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Label>
      {isNew && (
        <div
          style={{
            position: "absolute",
            zIndex: 9,
            marginLeft: "17px",
            marginTop: "6px",
          }}
        >
          <SearchOutlined style={{ fontSize: "32px", color: "#2478F680" }} />
        </div>
      )}
      <MSelect
        listHeight={250}
        virtual={false}
        error={error}
        id={id || name}
        style={{ width: "100%" }}
        defaultValue={defaultValue}
        onChange={(value) => onSelectionChange(value, name || id)}
        
        {...rest}
      >
        <>
          {isNew &&
            items?.map(({ name, value }, i) => (
              <Option key={i} value={value}>
                {name}
              </Option>
            ))}
        </>
        {group
          ? items?.map(({ options, label }, i) => (
              <OptGroup key={i} label={label}>
                {options?.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </OptGroup>
            ))
          : items?.map(({ label, value }, i) => (
              <Option key={i} value={value}>
                {label}
              </Option>
            ))}
      </MSelect>
    </>
  );
};

export default Select;
