import { FC } from "react"
import { Row, Col, Typography, InputNumber } from "antd"
import { StockNumberInputProps } from "../../utils/interfaces"
import { InputLabelDescription } from "../InputLabelDescription"
import { theme } from "src/theme/theme"

const { Text } = Typography

export const StockNumberInput: FC<StockNumberInputProps> = ({ leftLabel, rightLabel, onChange }) => {

    const handleChangeStockPrice = (value: number) => {
        onChange(value)
    }

    return <>
        <InputLabelDescription label={leftLabel} description={rightLabel} />
        <Row justify="center" align="middle" style={{ width: "100%", height: 40, border: `1px solid ${theme.colors.primary[500]}` }}>
            <Col span={23}>
                <InputNumber
                    bordered={false}
                    defaultValue={0}
                    min={0}
                    style={{ width: "100%", color: theme.colors.primary[500] }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    onChange={handleChangeStockPrice}
                />
            </Col>
        </Row>
    </>
}