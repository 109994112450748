import styled from "styled-components/macro";
import { typography } from "src/utils/styledComponents/typography";
import { Link } from "react-router-dom";
import { LARGE_SCREEN, SMALL_LAPTOP } from "src/utils/constants/media";
import { Button as AntButton } from "antd";
import { colors } from "src/theme/colors";

export const Container = styled.div<{ $disableDetails?: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 328px) auto;
  grid-template-rows: auto;
  grid-template-areas: ${(props) =>
    props.$disableDetails ? "'content content'" : "'details content'"};
  /* grid-template-areas: "content content"; */
  width: 100%;
  overflow: hidden;
  ${typography.CustomText("1em")};

  @media (max-width: ${LARGE_SCREEN}px) {
    grid-template-columns: minmax(0, 270px) auto;
  }

  @media (max-width: ${SMALL_LAPTOP}px) {
    grid-template-columns: auto;
    grid-template-areas: "content content";
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  background-color: #fff;
  border-radius: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  min-height: 90vh;
  background-color: #fff;
  border-radius: 12px;
  padding: 1em;
`;

export const Back = styled(Link)`
  display: flex;
  align-items: center;
  padding-left: 1em;
  color: #2478f6;
  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;
  grid-area: back;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

export const Icon = styled.img`
  filter: invert(37%) sepia(35%) saturate(6278%) \hue-rotate(207deg)brightness(
      103%
    ) contrast(93%);
  transform: rotate(180deg);
`;

export const GoBackText = styled.span`
  margin-left: 1.5em;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25em;
  font-weight: 600;
  grid-area: title;
  background-color: #fff;
  padding-left: 2em;
  padding-right: 2em;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  justify-content: space-between;
`;

export const Button = styled(AntButton)`
  &.ant-btn {
    &.ant-btn-link {
      background: #f2a20c;
      border-color: #f2a20c;
      color: white;
      border-radius: 5px;

      &:hover,
      &:focus {
        background: #f7be54;
        border-color: #f7be54;
      }
    }
  }
`;

export const Details = styled.div`
  background-color: ${colors.primary[10]};
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
`;

export const DetailsContainer = styled.div`
  grid-area: details;
  background: #fff;
  padding: 1em;
  padding-right: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  @media (max-width: ${SMALL_LAPTOP}px) {
    padding: 1em;
    display: none;
  }
`;

export const Main = styled.main<{ $detailsDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  justify-content: flex-start;
  grid-area: content;
  padding: 1em;
  background-color: white;
  width: 100%;
  border-radius: ${(props) =>
    props.$detailsDisabled ? "12px" : "0 12px 12px 0px"};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  /* @media (max-width: ${SMALL_LAPTOP}px) {
    padding-top: 1rem;
  } */
`;

export const AnchorContent = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  border-right: 2px solid #eee;
`;
