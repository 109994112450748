import React, { useState } from "react";
import Icon from "src/components/Icon";

import {
  Container,
  Header,
  Wrapper,
  Title,
  IconWrapper,
} from "./styles/pagedetails";

import task from "src/assets/Chevron.svg";

const PageDetails = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Container>
      <Header onClick={() => setIsVisible((v) => !v)}>
        <Title>More Details</Title>
        <IconWrapper isVisible={isVisible}>
          <Icon src={task} color="blue" />
        </IconWrapper>
      </Header>
      <Wrapper
        isVisible={isVisible}
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        {children}
      </Wrapper>
    </Container>
  );
};

export default PageDetails;
