import React, { useEffect, useState } from "react";
import { Invest } from "../";
import { Button } from "../";
import checkIcon from "src/assets/check.svg";
import Loading from "../Loading";

const OTPModal = ({
  formik,
  isModalVisible,
  setModalVisible,
  onResend,
  error,
}) => {
  const [isDisabled, setDisabled] = useState(true);
  const [rerender, setReRender] = useState(1);

  useEffect(() => {
    let timer = null;
    if (isDisabled) {
      timer = setTimeout(() => {
        setDisabled(false);
      }, 30000);
    }
    return () => clearTimeout(timer);
  }, [isDisabled]);

  const handleSubmit = (e) => {
    formik.handleSubmit(e);
    setReRender((v) => ++v);
  };

  return (
    <Invest.Modal
      title="OTP Verification"
      handleCancel={() => setModalVisible(false)}
      isModalVisible={isModalVisible}
      handleOk={() => console.log()}
      footer={[
        <Invest.Action key="customFooter">
          <Button
            key="back"
            outline={true}
            width="100%"
            onClick={onResend}
            disabled={isDisabled || formik.isSubmitting}
            isLoading={formik.isSubmitting}
          >
            Resend OTP
          </Button>
          {formik.isSubmitting ? (
            <Loading />
          ) : (
            <Button
              key="submit"
              onClick={handleSubmit}
              width="100%"
              type="submit"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
            >
              <Invest.ButtonContainer>
                Verify
                <Invest.Icon size="20px" color="white" src={checkIcon} />
              </Invest.ButtonContainer>
            </Button>
          )}
        </Invest.Action>,
      ]}
    >
      <Invest.OTP
        key={rerender}
        error={error}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={null}
      />
    </Invest.Modal>
  );
};

export default OTPModal;
