import styled from "styled-components";

interface IContainer {
  isLoading?: boolean;
  isError?: boolean;
}

const Container = styled.div<IContainer>`
  position: absolute;
  display: ${({ isLoading, isError }) =>
    isLoading || isError ? "initial" : "none"};
  width: 100%;
  height: 100%;
  background-color: ${({ isError }) => (isError ? "#fff2f0" : "white")};
  top: 0;
  left: 0;
  z-index: 18;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

export { Container, SpinnerWrapper };
export type { IContainer };
