export const PROFIT_LOSS_TYPES = {
  Tight: {
    profit: 7,
    loss: 5,
  },
  Average: {
    profit: 13,
    loss: 10,
  },
  Wide: {
    profit: 18,
    loss: 15,
  },
};

export const optionsOrderType = [
  {
    label: "Market",
    value: "Market",
    definition:
      "A market order is an order to buy or sell a security at the best available price in the current market. It is widely used due to its simplicity and the certainty of execution, as it remains in effect only for the day, and extended hours trading, if applicable.",
  },
  {
    label: "Limit",
    value: "Limit",
    definition:
      " A limit order is an order to buy or sell a security at a specific price or better. A buy limit order can only be executed at the limit price or lower, and a sell limit order can only be executed at the limit price or higher. This provides control, but at the cost of potentially not being filled if the price does not hit the limit level.",
  },
  // {
  //   label: "Stop",
  //   value: "Stop",
  //   definition:
  //     "A stop order, also known as a stop-loss order, is an order to buy or sell a stock once the price of the stock reaches a specified price, known as the stop price. When the stop price is reached, a stop order becomes a market order.",
  // },
  // {
  //   label: "StopLimit",
  //   value: "StopLimit",
  //   definition:
  //     "A stop-limit order is an order to buy or sell a stock that combines the features of a stop order and a limit order. Once the stop price is reached, the stop-limit order becomes a limit order to buy or sell at the limit price or better.",
  // },
  // {
  //   label: "OCO",
  //   value: "OCO",
  //   definition:
  //     "A One-Cancels-the-Other (OCO) Order is a pair of orders stipulating that if one order is executed, then the other order is automatically canceled. It combines a stop order with a limit order on an automated trading platform.",
  // },
  // {
  //   label: "TrailingStop",
  //   value: "TrailingStop",
  //   definition:
  //     "A trailing stop order sets the stop price at a fixed amount either below the market price for a long stock or an ETF, or above the market price for a short stock or ETF. As the market price rises for a long stock or ETF, the stop price rises by the trail amount, but if the stock price falls, the stop loss price doesn't change, and a market order is submitted when the stop price is hit.",
  // },
];

export const optionsProfitLossType = [
  { label: "Tight", value: "Tight" },
  { label: "Average", value: "Average" },
  { label: "Wide", value: "Wide" },
];

export const optionsTif = [
  { label: "DAY", value: "DAY", meaning: "Day Order", definition: "Canceled if it does not execute by the close of the trading day on which it was placed." },
  { label: "GTC", value: "GTC", meaning: "Good-Til-Canceled", definition: "Remains active until it is executed or canceled by the trader, regardless of the passage of time." },
  { label: "FOK", value: "FOK", meaning: "Fill-or-Kill", definition: "Must be executed immediately and in its entirety; otherwise, the entire order is canceled." },
  { label: "MOO", value: "MOO", meaning: "Market-on-Open", definition: "Executed at the market price as soon as the market opens for trading." },
  { label: "LOO", value: "LOO", meaning: "Limit-on-Open", definition: "Specifying a price at which the trader is willing to buy or sell when the market opens. If the specified price is not met, the order may not be executed." },
  { label: "IOC", value: "IOC", meaning: "Immediate-or-Cancel", definition: "Must be executed immediately, either in part or in whole. Any portion of the order that cannot be executed immediately is canceled." },
  { label: "DTC", value: "DTC", meaning: "Day-til-Canceled", definition: "Executed at or near the closing price of the trading day." },
  { label: "GTD", value: "GTD", meaning: " Good-Til-Date Time/Date", definition: "Remains active until it is executed or canceled by the trader, up to a specified expiration date." },
  { label: "GAT", value: "GAT", meaning: "Good-after-Time/Date", definition: "Becomes active and remains open for execution after a specified time." },
  { label: "EOM", value: "EOM", meaning: "End-Of-Month", definition: "Canceled if it does not execute by the close of the trading month in which it was placed" }
];

export const sellBuyValidationRules = {
  quntity: [
    {
      required: true,
      message: "Please enter the number of shares!",
    },
  ],
  takeProfit: [
    {
      required: true,
      message: "Please enter the take profit number!",
    },
  ],
  stopLoss: [
    {
      required: true,
      message: "Please enter the stop loss number!",
    },
  ],
  limitPrice: [
    {
      required: true,
      message: "Please enter the limit price number!",
    },
  ],
  stopPrice: [
    {
      required: true,
      message: "Please enter the stop price number!",
    },
  ],
  price: [
    {
      required: true,
      message: "Please enter the price number!",
    },
  ],
};

export const orderTypeLabel = {
  Limit: {
    first: "The limit price at which you want to execute the trade",
    second: "The limit price at which you want to execute the trade",
  },
  Stop: {
    first: "The stop price at which you want to execute the trade",
    second: "The stop price at which you want to execute the trade",
  },
  StopLimit: {
    first: "The Stop price at which you want to trigger the trade",
    second: "The limit price at which you want to execute the trade",
  },
  OCO: {
    first:
      "The price at which you expect the security to reach in a favorable scenario",
    second:
      "The price at which you want to exit the position if the market moves against you",
  },
  TrailingStop: {
    first:
      "The trailing stop price will be adjusted based on this value as the market moves in your favor",
    second:
      "The trailing step will be adjusted based on this value as the market moves in your favor",
  },
};

export const sideOrder = {
  SELL: "sell",
  BUY: "buy",
}

export const orderType = {
  Market: "MARKET",
  Limit: "LIMIT",
  Stop: "STOP-MARKET",
  StopLimit: "STOP-LIMIT",
  TrailingStop: "TRAILING-STOP-LOSS-MARKET"
}

export const tifDefinition = "TIF indicates how long the order will remain active before it is executed or before it expires"

export const orderStatus = {
  EXECUTED: "EXECUTED",
  EXECUTING: "EXECUTING",
  PRE_PENDING: "PRE-PENDING",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  FAILED: "FAILED",
  CANCELING: "CANCELING",
  CANCELED: "CANCELED",
  FILLED: "FILLED",
}