export const CurrencySymbolLookup = {
    "USD": "$",
    "EUR": "€",
    "GBP": "£",
    "JPY": "¥",
    "CAD": "C$",
    "AUD": "A$",
    "CHF": "CHF",
    "CNY": "¥",
    "INR": "₹",
    "BRL": "R$",
    "KRW": "₩",
    "RUB": "₽",
    "MXN": "Mex$",
    "SGD": "S$",
    "HKD": "HK$",
    "SAR": "SR"
}