import styled, { css } from "styled-components/macro";

const WhiteIcon = css`
  filter: invert(100%);
`;

const defalutIcon = css`
  filter: none;
`;

const blueIcon = css`
  filter: invert(32%) sepia(75%) saturate(1010%) hue-rotate(189deg)
    brightness(112%) contrast(108%);
`;

const RedIcon = css`
  filter: invert(26%) sepia(57%) saturate(3356%) hue-rotate(337deg)
    brightness(92%) contrast(86%);
`;
const GreenIcon = css`
  filter: invert(61%) sepia(47%) saturate(2909%) hue-rotate(43deg)
    brightness(99%) contrast(98%);
`;

const DarkGreenIcon = css`
  filter: invert(25%) sepia(11%) saturate(3021%) hue-rotate(71deg)
    brightness(99%) contrast(83%);
`;

const IconSize = css<{ size?: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const filterSelector = (color: string | undefined) => {
  switch (color) {
    case "blue":
      return blueIcon;
    case "white":
      return WhiteIcon;
    case "red":
      return RedIcon;
    case "green":
      return GreenIcon;
    case "dark-green":
      return DarkGreenIcon;
    default:
      return defalutIcon;
  }
};
export const Icon = styled.img<{
  color?: "blue" | "white" | "green" | "dark-green" | "default" | "red";
  size?: string;
  disabled?: boolean;
}>`
  filter: invert(64%) sepia(48%) saturate(6743%) hue-rotate(101deg)
    brightness(104%) contrast(101%);
  ${({ color }) => filterSelector(color)}
  ${({ size }) => size && IconSize}
  ${({ disabled, color }) => disabled && filterSelector(color)}
`;
