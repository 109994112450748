import axios, { AxiosInstance } from "axios";
import Cookies from "universal-cookie";
import { baseUrl, WEBAPPLINK, domainName, baseUrl_V2 } from "src/utils/config";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseUrl,
  // TODO this needs to be changed
  timeout: 10000000,
  headers: {
    contentType: "application/json",
    accept: "application/json",
  },
});

export const getSessionId = () => {
  try {
    let sessionId = localStorage.getItem("analytics");
    if (sessionId) {
      sessionId = JSON.parse(sessionId);
      return sessionId["x-session-id"];
    }
    return "";
  } catch (error) {
    return "";
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    if (!config.headers.token) {
      const token = cookies.get("JWToken", { domain: domainName });

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (!config.headers["x-session-id"])
      config.headers["x-session-id"] = getSessionId();

    if (config.baseURL === "v2") config.baseURL = baseUrl_V2;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx.
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx.

    if (
      error.response &&
      error.response.status === 401 &&
      window.location.href !== `${WEBAPPLINK}`
    ) {
      RemoveTokenAndRedirect();
    }

    return Promise.reject(error);
  },
);

const RemoveTokenAndRedirect = () => {
  const cookies = new Cookies();

  cookies.remove("JWToken", { domain: domainName });
  window.location.assign(`${WEBAPPLINK}`);
};

export { axiosInstance };
export default axiosInstance;
