import React, { CSSProperties } from "react";

import {
  Container,
  Form,
  Title,
  Small,
  Header,
  Button,
  Frame,
  Action,
  Wrapper,
  Picture,
  Timestamp,
  Profile,
  Name,
  Job,
  ProfileDetails,
  PictureIcon,
  PasswordHeader,
  Tag,
  TagTitle,
} from "./styles/settings";

import { Icon } from "../";

import picture from "../../assets/picture.svg";

import { UserOutlined } from "@ant-design/icons";
import {
  ButtonProps,
  Popconfirm,
  PopconfirmProps,
  Avatar,
  Dropdown,
} from "antd";
import moment from "moment";

import { ReactComponent as PrimeIcon } from "src/assets/prime.svg";
import { ReactComponent as EliteIcon } from "src/assets/elite.svg";

const Settings = function ContainerSettings({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
};

Settings.Form = function SettingForm({ children, ...rest }) {
  return <Form {...rest}>{children}</Form>;
};

Settings.Title = function SettingTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Settings.Small = function SettingSmallText({ children, ...rest }) {
  return <Small {...rest}>{children}</Small>;
};

Settings.Header = function SettingHeader({ children }) {
  return <Header>{children}</Header>;
};

Settings.Button = function SettingButton({ children, ...rest }: ButtonProps) {
  return <Button {...rest}>{children}</Button>;
};

Settings.Frame = function SettingFrame({
  children,
  ...rest
}: {
  children?: React.ReactNode;
  style?: CSSProperties;
}) {
  return <Frame {...rest}>{children}</Frame>;
};

Settings.Action = function SettingAction({
  children,
  ...rest
}: {
  children?: React.ReactNode;
}) {
  return <Action {...rest}>{children}</Action>;
};

Settings.Popconfirm = function SettingConfirm({
  children,
  ...rest
}: PopconfirmProps) {
  return <Popconfirm {...rest}>{children}</Popconfirm>;
};

Settings.Wrapper = function SettingWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Settings.Picture = function SettingPicture({ src, menu }) {
  return (
    <Picture>
      <Avatar shape="square" size={128} src={src} icon={<UserOutlined />} />
      {menu && (
        <PictureIcon>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Icon color="blue" src={picture} />
          </Dropdown>
        </PictureIcon>
      )}
    </Picture>
  );
};

Settings.Profile = function SettingsProfile({ children }) {
  return <Profile>{children}</Profile>;
};

Settings.Name = function SettingsName({ children, ...rest }) {
  return <Name {...rest}>{children}</Name>;
};

Settings.Tag = function SettingsName({ children, type, ...rest }) {
  return (
    <Tag {...rest}>
      {type === "elite" && <EliteIcon fill={"white"} width={18} height={18} />}
      {type === "prime" && <PrimeIcon fill={"white"} width={18} height={18} />}
      <TagTitle>{children}</TagTitle>
    </Tag>
  );
};

Settings.Job = function SettingsJob({ children, ...rest }) {
  return <Job {...rest}>{children}</Job>;
};

Settings.ProfileDetails = function SettingsProfileDetails({
  children,
  ...rest
}) {
  return <ProfileDetails {...rest}>{children}</ProfileDetails>;
};

const TIME_FORMAT = "YYYY-MM-DD h:mmA";

Settings.Timestamp = function SettingTimeStamp({ created, updated }) {
  return (
    <Timestamp>
      <span>account created : {moment(created).format(TIME_FORMAT)}</span>
      <span>last updated : {moment(updated).format(TIME_FORMAT)}</span>
    </Timestamp>
  );
};

Settings.PasswordHeader = function SettingsPasswordHeader({
  children,
  ...rest
}) {
  return <PasswordHeader {...rest}>{children}</PasswordHeader>;
};

export default Settings;
