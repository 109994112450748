import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootStateType } from "../rootReducer";
import { SelfMadeSmartfolio, BasketItem, Security } from "./goal";

const initialState: SelfMadeSmartfolio = {
  name: "",
  description: "",
  basket_items: [
    {
      weight: 1,
      ticker_id: 1,
      symbol: "CASH",
      name: "",
      maximum_bound_weight: 1,
      minimum_bound_weight: 0,
    },
  ],
};

const selfMadeSmartfolioSlice = createSlice({
  name: "selfMadeSmartfolio",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setBasket: (state, action: PayloadAction<BasketItem[]>) => {
      state.basket_items = state.basket_items
        .slice(0, 1)
        .concat(action.payload);

      state.basket_items[0].weight =
        1 - action.payload.reduce((acc, curr) => acc + curr.weight, 0);
    },
    addBasketItem: (state, action: PayloadAction<BasketItem | undefined>) => {
      const newItem = action.payload ?? {
        weight: 0,
        ticker_id: -1,
        maximum_bound_weight: 1,
        minimum_bound_weight: 0,
        symbol: "",
        name: ""
      };

      state.basket_items.push(newItem);
    },
    updateBasketItem: (
      state,
      action: PayloadAction<{
        index: number;
        attribute: string;
        value: number | string;
      }>,
    ) => {
      const { index, attribute } = action.payload;
      let newValue = action.payload.value;
      if (attribute === "weight") {
        if (+newValue < state.basket_items[index].minimum_bound_weight * 100) {
          // if weights < minimum_bound_weight then minimum_bound_weight = weights
          state.basket_items[index].minimum_bound_weight = +newValue / 100
        }
        if (+newValue > state.basket_items[index].maximum_bound_weight * 100) {
          // if weights > maximum_bound_weight then maximum_bound_weight = weights
          state.basket_items[index].maximum_bound_weight = +newValue / 100
        }
        let scaledCashWeight = state.basket_items[0].weight * 1000;
        scaledCashWeight += state.basket_items[index].weight * 1000 - Number(newValue) * 10;
        state.basket_items[0].weight = scaledCashWeight / 1000;
      }
      if (attribute.includes("weight"))
        newValue = +(newValue as number / 100).toFixed(2);
      state.basket_items[index][attribute] = newValue;
    },
    updateBasketInfo: (
      state,
      action: PayloadAction<{ index: number; security: Security }>,
    ) => {
      const { index, security } = action.payload;
      state.basket_items[index] = {
        ...state.basket_items[index],
        ticker_id: security.key,
        name: security.security,
        symbol: security.symbol,
      };
    },
    removeBasketItem: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index) {
        const scaledCashWeight: number = state.basket_items[0].weight * 1000 + +state.basket_items[index].weight * 1000;

        state.basket_items[0].weight = scaledCashWeight / 1000;
        state.basket_items.splice(index, 1);
      }
    },
  },
});

export const {
  setName,
  setDescription,
  addBasketItem,
  updateBasketItem,
  removeBasketItem,
  updateBasketInfo,
  setBasket,
  resetState
} = selfMadeSmartfolioSlice.actions;

export const selectBasket = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.SelfMadeSmartfolioReducer.basket_items,
);

export const selectName = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.SelfMadeSmartfolioReducer.name,
);

export const selectDescription = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.SelfMadeSmartfolioReducer.description,
);

export const selectSmartfolio = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.SelfMadeSmartfolioReducer,
);

export const selectCashWeight = createDraftSafeSelector(
  (state: RootStateType) => state,
  (state) => state.SelfMadeSmartfolioReducer.basket_items[0].weight,
);

export default selfMadeSmartfolioSlice.reducer;
