import { combineReducers } from "redux";

import CurrUser from "./Client";
import Investements from "./Investemets";
import Positions from "./Positions";
import Filters from "./TableFilters";
import Tickers from "./Tickers";
import Products from "./Products";
import Pagination from "./TablePagination";
import Smartfolios from "./SmartFolioFilter";
import NavSmartfolio from "./NavSmartfolio";
import menuReducer from "./Goal-Based/MenuSlice";
import marketReducer from "./Markets/MarketSlice";
import GoalAmountReducer from "./Goal-Based/GoalAmountSlice";
import ContributionAmountReducer from "./Goal-Based/ContributionAmountSlice";
import SelfMadeSmartfolioReducer from "./Goal-Based/SelfMadeSmartfolioSlice";
import SolveDurationReducer from "./Goal-Based/SolveDurationSlice";
import SolveInitialReducer from "./Goal-Based/SolveInitialSlice";
import AssetClassReducer from "./Goal-Based/AssetClassSlice";
import SelfMadeEditReducer from "./Goal-Based/EditSmartfolioSlice";
import { goalBasedApi } from "./Goal-Based/goalBasedApi";
import { v2Api } from "./middleware/api_v2";
import { marketApi } from "./Markets/marketApi";

const rootReducer = combineReducers({
  [marketApi.reducerPath]: marketApi.reducer,
  [goalBasedApi.reducerPath]: goalBasedApi.reducer,
  [v2Api.reducerPath]: v2Api.reducer,

  CurrUser,
  Investements,
  Positions,
  Tickers,
  Products,
  Filters,
  Pagination,
  Smartfolios,
  NavSmartfolio,
  menuReducer,
  GoalAmountReducer,
  ContributionAmountReducer,
  SolveInitialReducer,
  SolveDurationReducer,
  SelfMadeSmartfolioReducer,
  SelfMadeEditReducer,
  AssetClassReducer,
  marketReducer,
});

export type RootStateType = ReturnType<typeof rootReducer>;
export default rootReducer;
