import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { RootStateType } from "./rootReducer";
import { apiCallBegan } from "./ApiActions";
/*
 ** Notes:
 ** - client state object is of type ClinetDataRes (src/intefaces/Client.d.ts)
 ** - client is the current user.
 */

/*
 *******************************************************************************
 ** reducer
 */

const initialState = {
  data: [],
  isLoading: true,
};

const slice = createSlice({
  name: "navSmartfolio",
  initialState,
  reducers: {
    navSmartfolioRequest: (state) => {
      state.isLoading = true;
    },
    navSmartfolioResponse: (
      state,
      action,
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});
export const { navSmartfolioRequest, navSmartfolioResponse } =
  slice.actions;

/*
 *******************************************************************************
 ** action Creators
 */

const loadNavSmartfolio = (clientType, market) =>
  apiCallBegan({
    url: `/smartfolios?market=${market}&client_type=${clientType}`,
    onStart: navSmartfolioRequest.type,
    onSuccess: navSmartfolioResponse.type,
    // onFail: clientFailed.type,
  });

/*
 *******************************************************************************
 ** exports
 */

export { loadNavSmartfolio };
export default slice.reducer;
