import styled from 'styled-components/macro';
import { Alert as AntAlret } from 'antd';

export const ErrorMessage = styled(AntAlret)`
  &.ant-alert {
    width: 60%;
    margin-top: 5px;
    border-radius: 12px;

    .ant-alert-content {
      .ant-alert-message {
        color: rgb(228 0 0 / 85%);
      }
    }
  }
`;
