import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import api from "./middleware/api";
import { goalBasedApi } from "./Goal-Based/goalBasedApi";
import { v2Api } from "./middleware/api_v2";
import { marketApi } from "./Markets/marketApi";

// It's advised to use the getdefault middleware like this, soo keep it!
// const MiddlwaresArray = (() => getDefaultMiddleware().concat(api))();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(v2Api.middleware)
      .concat(goalBasedApi.middleware)
      .concat(marketApi.middleware),
});

export type AppDispatchType = typeof store.dispatch;

export default store;
