import React, { useState } from 'react';
import { Container, CollapsableItem, ReadMore, Title } from './style';

type CollapseItemProps = {
  title: string; //Enfourced later
  children?: React.ReactNode;
};

const CollapseItem: React.FC<CollapseItemProps> = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Container>
      <Title>{title}</Title>
      <CollapsableItem collapsed={collapsed}>{children}</CollapsableItem>
      <ReadMore onClick={() => setCollapsed((v) => !v)}>
        {collapsed ? '+Read more' : '-Read less'}
      </ReadMore>
    </Container>
  );
};

export default CollapseItem;
