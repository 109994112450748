import axios from "axios"
import React from "react"
import { baseUrl } from "src/utils/config"

export const useAnalytics = () => {
    React.useEffect(()=> {
        axios.get(`https://ipv4.icanhazip.com/`)
        .then(res => {
            const ip = res.data?.trim()
            return axios.get(`${baseUrl}/analytics`, {
                headers: {
                    'real-origin-ip': ip
                },
            })
        }).then(result => {
            localStorage.setItem('analytics', JSON.stringify(result?.data))
        })
    }, [])
}