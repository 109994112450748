import { Badge, Button, Space, Tag } from "antd";
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import { fixNumber, formatMoney } from "src/utils/number"
import { CloseCircleOutlined } from "@ant-design/icons"
import { orderStatus, orderType } from "./constants";
import Text from "antd/lib/typography/Text";

type OrdersColumnsFn = (
    onCancelOrder: { (record: any, id: number): void },
    cancelOrderLoading: boolean,
    selectedId: number,
) => ColumnsType<any>;

export const orderStatusColors = {
    EXECUTED: "#28a745",     // Green
    EXECUTING: "#ffc107",    // Yellow
    PRE_PENDING: "#007bff",  // Blue
    PENDING: "#007bff",      // Blue
    REJECTED: "#dc3545",     // Red
    EXPIRED: "#6c757d",      // Gray
    FAILED: "#dc3545",       // Red
    CANCELING: "#ffc107",    // Yellow
    CANCELED: "#dc3545",     // Red
    FILLED: "#28a745",       // Green
}

export const orderSideColors = {
    BUY: "#007bff",    // Blue
    SELL: "#F5B911",   // Orange
};

export const orderTypeColors = {
    MARKET: "blue",   // Blue
    LIMIT: "magenta",    // Pink
};

export const ordersColumns: OrdersColumnsFn = (
    onCancelOrder: { (record: any, id: number): void },
    cancelOrderLoading,
    selectedId: number
) => {

    const columns: ColumnsType<any> = [
        {
            title: 'Order',
            width: "80px",
            dataIndex: 'order_side',
            key: 'order_side',
            fixed: 'left',
            filters: [
                {
                    text: 'BUY',
                    value: 'BUY',
                },
                {
                    text: 'SELL',
                    value: 'SELL',
                },
            ],
            onFilter: (value: string, record) => record.order_side.indexOf(value) === 0,
            render: order_side => <Text style={{ color: orderSideColors[order_side] }}>{order_side}</Text>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Executed',
                    value: orderStatus.EXECUTED,
                },
                {
                    text: 'Executing',
                    value: orderStatus.EXECUTING,
                },
                {
                    text: 'Pending',
                    value: orderStatus.PENDING,
                },
                {
                    text: 'Rejected',
                    value: orderStatus.REJECTED,
                },
                {
                    text: 'Expired',
                    value: orderStatus.EXPIRED,
                },
                {
                    text: 'Canceled',
                    value: orderStatus.CANCELED,
                },
                {
                    text: 'Filled',
                    value: orderStatus.FILLED,
                },
            ],
            onFilter: (value: string, record) => record.status.indexOf(value) === 0,
            render: status => <Space size={0}><Badge color={orderStatusColors[status]} />{status}</Space>
        },
        {
            title: 'Order type',
            dataIndex: 'order_type',
            key: 'order_type',
            filters: [
                {
                    text: 'Market',
                    value: orderType.Market,
                },
                {
                    text: 'Limit',
                    value: orderType.Limit,
                },
            ],
            onFilter: (value: string, record) => record.order_type.indexOf(value) === 0,
            render: order_type => <Tag color={orderTypeColors[order_type]}>{order_type}</Tag>
        },
        {
            title: 'Order Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: date => moment(date).format('MMM DD YYYY HH:mm A z'),
            sorter: (a, b) => moment(a.created_at).isBefore(b.created_at) ? -1 : 1,
        },
        {
            title: 'Execution Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: date => moment(date).format('MMM DD YYYY HH:mm A z'),
            sorter: (a, b) => moment(a.updated_at).isBefore(b.updated_at) ? -1 : 1,
        },
        {
            title: 'Quantity',
            dataIndex: 'order_quantity',
            key: 'order_quantity',
            sorter: (a, b) => a.order_quantity - b.order_quantity,
        },
        {
            title: 'Price ($)',
            dataIndex: 'order_price',
            key: 'order_price',
            render: price => formatMoney(fixNumber(price, 3))
        },
        {
            title: 'Value ($)',
            dataIndex: 'order_value',
            key: 'order_value',
            render: price => formatMoney(fixNumber(price, 3))
        },
        {
            title: 'Fill Quantity',
            dataIndex: 'fill_quantity',
            key: 'fill_quantity'
        },
        {
            title: 'Fill Price ($)',
            dataIndex: 'fill_price',
            key: 'fill_price',
            render: price => formatMoney(fixNumber(price, 3))
        },
        {
            title: 'Fill Value ($)',
            dataIndex: 'fill_value',
            key: 'fill_value',
            render: price => formatMoney(fixNumber(price, 3))
        },
        {
            title: 'TIF',
            dataIndex: 'duration',
            key: 'duration',
            render: (date, record) => date ? moment(date).format('MMM DD YYYY HH:mm A z') : record.tif,
            sorter: (a, b) => moment(a.duration).isBefore(b.duration) ? -1 : 1,
        },
        {
            title: 'Action',
            width: "110px",
            key: 'operation',
            fixed: 'right',
            render: (record, _) => (
                <Button
                    loading={cancelOrderLoading && selectedId === record.id}
                    onClick={() => onCancelOrder(record, record.id)}
                    danger shape="round"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                    icon={<CloseCircleOutlined />}
                    disabled={![orderStatus.PENDING, orderStatus.PRE_PENDING, orderStatus.EXECUTING].includes(record.status)}
                >
                    Cancel
                </Button>),
        },
    ]

    return columns
};