import { RadioGroupProps, RadioProps } from "antd";
import React from "react";
import { Radio as Fradio } from "./styles/style";

interface FRadioProps extends RadioProps {
  name: string;
}

const Radio = ({ name, ...props }: FRadioProps) => {
  return <Fradio {...props}>{name}</Fradio>;
};

Radio.Group = ({ children, ...restProps }: RadioGroupProps) => {
  return <Fradio.Group {...restProps}>{children}</Fradio.Group>;
};

export default Radio;
