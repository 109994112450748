import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import "sanitize.css";
import "antd/dist/antd.css";
import "@ant-design/pro-layout/dist/layout.css";
import "@ant-design/pro-card/dist/card.css";
import "@ant-design/pro-table/dist/table.css";
import "./index.css";
import App from "./App";
import store from "src/store/store";
import AutoLogout from "./components/AutoLogout";
import StyleProvider from "./theme/StyleProvider";

import NiceModal from "@ebay/nice-modal-react";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <StyleProvider>
            {/* NiceModal to manage modals */}
            <NiceModal.Provider>
              <App />
            </NiceModal.Provider>
          </StyleProvider>
          <AutoLogout timeout={600} />
        </QueryClientProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);
