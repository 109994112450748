import React from "react";
import {
  StyledModal,
  StyledButton,
  ButtonContainer,
  IconWrapper,
  Title,
  SubTitle,
  Frame,
} from "./styles/confirm";

import { ModalProps } from "antd";
import { Icon } from "../";
import trashIcon from "../../assets/trash.svg";

interface IConfirmModal extends ModalProps {
  onDelete?: () => void;
  onCancel?: () => void;
  buttonLoading?: boolean;
}

const Confirm = ({
  buttonLoading,
  onDelete,
  onCancel,
  ...rest
}: IConfirmModal) => {
  return (
    <StyledModal
      maskStyle={{
        backdropFilter: "blur(10px)",
      }}
      centered
      closable={false}
      width={480}
      footer={[
        <ButtonContainer key="customAction">
          <StyledButton block danger onClick={onDelete} loading={buttonLoading}>
            Yes, Delete it
          </StyledButton>
          <StyledButton block type="primary" onClick={onCancel}>
            No, Cancel
          </StyledButton>
        </ButtonContainer>,
      ]}
      {...rest}
    >
      <Frame>
        <IconWrapper>
          <Icon src={trashIcon} color="red" />
        </IconWrapper>
        <Title>Delete this Bank Account?</Title>
        <SubTitle>
          {process.env.REACT_APP_BANK_NAME || ""} Capital will review this
          action, you can cancel it before that.
        </SubTitle>
      </Frame>
    </StyledModal>
  );
};

export default Confirm;
