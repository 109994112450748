import { TRouteItem } from "src/components/FinaLayout/types";
import MenuItem from "./Item";
import { useLocation } from "react-router";

const ItemGroup = ({
  title,
  items,
  borderTop,
  collapse,
}: {
  title?: string;
  items: Array<TRouteItem>;
  borderTop?: string;
  collapse: boolean;
}) => {
  const location = useLocation();
  return (
    <div style={{ borderTop: borderTop, paddingTop: "10px" }}>
      {!collapse && title && (
        <p style={{ color: "#000000", opacity: "0.5" }}>{title}</p>
      )}
      {items.map((item) => (
        <MenuItem
          to={item.path}
          content={item.name}
          key={item.key}
          icon={item.icon}
          isActive={location.pathname === item.path}
        />
      ))}
    </div>
  );
};
export { ItemGroup as default };
