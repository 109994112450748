import React, { useEffect } from "react";
import { WEBAPPLINK, domainName } from "src/utils/config";
import Cookies from "universal-cookie";

type Props = {
  timeout: number;
};

const AutoLogout: React.FC<Props> = ({ timeout }) => {
  const cookies = new Cookies();
  let interval = null;
  let timeouttrackr = null;

  const updateExpiredTime = () => {
    if (timeouttrackr != null) clearTimeout(timeouttrackr);

    timeouttrackr = setTimeout(() => {
      cookies.set("_expiredTime", `${Date.now() + timeout * 1000}`, {
        domain: domainName,
      });
    }, 300);
  };

  const tracker = () => {
    window.addEventListener("mousemove", updateExpiredTime);
    window.addEventListener("scroll", updateExpiredTime, { passive: true });
    window.addEventListener("keydown", updateExpiredTime);
  };

  const clean = () => {
    cookies.remove("_expiredTime", { domain: domainName });
    clearInterval(interval);
    window.removeEventListener("mousemove", updateExpiredTime);
    window.removeEventListener("scroll", updateExpiredTime);
    window.removeEventListener("keydown", updateExpiredTime);
  };

  const startInterval = () => {
    updateExpiredTime();

    interval = setInterval(() => {
      const expiredTime = parseInt(cookies.get("_expiredTime"), 10);

      // Timout
      if (expiredTime < Date.now()) logout();
    }, 1000);
  };

  const logout = () => {
    clean();
    if (cookies.get("JWToken")) {
      cookies.remove("JWToken", { domain: domainName });
      window.location.href = `${WEBAPPLINK}`;
    }
  };

  useEffect(() => {
    const expiredTime = parseInt(cookies.get("_expiredTime"), 10);
    // Expired
    if (expiredTime > 0 && expiredTime < Date.now()) logout();

    tracker();
    startInterval();

    return () => {
      clean();
    };
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default AutoLogout;
