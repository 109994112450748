import React from "react";
import { useParams } from "react-router";
import ReactPlayer from "react-player";
import { Col, Row, Typography } from "antd";
import Header from "./components/Header";
import {
  useGetPrivateSmartFolioVideoQuery,
  useGetSmartfolioDetailsByIdQuery,
} from "src/store/middleware/api_v2";
import {
  PageLayout
} from "../SmartfolioAnalytics/components";
import { Flex } from "src/components/Flex";
import styled from "styled-components";

const SmallTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: #565656;
  line-height: 20px;
`;

const BigTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  line-height: 22px;
`;

const PrivateSmartfolio = () => {
  const { id } = useParams<any>();
  const smartfolioInfo = useGetSmartfolioDetailsByIdQuery(id);
  const { data: smartfolioVideo, isLoading } =
    useGetPrivateSmartFolioVideoQuery({ id });

  const marketName =
    smartfolioInfo?.data?.classification_content?.markets[0]?.name;

  const capitalProtection = smartfolioInfo?.data?.classification_content?.capital_protection[0].name;
  const underlyingAsset = smartfolioInfo?.data?.classification_content?.underlying_asset[0].name;
  const issuers = smartfolioInfo?.data?.classification_content?.issuer_rating[0].name;
  const payouts = smartfolioInfo?.data?.classification_content?.payout_structure[0].name;
  const maturities = smartfolioInfo?.data?.classification_content?.maturities[0].name;
  const shariaas = smartfolioInfo?.data?.classification_content?.shariaa_compliances[0].name;
  
  return (
    <PageLayout
      title=""
      isLoading={smartfolioInfo?.isLoading}
      isError={smartfolioInfo?.isError}
    >
      <Header
        description=""
        title={smartfolioInfo?.data?.display_name}
        id={smartfolioInfo?.data?.id?.toString()}
        smartfolioMarket={marketName}
      />
      <Row>
        <Col span={24}>
          <Flex>
            <div
              style={{
                height: "82px",
                background: "#F7F9FB",
                width: "100%",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between",
                padding: "18px",
              }}
            >
              <div>
                <SmallTitle>Capital Protection</SmallTitle>
                <BigTitle>{capitalProtection}</BigTitle>
              </div>
              <div>
                <SmallTitle>Underlying Asset</SmallTitle>
                <BigTitle>{underlyingAsset}</BigTitle>
              </div>
              <div>
                <SmallTitle>Issuer Rating</SmallTitle>
                <BigTitle>{issuers}</BigTitle>
              </div>
              <div>
                <SmallTitle>Payout Structure</SmallTitle>
                <BigTitle>{payouts}</BigTitle>
              </div>
              <div>
                <SmallTitle>Maturity</SmallTitle>
                <BigTitle>{maturities}</BigTitle>
              </div>
              <div>
                <SmallTitle>Shariaa Compliances</SmallTitle>
                <BigTitle>{shariaas}</BigTitle>
              </div>
            </div>
          </Flex>
        </Col>
        <Col span={24}>
          <Flex>
            <div style={{ marginTop: "22px", width: "100%" }}>
              {!isLoading && (
                <ReactPlayer
                  url={smartfolioVideo?.url}
                  height="658px"
                  width="100%"
                />
              )}
            </div>
          </Flex>
        </Col>
      </Row>
    </PageLayout>
  );
};
export { PrivateSmartfolio as default };
