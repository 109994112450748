import React from "react";
import { Alert, Icon, Message } from "./styles/banner";

import alert from "src/assets/alert.svg";

const Banner = ({ children, ...restProps }) => {
  return (
    <Alert {...restProps}>
      <Icon src={alert} />
      <Message>{children}</Message>
    </Alert>
  );
};

export default Banner;
