import AxiosInstance from "src/utils/axiosInstance";

import { errorWrapper } from "src/action/utilities";

export const getBanks = async () => {
  try {
    const res = await AxiosInstance.get("/bankaccounts");
    return { data: res.data, status: res.status, success: true };
  } catch ({ response }) {
    const data = response?.data;
    const message = data?.message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};

export const Adddeposit = async (values: any) => {
  try {
    const res = await AxiosInstance.post("/deposit", values);
    return { data: res.data, status: res.status, success: true };
  } catch ({ data }) {
    const message = data?.message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};

export const addBankAccount = async (values: any) => {
  try {
    const res = await AxiosInstance.post("/bankAccount/add", values);
    return { data: res.data, status: res.status, success: true };
  } catch ({ response }) {
    const data = response?.data;
    let message = typeof data === 'string' ? data : data?.message;
    if (data && data.code === 11000) {
      message = "Bank account already exists";
    }
    message = message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};

/**  for leanProject **/

export const getcustomerDetails = async (values: any) => {
  try {
    // get customer
    const res = await AxiosInstance.get(
      `/payment/customer/?provider=${values?.provider}&customerId=${values?.customerId}`,
    );
    return { data: res.data, status: res.status, success: true };
  } catch ({ error }) {
    return errorWrapper(error);
  }
};

export const creatCustomers = async (values: any) => {
  try {
    const res = await AxiosInstance.post("/payment/customer", values);
    return { data: res.data, status: res.status, success: true };
  } catch ({ error }) {
    return errorWrapper(error);
  }
};

export const getPaymentDestination = async (values: any) => {
  try {
    // get customer
    const res = await AxiosInstance.get(
      `/payment/destinations/?provider=${values?.provider}`,
    );
    return { data: res.data, status: res.status, success: true };
  } catch ({ error }) {
    return errorWrapper(error);
  }
};

export const creatPaymentIntent = async (values: any) => {
  return AxiosInstance.post("/payment/intents", values)
        .then(res => {
          return { data: res.data, status: res.status, success: true };
        })
        .catch(err => {
          return errorWrapper(err);
        })
};

export const getLeanBanks = async (values?: any) => {
  try {
    const res = await AxiosInstance.get(
      `/payment/banks/?provider=${values?.provider || "leantech"}`,
    );
    return res.data;
  } catch ({ error }) {
    return errorWrapper(error);
  }
};
