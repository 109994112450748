import { InputNumber, Input, Select as antdSelect } from "antd";
import { theme } from "src/theme/theme";
import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";

export const Head = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr) 60px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr) 60px;
  align-items: center;
  gap: 16px;
`;

export const InputText = styled(Input)`
  border-radius: 5px;
  border: 1px solid ${theme.colors.primary[500]};
  background: ${theme.colors.primary[10]};
  &:hover {
    border: 1px solid ${theme.colors.primary[500]};
  }
  &:focus {
    border: 1px solid ${theme.colors.primary[500]} !important;
  }
`;

export const InputTextArea = styled(TextArea)`
  border-radius: 5px;
  border: 1px solid ${theme.colors.primary[500]};
  background: ${theme.colors.primary[10]};
  &:hover {
    border: 1px solid ${theme.colors.primary[500]};
  }
  &:focus {
    border: 1px solid ${theme.colors.primary[500]} !important;
  }
`;

export const InputNum = styled(InputNumber)`
  border-radius: 5px;
  border: 1px solid ${theme.colors.primary[500]};
  background: ${theme.colors.primary[10]};
  padding: 4px 0;
  color: ${theme.colors.primary[500]};
  &:hover {
    border: 1px solid ${theme.colors.primary[500]};
  }
  &:focus {
    border: 1px solid ${theme.colors.primary[500]} !important;
  }
`;

export const Select = styled(antdSelect)`
  & .ant-select-selector {
    border-radius: 5px !important;
    border: 1px solid ${theme.colors.primary[500]} !important;
    background: ${theme.colors.primary[10]};
    color: ${theme.colors.primary[500]};
    height: 40px !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;
