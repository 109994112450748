import React from "react";
import { Row, Col, InputNumberProps, InputProps } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import {
  FormContainer,
  FormElement,
  Label,
  InputNumber,
  Wrapper,
  Text,
  Input,
  Action,
  ButtonContainer,
  TextArea,
  Password,
  PasswordRules,
  Rule,
} from "./styles/form";

import {
  lowercaseRegex,
  numberRegex,
  specialCharRegex,
  uppercaseRegex,
} from "src/utils/regex";
import { theme } from "src/theme/theme";

interface FormProps {
  total?: string | number;
  amount?: string | number;
  newTotal?: string | number;
  children?: React.ReactNode;
}

const Form = ({ total, amount, newTotal, children, ...rest }: FormProps) => {
  return (
    <FormContainer {...rest}>
      <Row>
        <Col span={24}>
          <Wrapper>{children}</Wrapper>
        </Col>
      </Row>
    </FormContainer>
  );
};

interface InvestInputNumberProps extends InputNumberProps {
  name?: string;
  label?: React.ReactNode;
  error?: string;
}

Form.InputNumber = function FormInputNumber({
  onChange,
  defaultValue,
  label,
  formatter,
  parser,
  name,
  error,
  id,
  color,
  ...rest
}: InvestInputNumberProps) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Label>
      <InputNumber
        id={id}
        name={name}
        defaultValue={defaultValue}
        size="large"
        formatter={formatter}
        parser={parser}
        onChange={onChange}
        color={color}
        {...rest}
      />
    </>
  );
};

interface InputFormProps extends InputProps {
  name?: string;
  error?: string;
  label?: React.ReactNode;
  showRules?: boolean;
}

interface PasswordInputFormProps extends InputFormProps {
  value?: string;
}

Form.Input = function FormInput({
  label,
  id,
  error,
  onChange,
  ...rest
}: InputFormProps) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
      </Label>
      <Input size="large" onChange={onChange} {...rest} />
      {error && <Text type="danger">{error}</Text>}
    </>
  );
};

Form.Password = function FormPassword({
  label,
  id,
  error,
  value = "",
  onChange,
  showRules,
  ...rest
}: PasswordInputFormProps) {
  const passwordRules = [
    {
      id: 0,
      name: "8 characters minimum",
      isValid: value.length >= 8,
    },
    {
      id: 1,
      name: "1 lowercase character",
      isValid: new RegExp(lowercaseRegex).test(value),
    },
    {
      id: 2,
      name: "1 special character",
      isValid: new RegExp(specialCharRegex).test(value),
    },
    {
      id: 3,
      name: "1 number",
      isValid: new RegExp(numberRegex).test(value),
    },
    {
      id: 4,
      name: "1 uppercase character",
      isValid: new RegExp(uppercaseRegex).test(value),
    },
  ];

  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
      </Label>
      <Password
        iconRender={(visible) =>
          visible ? (
            <EyeOutlined style={{ opacity: 0.8, color: theme.colors.primary["500"] }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: theme.colors.primary["500"] }} />
          )
        }
        size="large"
        onChange={onChange}
        value={value}
        {...rest}
      />
      {showRules && (
        <PasswordRules>
          {passwordRules.map((rule) => (
            <Rule
              isVisible={!!value}
              key={rule.id}
              startValidating={true}
              isValid={rule.isValid}
            >
              {rule.name}
            </Rule>
          ))}
        </PasswordRules>
      )}
    </>
  );
};

Form.Element = function InputFormElement({ children, ...rest }) {
  return <FormElement {...rest}>{children}</FormElement>;
};

Form.Action = function FormAction({ children, ...rest }) {
  return <Action {...rest}>{children}</Action>;
};

Form.ButtonContainer = function FormButton({ children, ...rest }) {
  return <ButtonContainer {...rest}>{children}</ButtonContainer>;
};

Form.TextArea = function FormTextArea({ label, id, error, onChange, ...rest }) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
      </Label>
      <TextArea
        onChange={onChange}
        autoSize={{ minRows: 3, maxRows: 5 }}
        {...rest}
      />
    </>
  );
};

interface LabelProps {
  label?: string;
  id?: string;
  error?: string;
  children?: React.ReactNode;
}

Form.Label = function FormLabel({ children, label, id, error }: LabelProps) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
      </Label>
      {children}
    </>
  );
};

export default Form;
