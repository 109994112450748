import styled from "styled-components/macro";
import { typography } from "src/utils/styledComponents/typography";

export const Container = styled.div`
  padding: 1em 0px;
`;

export const CollapsableItem = styled.div<{ collapsed: boolean }>`
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => (props.collapsed ? "1.8em" : "1000px")};
  transition: max-height 0.2 ease-in-out;
`;

export const ReadMore = styled.span`
  color: #2478f6;
  cursor: pointer;
`;

export const Title = styled.span`
  ${typography.CustomText("20px")}
  font-weight: 600;
`;
