import { InputNumberProps, InputProps } from "antd";
import React from "react";
import { useTheme } from "styled-components";
import {
  Label,
  Input as FInput,
  Text,
  InputNumber,
  Optional,
} from "./styles/style";

interface FInputProps extends InputProps {
  id: string;
  error: string;
  label: React.ReactNode;
  onChange?: (e) => void;
  optional?: boolean;
}

const Input = ({
  id,
  error,
  label,
  optional,
  onChange,
  ...rest
}: FInputProps) => {
  const theme = useTheme();
  return (
    <>
      <Label htmlFor={id}>
        <Text>
          {label} {optional && <Optional>(optional)</Optional>}
        </Text>
      </Label>
      <FInput
        type={error ? "danger" : null}
        size="large"
        onChange={onChange}
        {...rest}
        color={theme.colors.primary}
      />
      {error && <Text type="danger">{error}</Text>}
    </>
  );
};
interface FInputNumberProps extends InputNumberProps {
  name: string;
  label?: string;
  error?: string;
}

Input.InputNumber = function DepositInputNumber({
  onChange,
  defaultValue,
  label,
  formatter,
  parser,
  name,
  error,
  id,
  ...rest
}: FInputNumberProps) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Label>
      <InputNumber
        id={id}
        name={name}
        defaultValue={defaultValue}
        size="large"
        formatter={formatter}
        parser={parser}
        error={error}
        onChange={onChange}
        {...rest}
      />
    </>
  );
};

export default Input;
