import styled from "styled-components/macro";
import { Button as Btn } from "antd";
import { customScrollbar } from "src/utils/styledComponents/scroll";
import { colors } from "src/theme/colors";

export const Container = styled.div`
  padding: 16px;
`;

export const Inner = styled.div<{ bgColor?: "white" | "grey" }>`
  height: 483px;
  padding: 40px;
  background-color: ${({ bgColor }) =>
    bgColor === "white" ? "white" : "#f7f9fb"};
  border-radius: 15px;
  overflow-y: auto;
  ${customScrollbar};
`;

export const Button = styled(Btn)`
  &.ant-btn {
    &.ant-btn-lg {
      font-weight: 600;
      height: 48px;
      padding: 6.4px 32px;
    }

    &.ant-btn-primary {
      background-color: ${colors.primary[500]};
      border-color: ${colors.primary[500]};
      border-radius: 5px;

      &[disabled] {
        background-color: #e8f1ff;
        color: ${colors.primary[500]};
        opacity: 0.5;
      }

      &:active {
        border-color: ${colors.primary[500]};
      }
    }
  }
`;

export const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const List = styled.ul`
  padding: 0 0 0 1rem;
`;
