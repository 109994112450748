import React from "react";

import {
  Row,
  Col,
  InputNumberProps,
  // InputProps,
  RowProps,
  ColProps,
} from "antd";

import {
  Container,
  FormContainer,
  InputNumber,
  Label,
  Wrapper,
  Text,
  FormElement,
  Input,
  Action,
  ButtonContainer,
  Modal,
  Small,
  NewBankFormContainer,
  Header,
  ModalWrapper,
  Row as CustomRow,
  Col as CustomCol,
  ErrorMessage,
  NewBankButton,
} from "./styles/deposit";

const Deposit = ({ children }) => {
  return <Container>{children}</Container>;
};

Deposit.Input = function DepositInput({ label, id, error, onChange, ...rest }) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
      </Label>
      <Input size="large" onChange={onChange} {...rest} />
      {error && <Text type="danger">{error}</Text>}
    </>
  );
};

interface InvestInputNumberProps extends InputNumberProps {
  name: string;
  label?: string;
  error?: string;
}

Deposit.InputNumber = function DepositInputNumber({
  onChange,
  defaultValue,
  label,
  formatter,
  parser,
  name,
  error,
  id,
  ...rest
}: InvestInputNumberProps) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Label>
      <InputNumber
        id={id}
        name={name}
        defaultValue={defaultValue}
        size="large"
        formatter={formatter}
        parser={parser}
        onChange={onChange}
        {...rest}
      />
    </>
  );
};

Deposit.Element = function DepositElement({ children }) {
  return <FormElement>{children}</FormElement>;
};

Deposit.Form = function DepositForm({ children }) {
  return (
    <FormContainer>
      <Row>
        <Col span={24}>
          <Wrapper>{children}</Wrapper>
        </Col>
      </Row>
    </FormContainer>
  );
};

Deposit.Action = function DepositAction({ children, ...rest }) {
  return <Action {...rest}>{children}</Action>;
};

Deposit.ButtonContainer = function DepositButton({ children, ...rest }) {
  return <ButtonContainer {...rest}>{children}</ButtonContainer>;
};

Deposit.Modal = function DepositModal({
  isModalVisible,
  handleOk,
  handleCancel,
  children,
  footer,
  title,

  ...rest
}) {
  return (
    <Modal
      maskStyle={{
        backdropFilter: "blur(10px)",
      }}
      title={<Header>{title}</Header>}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"70%"}
      footer={footer}
      {...rest}
    >
      {children}
    </Modal>
  );
};

Deposit.NewBank = function DepositNewBank({ error, children, ...rest }) {
  return (
    <NewBankFormContainer {...rest}>
      <Small>
        Kindly add your personal bank account information to receive your future
        withdrawals
      </Small>
      {error && (
        <ErrorMessage
          message="Error"
          description={error}
          type="error"
          showDeposit Summary

        />
      )}
      <ModalWrapper>{children}</ModalWrapper>
    </NewBankFormContainer>
  );
};

Deposit.Col = function DepositCol({ children, ...rest }: ColProps) {
  return <CustomCol {...rest}>{children}</CustomCol>;
};

Deposit.Row = function DepositRow({ children, ...rest }: RowProps) {
  return <CustomRow {...rest}>{children}</CustomRow>;
};

Deposit.AddBankButton = function AddBankButton({ onClick, children }) {
  return <NewBankButton onClick={onClick}>{children}</NewBankButton>;
};

export default Deposit;
