import React from "react";
import { Radio } from "antd";
import { StyledRadioGroup, FrequencyInMonths } from "./Frequency";
import { StyledSubtitle } from "./SliderTitle";
import { UpdateFieldProps } from "src/store/Goal-Based/goal";
import { useDispatch } from "react-redux";

const { Button } = Radio;

export enum Fields {
  period = "period",
  startDate = "start_date",
  endDate = "end_date",
  contributionType = "contribution_type",
  initialAmount = "initial_amount",
  contributionAmount = "contribution_amount",
  goalAmount = "goal_amount",
}

export function FrequencyRadioField<T>(props: UpdateFieldProps<T>) {
  const dispatch = useDispatch();
  const { updateField, value } = props;
  return (
    <>
      <StyledSubtitle>With A Regular Frequency:</StyledSubtitle>
      <StyledRadioGroup
        value={value}
        name={Fields.contributionType}
        onChange={(e: any) =>
          dispatch(
            updateField({
              name: Fields.contributionType,
              value: e.target.value,
            }),
          )
        }
      >
        <Button value={FrequencyInMonths.Monthly}>Monthly</Button>
        <Button value={FrequencyInMonths.Quarterly}>Quarterly</Button>
        <Button value={FrequencyInMonths.SemiAnnually}>Semi-Annually</Button>
        <Button value={FrequencyInMonths.Annually}>Annually</Button>
      </StyledRadioGroup>
    </>
  );
}
