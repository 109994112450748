import styled from "styled-components";
import { Typography } from "antd";
import { theme } from "src/theme/theme";

export const typography = {
  h1Text: () => `
    font-family: Merriweather;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.5;
  `,

  h2Text: () => `
    font-size: 1.5rem;
    font-family: Merriweather;
    line-height: 1.5;
  `,

  h2Link: () => `
    font-family: proxima-Nova;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 25px;
    color: #1967fa;
    text-decoration: underline;
    line-height: 1.5;

  `,
  ParagraphTextsm: () => `
  color: black;
  font-family: proxima-Nova;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;

  `,
  ParagraphText: () => `
  color: black;
  font-family: proxima-Nova;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  `,
  ParagraphTextlg: () => `
  color: black;
  font-family: proxima-Nova;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.5;

  `,
  CustomText: (size: any) => `
  font-family: proxima-Nova;
  font-size: ${size};

  `,
  CustomMeriText: (size: any) => `
  font-family: Merriweather;
  font-size: ${size};

  `,
};

// NOTE: this is text we use as label in the invest page.
export const Text = styled(Typography.Text)`
  &.ant-typography {
    font-size: 12px;
    opacity: 0.8;
    .ant-typography-danger {
      color: red !important;
    }
    color: ${({ type }) => !type && theme.colors.primary["600"]};
  }
`;
