import { Pie } from "@ant-design/charts";
import { FC } from "react";
import { theme } from "src/theme/theme";

interface IPieChart {
  data: { key: string; value: number }[];
  legend?: boolean;
}

export const PieColors = [
  "#39B9E9",
  "#545C94",
  "#ED4782",
  "#B376D5",
  "#F5B911",
  "#F29811",
  "#ffb400",
  "#d2980d",
  "#a57c1b",
  "#786028",
  "#363445",
  "#48446e",
  "#5e569b",
  "#776bcd",
  "#9080ff",
  "#4297A0",
  "#4297A0",
  "#F4EAE6",
  "#E57F84",
  "#2F5061",
  "#90ADC6",
  "#E9EAEC",
  "#FAD02C",
  "#333652",
  "#D2FBA4",
  "#F1C0B9",
  "#88CA5E",
  "#1D741B",
  "#05E0E9",
  "#FF2768",
  "#CFEED1",
  "#4E1A3D",
  "#F9F1F0",
  "#663635",
  "#E1999F",
  "#DEB3AD",
  "#54086B",
  "#FF0BAC",
  "#00BEC5",
  "#050833",
  "#E9E6E2",
  "#B5A4A3",
  "#C1C2AD",
  "#535E4B",
  "#0292B7",
  "#1AC8DB",
  "#8C756A",
  "#DEE2EC",
  "#005437",
  "#DEBAD6",
  "#340744",
  "#741AAC",
];

export const PieChart: FC<IPieChart> = ({ data, legend = true }) => {
  const config = {
    legend: legend,
    outerWidth: 200,
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "key",
    radius: 1,
    innerRadius: 0.6,
    label: {
      content: null,
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: null,
      content: null,
    },
    // theme: {
    //   colors10: PieColors,
    //   colors20: PieColors,
    // },
  };

  return <Pie {...(config as any)} color={theme.pieColors} />;
};
