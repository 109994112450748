import styled from "styled-components";
import {
  Typography,
  Input as AntInput,
  InputNumber as AntInputNumber,
} from "antd";
import { typography } from "src/utils/styledComponents/typography";
import { colors } from "src/theme/colors";
import { theme } from "src/theme/theme";

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  color: ${colors.primary["500"]};
`;

export const Text = styled(Typography.Text)`
  &.ant-typography {
    color: ${(props) =>
      props.type !== "danger" ? colors.primary["500"] : null};
    opacity: 0.8;
    font-size: 12px;
  }
`;

export const Optional = styled.span`
  color: #00000080;
  opacity: 0.8;
  ${typography.CustomText("0.75rem")}
  font-weight: 500;
`;

export const Input = styled(AntInput)`
  &.ant-input {
    border-radius: 5px;

    border: 1px solid
      ${(props) => (props.type !== "danger" ? props.color["500"] : "#dd093c")} !important;
    padding: 8px 16px;
    box-shadow: none !important;
  }
  &.ant-input[disabled] {
    color: #000;
    background-color: #f7f9fb;
    cursor: unset;

    &:hover {
      border: 1px solid
        ${(props) => (props.type !== "danger" ? props.color["500"] : "#dd093c")} !important;
    }
  }

  &.ant-input-affix-wrapper {
    border-radius: 5px;
    border: 1px solid ${colors.primary["500"]} !important;
    padding: 8px 16px;
  }
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    width: 100%;
    border-radius: 5px;
    border: 1px solid
      ${({error}) => (error ? theme.colors.error[500] : colors.primary["500"])} !important;
  }
`;
