const LogOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="15"
      viewBox="0 0 10 15"
    >
      <path
        fillRule="evenodd"
        d="M0 1.412A1.388 1.388 0 011.398 0H8.5a1.381 1.381 0 01.998.41 1.384 1.384 0 01.4 1.002v11.291A1.387 1.387 0 018.5 14.115H1.398a1.382 1.382 0 01-.998-.41 1.383 1.383 0 01-.4-1.002V1.412zm8.5 11.291H4.2l1.3-.579a1.48 1.48 0 00.898-1.3V3.936A1.38 1.38 0 005.6 2.71l-2.202-1.3H8.5v11.293zM1.398 9.245V1.922l3.5 2.017v6.888l-3.5 1.512V9.245zM3.5 6.352a.708.708 0 00-.658.432.706.706 0 00.15.771.706.706 0 001.156-.767.703.703 0 00-.648-.436z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
export { LogOutIcon as default };
