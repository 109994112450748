import React from "react";
import { Primary, ButtonContainer } from "./styles";
import ButtonContent from "./ButtonContent";

const Button = (props) => {
  const { className, width, outline, margin, ...leftoverProps } = props;

  const buttonProps = { className, ...leftoverProps };
  return (
    <ButtonContainer outline={outline} width={width} $margin={margin}>
      <Primary {...buttonProps}>
        <ButtonContent {...leftoverProps} />
      </Primary>
    </ButtonContainer>
  );
};

export { Button };
export default Button;
