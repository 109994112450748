import React from "react";
import {
  Container,
  Wrapper,
  Title,
  SubTitle,
  Text,
  SubText,
  RadioContainer,
  FormItem,
  AddButton,
} from "./styles/style";

type FDepositProps = {
  children: React.ReactNode;
  title: string;
  leanWrapper?: boolean;
};

const FDeposit = ({ title, children, ...restProps }: FDepositProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Wrapper {...restProps}>{children}</Wrapper>
    </Container>
  );
};

FDeposit.Text = function DepositText({ children }) {
  return <Text>{children}</Text>;
};

FDeposit.Title = function DepositTitle({ children }) {
  return <SubTitle>{children}</SubTitle>;
};

FDeposit.SubText = function DepositSubText({ children, ...rest }) {
  return <SubText>{children}</SubText>;
};

FDeposit.RadioContainer = function DepositRadioContainer({
  children,
  ...rest
}) {
  return <RadioContainer {...rest}>{children}</RadioContainer>;
};

FDeposit.FormItem = function DepositFormItem({ children, ...rest }) {
  return <FormItem>{children}</FormItem>;
};

FDeposit.AddLink = function DepositAddLink({ children, ...rest }) {
  return <AddButton{...rest}>{children}</AddButton>;
};

export default FDeposit;
