import { PMCommissionCoefficient } from "./constants/comissions";

export function formatMoney(amount: number): string {
  return !amount
    ? "0.00"
    : amount.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

export function calculateFee(amount) {
  const fee = amount * (PMCommissionCoefficient - 1);
  return Number(Math.round((fee + Number.EPSILON) * 100) / 100).toFixed(2);
}

export function formatAmount(amount: number): string {
  return !Math.ceil(amount)
    ? "0"
    : (amount >= 0 ? Math.floor(amount) : Math.ceil(amount)).toLocaleString(
        "en",
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      );
}

export function fixNumber(number: number, precision = 2) {
  return number ? Number(Number(number).toFixed(precision)) : 0;
}

export function formatPrecisionNumber(number: number, precision: number) {
  return fixNumber(number / 10 ** precision);
}

// calculate the total amount + % of TP or SL
export function calculeTPSL(amount: any, TP_SL: any) {
  return fixNumber(amount * (1 + TP_SL / 100));
}

export function calcRendement(nav: any, initialNav: any) {
  const ratio = nav / initialNav;
  const rendement = ratio - 1;
  return fixNumber(rendement * 100);
}

export const calcCurrentNav = (investment: any) =>
  investment.nav ? Number(investment.nav).toFixed(2) : "-";

export const calcPercentage = (percent: number, total: number): number => {
  return (percent / 100) * total;
};
