import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InputNumber as AntInputNumber,
  Typography,
  Input as AntInput,
  Modal as AntModal,
  Row as AntRow,
  Col as AntCol,
  Alert as AntAlret,
} from "antd";
import { typography } from "../../../utils/styledComponents/typography";
import { IPAD, LARGE_SCREEN } from "../../../utils/constants/media";
import { colors } from "src/theme/colors";
import { theme } from "src/theme/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 57%;
  ${typography.CustomText("1em")}
  align-items: center;

  @media (max-width: ${LARGE_SCREEN}px) {
    width: 90%;
  }
`;

export const IconContainer = styled.span`
  background-color: #e8f1ff;
  padding: 3px 5px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DollarSign = styled(FontAwesomeIcon)<{ color?: string }>`
  font-size: 14px;
  color: ${({ color }) => (color ? color : "#000")};
`;

export const FormContainer = styled.div`
  margin-top: 5rem;
  font-size: 0.75rem;
  width: 70%;
  @media (max-width: ${IPAD}px) {
    width: 100%;
  }
`;

export const FormElement = styled.div`
  margin: 2em 0;
  position: relative;
`;

export const Amount = styled.span<{ color?: string }>`
  color: ${({ color }) => (color ? color : "#000")};
  font-weight: bold;
  font-size: 0.938rem;
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]} !important;
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  color: "#0D4BA8";
`;

export const ColContainer = styled.div<{ justify?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  gap: 8px;
  align-items: center;

  @media (max-width: ${IPAD}px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  margin-top: 3rem;
`;

export const Text = styled(Typography.Text)`
  &.ant-typography {
    color: ${colors.primary["500"]};
    opacity: 0.8;
    font-size: 12px;
  }
`;

export const Input = styled(AntInput)`
  &.ant-input {
    border-radius: 5px;
    border: 1px solid ${theme.colors.primary["500"]} !important;
    padding: 8px 16px;
  }

  &.ant-input[disabled] {
    color: #000;
    background-color: ${colors.primary["25"]};
    cursor: unset;

    &:hover {
      border: 1px solid #2178ff80;
    }
  }

  &.ant-input-affix-wrapper {
    border-radius: 5px;
    border: 1px solid ${colors.primary[500]} !important;
    padding: 8px 16px;
  }
`;

export const Action = styled.div<{ position?: string }>`
  margin: 5em 0;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => position || "space-between"};
  gap: 1em;

  button {
    padding: 20px 0;
    font-size: 1rem;
  }

  @media (max-width: ${IPAD}px) {
    flex-direction: column-reverse;
    width: 100% !important;
    button {
      width: 100%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Modal = styled(AntModal)`
  ${Action} {
    width: 50%;
  }
  &.ant-modal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0;
      padding-bottom: 3em;
      display: flex;
      justify-content: center;

      ${Action} {
        margin: 0;
      }
    }
    ${typography.CustomText("1em")}
    .ant-modal-body {
      padding-top: 0px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 12px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-close-x {
      .anticon {
        background: #dc3545;
        border-radius: 50%;
        padding: 4px;
        color: white;
      }
    }
  }

  @media (max-width: ${IPAD}px) {
    flex-wrap: wrap;
    width: 100% !important;

    &.ant-modal {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  margin-top: 1em;
  font-weight: 700;
  color: #0d4ba8;
  ${typography.CustomMeriText("1.5em")}
`;

export const Small = styled.span`
  font-size: 1em;
  opacity: 0.5;
  text-align: center;
`;

export const NewBankFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typography.CustomText("1em")}
  align-items: center;
`;

export const ModalWrapper = styled.div`
  margin-top: 6rem;
  width: 80%;
`;

export const Row = styled(AntRow)`
  &.ant-row {
    margin-bottom: 2rem;
  }
`;
export const Col = styled(AntCol)``;

export const ErrorMessage = styled(AntAlret)`
  &.ant-alert {
    width: 100%;
    border-radius: 12px;

    .ant-alert-content {
      .ant-alert-message {
        color: rgb(228 0 0 / 85%);
      }
    }
  }
`;

export const NewBankButton = styled.div`
  background-color: #e8f1ff;
  color: ${colors.primary["500"]};
  border: 2px solid ${colors.primary["500"]};
  padding: 7px 30px;
  position: absolute;
  top: 21px;
  right: -172px;
  text-align: center;
  border-radius: 5px;
  vertical-align: middle;
  text-transform: capitalize;
  cursor: pointer;

  @media (max-width: ${IPAD}px) {
    top: 69px;
    right: 0;
    left: 0;
  }
`;

export const MoneyIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: ${theme.colors.primary["500"]};
  align-items: center;

  span {
    font-weight: normal;
    margin-top: -5px;
  }
`;

export const MoneyIcon = styled.img`
  position: absolute;
  top: -32px;
  right: 15%;
`;
