import { Flex } from "src/components/Flex";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  display: inline-grid;
  background-color: ${theme.colors.primary[10]};
  border-radius: 8px;
  overflow: hidden;
  padding: 0 1rem;

  & .ant-tabs-nav {
    all: unset;
  }

  & .ant-tabs-nav-operations,
  & .ant-tabs-content-holder,
  & .ant-tabs-ink-bar,
  & .ant-tabs-nav::before {
    display: none !important;
  }
`;
