import { ReactNode } from "react";
import { StyledItemLink } from "./FinaSidebar.style";

const MenuItem = ({
  content,
  icon,
  isActive,
  to,
}: {
  content: string;
  icon: ReactNode;
  isActive?: boolean;
  to: string;
}) => {
  return (
    <StyledItemLink isActive={isActive} to={to}>
      {icon}
      <span>{content}</span>
    </StyledItemLink>
  );
};
export { MenuItem as default };
