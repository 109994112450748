import { typography } from "src/utils/styledComponents/typography";
import styled from "styled-components/macro";
import { Button as AntButton } from "antd";
import { theme } from "src/theme/theme";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Title = styled.h2`
  color: ${theme.colors.primary[500]};
  ${typography.CustomMeriText("1.5")}
  font-weight: bold;
`;

export const SubTitle = styled.h3`
  color: #000;
  ${typography.CustomMeriText("1.5")}
  text-align: left;
  font-size: 1em;
  font-family: "proxima-nova";
  letter-spacing: 0px;
  opacity: 1;
`;

export const Wrapper = styled.div<{ leanWrapper?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;

  ${({ leanWrapper }) =>
    leanWrapper
      ? `
  @media (min-width: 1581px) {
    width: 50%;
  }
  
  @media (max-width: 1580px) {
    width: 80%;
  }
  `
      : null}
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  color: ${theme.colors.primary[500]};
  opacity: 0.8;
  ${typography.CustomText("0.75rem")}
  font-weight: 500;
  padding-bottom: 0.5rem;
`;

export const SubText = styled.div`
  color: #000;
  opacity: 0.5;
  ${typography.CustomText("0.75rem")}
  font-weight: 500;
`;

export const RadioContainer = styled.div`
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
`;

export const FormItem = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const AddButton = styled(AntButton)`
  background-color: ${theme.colors.primary[25]};
  color: ${theme.colors.primary[500]};
  border: 2px solid ${theme.colors.primary[500]};
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
  height: 42px;
  display: flex;

  align-items: center;
  justify-content: center;
  max-width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    border-color: ${theme.colors.primary[500]} !important;
    background: ${theme.colors.primary[25]} !important;
    color: ${theme.colors.primary[900]};
  }
`;
