import { darken } from 'polished';
import { theme } from 'src/theme/theme';

export default function buttonVariant(color, background) {
  return `
  color: ${color};
  background-color: ${background};

  &:focus,
  &.focus {
    color: ${color};
    background-color: ${background};
    outline: 0;
  }
  &:hover,
  &.hover {
    color: ${color};
    background-color: ${theme.colors.primary[400]};
    outline: 0;
  }
  &:active,
  &.active {
    color: ${color};
    background-color: ${background};
    background-image: none;

    &:hover,
    &:focus,
    &.focus {
      color: ${color};
      background-color: ${darken(0.17, background)};
      outline: 0;
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: ${background};

    }
  }

  .badge {
    color: ${background};
    background-color: ${color};
  }`;
}
