import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolveInitialData } from "src/store/Goal-Based/goal";
import moment from "moment";
import { UpdateFieldPayload } from "./goal";

const initialState: SolveInitialData = {
  period: 12,
  start_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  end_date: moment().add(5, "month").format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  contribution_type: 1,
  contribution_amount: "500",
  goal_amount: "10000",
};

const SolveInitialSlice = createSlice({
  name: "SolveInitial",
  initialState,
  reducers: {
    updateSolveInitialField: (state: any, action: PayloadAction<UpdateFieldPayload<SolveInitialData>>) => {
      const { name, value } = action.payload;
      state[name] = value;
    }
  },
});

export const { updateSolveInitialField } = SolveInitialSlice.actions;
export default SolveInitialSlice.reducer;
