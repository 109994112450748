import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

export interface ITicker {
  market: string;
  portal_name: string;
  symbol: string;
  zag_id: string;
}

const useTickers = () =>
  useQuery<ITicker[]>(
    "/RoboAdvisor/tickers",
    async () => (await axios(`${baseUrl}/roboAdvisor/tickers`)).data,
    {
      refetchInterval: 1800000,
      refetchOnWindowFocus: false,
    },
  );

export default useTickers;
